(function() {

  var templates = [
    "leftside_text",
    "rightside_text",
    "task_text",
    "progress_text",
    "task_class"
  ];

  function defaults(obj, std) {
    for (var key in std)
      if (!obj[key])
        obj[key] = std[key];
    return obj;
  }

  function mark_columns(base) {
    var columns = base.config.columns;
    if (columns)
      for (var i = 0; i < columns.length; i++) {
        if (columns[i].template)
          columns[i].$template = true;
      }
  }

  function set_level(data) {
    var h_lvl = 1;
    var sub_lvl = 1;
    for (var i = 0; i < data.length; i++) {
      if (data[i].parent == 0) {
        data[i]._lvl = '' + h_lvl;
        h_lvl++;
      }
      for (var j = i + 1; j < data.length; j++) {
        if (data[i].id == data[j].parent) {
          data[j]._lvl = data[i]._lvl + '.' + sub_lvl;
          sub_lvl++;
        }
      }
      sub_lvl = 1;
    }
  }

  function find_rec_links(links, tasks) {
    var lvl1, lvl2;
    for (var i = 0; i < links.length; i++) {
      lvl1 = tasks[links[i].source]._lvl;
      lvl2 = tasks[links[i].target]._lvl;
      if ((lvl1.length != lvl2.length)
        && !(lvl1.length > lvl2.length ? lvl1.indexOf(lvl2) : lvl2.indexOf(lvl1))) {
        if (gantt.checkEvent("onExportCircularDependency") &&
          !gantt.callEvent("onExportCircularDependency", [links[i].id, links[i]])) continue;
        links.splice(i, 1);
        i--;
      }
    }
  }

  function clear_level(data) {
    for (var i = 0; i < data.length; i++) {
      delete data[i]._lvl;
    }
  }

  function clear_rec_links(data) {
    set_level(data.data);
    var tasks = {};
    for (var i = 0; i < data.data.length; i++) {
      tasks[data.data[i].id] = data.data[i];
    }

    find_rec_links(data.links, tasks);
    clear_level(data.data);
  }

  function add_export_methods(gantt) {
    gantt._ms_export = {};

    gantt._ms_export.ajax_to_export = function(data, type, callback) {
      delete data.callback;

      var DImportServer = GT.DImportOuter || "https://dimport.ganttpro.com";

      gantt.ajax.post(DImportServer + "/",
        "type=" + type + "&store=1&data=" + encodeURIComponent(JSON.stringify(data)),
        function(loader) {
          var fail = loader.xmlDoc.status > 400;
          var info = null;

          if (!fail) {
            try {
              info = JSON.parse(loader.xmlDoc.responseText);
            } catch (e) {
            }
          }
          callback(info);
        }
      );
    };

    gantt._ms_export.send_to_export = function(data, type) {
      // if (GT.agent.family === "Safari") {
      //   data.name = decodeURI(data.name)
      //     .replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '')
      //     .replace(/[ ]/g, "_");
      // }

      data.name = decodeURI(data.name)
        .replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '')
        .replace(/[ ]/g, "_");

      if (data.callback)
        return gantt._ms_export.ajax_to_export(data, type, data.callback);

      if (data.config)
        mark_columns(data, type);
      var form = this.create_hidden_form(),
          DImportServer = GT.DImportOuter || "https://dimport.ganttpro.com";

      form.firstChild.action = DImportServer + "/";

      const dataCopy = {...data};
      const dataConfig = {...data.config};
      dataConfig.columns = [];
      dataCopy.config = dataConfig;

      form.firstChild.childNodes[0].value = JSON.stringify(dataCopy);
      form.firstChild.childNodes[1].value = type;
      form.firstChild.submit();
    };

    gantt._ms_export.create_hidden_form = function() {
      if (!this._hidden_export_form) {
        var t = this._hidden_export_form = document.createElement("div");
        t.style.display = "none";
        t.innerHTML = "<form method='POST'><input type='text' name='data'><input type='hidden' name='type' value=''></form>";
        document.body.appendChild(t);
      }
      return this._hidden_export_form;
    };

    gantt._ms_export.serialize_all = function() {
      gantt.json._copyObject = copy_object_all;
      var data = export_serialize();
      gantt.json._copyObject = original;
      return data;
    };

    gantt._ms_export.serialize_plain = function() {
      var config = gantt.templates.format_date;
      gantt.templates.format_date = gantt.date.date_to_str("%Y%m%dT%H%i%s", true);
      gantt.json._copyObject = copy_object_plain;

      var data = export_serialize();

      gantt.templates.format_date = config;
      gantt.json._copyObject = original;

      delete data.links;
      return data;
    };

    gantt._ms_export.serialize_table = function() {
      gantt.json._copyObject = copy_object_table;
      var data = export_serialize();
      gantt.json._copyObject = original;

      delete data.links;
      return data;
    };

    gantt._ms_export.serialize_columns = function() {
      gantt.exportMode = true;

      var columns = [];
      var cols = gantt.config.columns;

      for (var i = 0; i < cols.length; i++) {
        if (cols[i].name == "add") continue;
        columns[i] = {
          id: ((cols[i].template) ? ("_" + i) : cols[i].name),
          header: cols[i].label || gantt.locale.labels["column_" + cols[i].name],
          width: (cols[i].width ? Math.floor(cols[i].width / 4) : "")
        };
        if (cols[i].name == "duration")
          columns[i].type = "number";
      }

      columns.push({
        id: "text", header: ""
      });

      gantt.exportMode = false;
      return columns;
    };

    gantt.exportToMSProject = function(config) {
      var old_xml_format = this.templates.xml_format;
      this.templates.xml_format = this.date.date_to_str("%d-%m-%Y %H:%i:%s");
      var data = this._ms_export.serialize_all();

      if (config && config.project) {
        for (var i in config.project) {
          if (!this.config._custom_data)
            this.config._custom_data = {};
          this.config._custom_data[i] = typeof config.project[i] == "function" ? config.project[i](this.config) : config.project[i];
        }
        delete config.project;
      }
      if (config && config.tasks) {
        data.data.forEach(function(el) {
          for (var i in config.tasks) {
            if (!el.$custom_data)
              el.$custom_data = {};
            el.$custom_data[i] = config.tasks[i](el, gantt.config);
          }
        });
        delete config.tasks;
      }

      if (config && config.recurring) {
        clear_rec_links(data);
      }

      var p_name = (config && config.name) ? config.name : 'gantt.xml';
      if (config && config.name)
        delete config.name;

      this.config.custom = config || {};
      var time = {};
      time.hours = gantt.getCalendar("global").getConfig().hours;
      time.dates = gantt.getCalendar("global").getConfig().dates;
      var p_dates = this.getSubtaskDates();
      if (p_dates.start_date && p_dates.end_date) {
        this.config.start_end = {
          start_date: this.templates.xml_format(p_dates.start_date),
          end_date: this.templates.xml_format(p_dates.end_date)
        };
      }

      var manual = config && config.manual ? true : false;

      config = {
        config: this.config,
        data: data,
        manual: manual,
        name: p_name,
        worktime: time
      };
      this._ms_export.send_to_export(config, "msproject");
      this.templates.xml_format = old_xml_format;
      this.config._custom_data = null;
    };

    //patch broken json serialization in gantt 2.1
    var original = gantt.json._copyObject;

    function copy_object_base(obj) {
      var copy = {};
      for (var key in obj) {
        if (key.charAt(0) == "$")
          continue;
        copy[key] = obj[key];
      }
      copy.start_date = gantt.templates.format_date(copy.start_date);
      if (copy.end_date)
        copy.end_date = gantt.templates.format_date(copy.end_date);

      return copy;
    }

    function copy_object_plain(obj) {
      var text = gantt.templates.task_text(obj.start_date, obj.end_date, obj);

      var copy = copy_object_base(obj);
      copy.text = text;

      return copy;
    }

    function copy_object_table(obj) {
      var copy = copy_object_columns(obj, copy_object_plain(obj));
      if (copy.start_date)
        copy.start_date = copy.start_date.valueOf();
      if (copy.end_date)
        copy.end_date = copy.end_date.valueOf();
      return copy;
    }

    function copy_object_columns(obj, copy) {
      for (var i = 0; i < gantt.config.columns.length; i++) {
        var ct = gantt.config.columns[i].template;
        if (ct) copy["_" + i] = ct(obj);
      }
      return copy;
    }

    function copy_object_all(obj) {
      var copy = copy_object_base(obj);

      //serialize all text templates
      for (var i = 0; i < templates.length; i++) {
        var template = gantt.templates[templates[i]];
        if (template)
          copy["$" + i] = template(obj.start_date, obj.end_date, obj);
      }

      copy_object_columns(obj, copy);
      copy.open = obj.$open;
      return copy;
    }

    // need for Safari
    function formatDate(date) {
      return gantt.templates.xml_format(moment(date).toDate());
    }

    function export_serialize() {
      gantt.exportMode = true;
      var data = gantt.serialize(),
        exportData = {
          data: [],
          links: []
        };
      data.data.forEach(function(task) {
        if (task.type !== gantt.config.types.button && gantt.isTaskExists(task.id)) {
          task.start_date = formatDate(task.start_date);
          task.end_date = formatDate(task.end_date);
          task.last_update = formatDate(task.last_update);
          task.created_at = formatDate(task.created_at);
          exportData.data.push(task);
        }
      });

      data.links.forEach(function(link) {
        if (gantt.isLinkAllowed(link) && gantt.isTaskExists(link.source) && gantt.isTaskExists(link.target)) {
          exportData.links.push(link);
        }
      });
      gantt.exportMode = false;
      return exportData;
    }
  }

  add_export_methods(gantt);
  if (window.Gantt && Gantt.plugin)
    Gantt.plugin(add_export_methods);

})();