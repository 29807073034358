"use strict";

Gantt.plugin(function(gantt) {

  gantt._reCalcCalendarDays = function (startDate, endDate, direction) {
    var tempHours = [],
      workHoursInDay = 0,
      tempStartDay = new Date(startDate),
      oneDay = 24 * 60 * 60 * 1000,
      calendarDays = "";

    tempHours = gantt.getWorkHours(new Date(startDate));
    tempStartDay.setHours(tempHours[0]);
    workHoursInDay = gantt.getDefaultWorkHoursPerDay(tempHours);

    if (startDate < tempStartDay) {
      startDate = tempStartDay;
    }
    calendarDays = direction * Math.abs(new Date(endDate).getTime() - new Date(startDate).getTime()) / oneDay;

    if (Math.round(calendarDays % 1 * 100) / 100 !== 0) {
      if (Math.round(1 / (24 / workHoursInDay) * 100) / 100 === Math.round((calendarDays % 1) * 100) / 100) {
        calendarDays = parseInt(calendarDays, 10) + 1;
      }
    }
    return Math.round(calendarDays * 100) / 100;
  };

  gantt._reCalcDurationToMonth = function (startDate, endDate, gantt_id) {
    var calcDay = new Date(startDate.getFullYear(), startDate.getMonth(), 0),
      calcMounth = 0,
      controlDay = new Date(endDate),
      countDaysInMounth = 0,
      calcDuration = 0,
      direction = 1,
      totalWeeks = gantt._reCalcDurationToWeek(startDate, endDate, gantt_id);

    if (new Date(startDate).getTime() > new Date(endDate).getTime()) {
      calcDay = new Date(endDate.getFullYear(), endDate.getMonth(), 0);
      controlDay = new Date(startDate);
    }
    while (calcDay < controlDay) {
      countDaysInMounth += new Date(calcDay.getFullYear(), calcDay.getMonth(), 0).getDate();
      calcDay.setMonth(calcDay.getMonth() + 1);
      calcMounth++;
    }
    if (!countDaysInMounth) {
      countDaysInMounth = calcDay.getDate();
      calcMounth = 1;
    }
    calcDuration = Math.round((totalWeeks.duration / ((countDaysInMounth / calcMounth) / 7)) * 100) / 100;
    calcDuration = !isNaN(calcDuration) ? calcDuration : "0";
    return {duration: calcDuration, calendarDays: totalWeeks.calendarDays};
  };

  gantt._reCalcDurationToWeek = function (startDate, endDate, gantt_id) {
    var durationUnit = gantt.calculateDuration(startDate, endDate, {calendar_id: gantt_id}),
      calcDay = new Date(startDate),
      calendarDays = 0,
      calcDuration = 0,
      calcTotalDaysInWeek = 0,
      controlDay = new Date(startDate),
      oneWeek = 24 * 60 * 60 * 1000 * 7,
      direction = 1,
      totalDays = gantt._reCalcDurationToDay(startDate, endDate),
      calcTestWeekDays = 7;

    if (new Date(startDate).getTime() > new Date(endDate).getTime()) {
      calcDay = new Date(endDate);
      controlDay = new Date(endDate);
    }
    while ((calcDay.getTime() - controlDay.getTime()) / oneWeek < 1 && calcTestWeekDays > 0) {
      if (gantt.getWorkHours(calcDay).length) {
        calcTotalDaysInWeek++;
      }
      calcDay.setDate(calcDay.getDate() + 1);
      calcTestWeekDays -= 1;
    }
    calcDuration = Math.round((totalDays.duration / calcTotalDaysInWeek) * 100) / 100;
    calcDuration = !isNaN(calcDuration) ? calcDuration : "0";

    return {duration: calcDuration, calendarDays: totalDays.calendarDays};
  };

  gantt._reCalcDurationToDay = function (startDate, endDate, gantt_id) {
    var durationUnit = gantt.calculateDuration(startDate, endDate, {calendar_id: gantt_id}),
      calcDay = new Date(startDate),
      durationMultiplic = {"hour": 1, "minute": 60},
      calendarDays = 0,
      tempHours = [],
      calcDuration = 0,
      totalHoursInDurationDays = 0,
      direction = 1,
      controlDay = new Date(endDate),
      totalDays = 0;

    if (new Date(startDate).getTime() > new Date(endDate).getTime()) {
      direction = -1;
      calcDay = new Date(endDate);
      controlDay = new Date(startDate);
      durationUnit = direction * gantt.calculateDuration(endDate, startDate, {calendar_id: gantt_id});
    }


    while (calcDay.valueOf() <= controlDay.valueOf()) {
      tempHours = gantt.getWorkHours(calcDay);
      totalHoursInDurationDays = gantt.getDefaultWorkHoursPerDay(tempHours);

      if (tempHours.length) {
        totalDays++;
      }
      calcDay.setDate(calcDay.getDate() + 1);
    }
    calcDuration = Math.round((durationUnit * gantt.config.duration_step / durationMultiplic[gantt.config.duration_unit]) / (totalHoursInDurationDays / totalDays) * 100) / 100;
    calendarDays = gantt._reCalcCalendarDays(startDate, endDate, direction);
    calcDuration = !isNaN(calcDuration) ? calcDuration : "0";
    return {duration: calcDuration, calendarDays: calendarDays || 0};
  };

  gantt._reCalcDurationToHour = function (startDate, endDate, gantt_id) {
    var durationUnit = gantt.calculateDuration(startDate, endDate, {calendar_id: gantt_id}),
      durationMultiplic = {"hour": 1, "minute": 60},
      calendarDays = 0,
      direction = 1,
      calcDuration = 0;

    if (new Date(startDate).getTime() > new Date(endDate).getTime()) {
      direction = -1;
      durationUnit = direction * gantt.calculateDuration(endDate, startDate, {calendar_id: gantt_id});
    }
    //console.trace("reCalcDurationToHour");
    calendarDays = gantt._reCalcCalendarDays(startDate, endDate, direction);

    calcDuration = Math.round((durationUnit * gantt.config.duration_step / durationMultiplic[gantt.config.duration_unit]) * 100) / 100;

    calcDuration = (!isNaN(calcDuration) || calcDuration < 0) ? calcDuration : "0";

    return {duration: calcDuration || 0, calendarDays: calendarDays || 0};
  };

  gantt._reCalcHourToDuration = function (startDate, newDuration) {
    var calcDay = new Date(startDate),
      direction = 1,
      durationMultiplic = {"hour": 1, "minute": 60},
      calcManualDuration = parseFloat(parseFloat(newDuration) * durationMultiplic[gantt.config.duration_unit] / gantt.config.duration_step),
      endDate = gantt.calculateEndDate(new Date(startDate), calcManualDuration * durationMultiplic['minute'], "minute"),
      calendarDays = 0;

    if (newDuration < 0) {
      direction = -1;
    }
    //console.trace("reCalcHourToDuration");
    calendarDays = gantt._reCalcCalendarDays(startDate, endDate, direction);
    return {endDate: endDate, calendarDays: calendarDays};
  };

  gantt._reCalcMinuteToDuration = function (startDate, newDuration) {
    var calcDay = new Date(startDate),
      direction = 1,
      calcManualDuration = parseFloat(parseFloat(newDuration) / gantt.config.duration_step),
      endDate = gantt.calculateEndDate(new Date(startDate), calcManualDuration, "minute"),
      calendarDays = 0;

    if (newDuration < 0) {
      direction = -1;
    }

    calendarDays = gantt._reCalcCalendarDays(startDate, endDate, direction);
    return {endDate: endDate, calendarDays: calendarDays};
  };

  gantt._reCalcDaysToDuration = function (startDate, newDuration) {
    var calcDay = new Date(startDate),
      durationMultiplic = {"hour": 1, "minute": 60},
      endDate = {},
      tempHours = [],
      calcTotalDays = 0,
      calcManualDuration = 0,
      direction = 1, //if 0 then forward if - 1 then backward
      floatDays = newDuration.toString().split("."),
      floatDaysAbs = 0,
      i = 0,
      floatHours = 0,
      calendarDays = 0;

    if (floatDays[0] < 0) {
      direction = -1;
    }
    floatDaysAbs = Math.abs(floatDays[0]);

    while (calcTotalDays < floatDaysAbs) {
      tempHours = gantt.getWorkHours(calcDay);

      if (typeof tempHours[0] === 'string') {
        tempHours = tempHours.map(i => i.split('-')).flat(2).map(i => {
          const hours = i.split(':');
          let res = Number(hours[0]);

          if (Number(hours[1])) {
            res += 0.5;
          }

          return res;
        });
      }

      for (i = 0; i < tempHours.length; i = i + 2) {
        calcManualDuration += (tempHours[i + 1] - tempHours[i]) * durationMultiplic[gantt.config.duration_unit] / gantt.config.duration_step;
      }

      if (tempHours.length) {
        calcTotalDays++;
      }
      calcDay.setDate(calcDay.getDate() + direction);
    }

    if (!tempHours.length) {
      tempHours = gantt.getWorkHours(gantt.getClosestWorkTime(calcDay));
    }

    if (floatDays[1]) {
      var workHoursCount = gantt.getDefaultWorkHoursPerDay(tempHours);

      floatHours = parseFloat(floatDays[1] / Math.pow(10, floatDays[1].length) * workHoursCount).toFixed(2).toString().split(".");

      calcManualDuration += parseInt(floatHours[0]) * durationMultiplic[gantt.config.duration_unit] / gantt.config.duration_step;
      if (floatHours[1]) {
        var range = Math.pow(10, floatHours[1].length) * gantt.config.duration_step;
        var roundHours = floatHours[1] * durationMultiplic[gantt.config.duration_unit] / range;
        calcManualDuration += Math.round(roundHours * range / range);
      }
    }
    endDate = gantt.calculateEndDate(new Date(startDate), direction * calcManualDuration, gantt.config.duration_unit);
    calendarDays = gantt._reCalcCalendarDays(startDate, endDate, direction);

    return {endDate: endDate, calendarDays: calendarDays};
  };

  gantt._reCalcWeeksToDuration = function (startDate, newDuration) {
    var calcDay = new Date(startDate),
      floatWeeks = newDuration.toString().split("."),
      calcTotalDaysInWeek = 0,
      direction = 1,
      totalDays = 0;

    if (floatWeeks[0] < 0) {
      direction = -1;
    }

    while (Math.abs((calcDay.getTime() - new Date(startDate).getTime()) / (24 * 60 * 60 * 1000 * 7)) < 1) {
      if (gantt.getWorkHours(calcDay).length) {
        calcTotalDaysInWeek++;
      }
      calcDay.setDate(calcDay.getDate() + direction);
    }
    totalDays = parseInt(calcTotalDaysInWeek * Math.abs(floatWeeks[0]));

    if (floatWeeks[1]) {
      totalDays += parseFloat(Math.round(floatWeeks[1] / Math.pow(10, floatWeeks[1].length) * calcTotalDaysInWeek * 100) / 100);
    }

    return gantt._reCalcDaysToDuration(startDate, (direction * totalDays).toString());
  };

  gantt._reCalcMonthToDuration = function (startDate, newDuration) {
    var calcDay = new Date(startDate.getFullYear(), startDate.getMonth(), 0),
      countDaysInMounth = 0,
      floatMount = newDuration.toString().split("."),
      direction = 1,
      calcMounth = 0;

    if (floatMount[0] < 0) {
      direction = -1;
    }
    while (calcMounth < Math.abs(floatMount[0])) {
      if (direction < 0) {
        countDaysInMounth += new Date(calcDay.getFullYear(), calcDay.getMonth() - 1, 0).getDate();
      } else {
        countDaysInMounth += new Date(calcDay.getFullYear(), calcDay.getMonth(), 0).getDate();
      }

      calcDay.setMonth(calcDay.getMonth() + direction);
      calcMounth++;
    }

    if (floatMount[1] && !countDaysInMounth) {
      countDaysInMounth = calcDay.getDate();
      calcMounth = 1;
    }

    return gantt._reCalcWeeksToDuration(startDate, Math.round((countDaysInMounth / calcMounth) * newDuration / 7 * 100) / 100);
  };

  gantt._reCalcYearToDuration = function (startDate, newDuration) {
    return gantt._reCalcMonthToDuration(startDate, newDuration * 12);
  };

  gantt._reCalcQuarterToDuration = function (startDate, newDuration) {
    return gantt._reCalcMonthToDuration(startDate, newDuration * 3);
  };

  gantt.calculateDurationTo = function (startDate, endDate, durationView, gantt_id) {
    const strategy = {
      'month': gantt._reCalcDurationToMonth,
      'week': gantt._reCalcDurationToWeek,
      'day': gantt._reCalcDurationToDay,
      'hour': gantt._reCalcDurationToHour,
      'minute': gantt._reCalcMinuteToDuration
    };

    return strategy[durationView || gantt.config.duration_view || 'hour'](startDate, endDate, gantt_id);
  };

  gantt.calculateToDuration = function (startDate, newDuration, durationView) {
    switch (durationView || gantt.config.duration_view) {
      case 'year':
        return gantt._reCalcYearToDuration(startDate, newDuration);
        break;
      case 'quarter':
        return gantt._reCalcQuarterToDuration(startDate, newDuration);
        break;
      case 'month':
        return gantt._reCalcMonthToDuration(startDate, newDuration);
        break;
      case 'week':
        return gantt._reCalcWeeksToDuration(startDate, newDuration);
        break;
      case 'day':
        return gantt._reCalcDaysToDuration(startDate, newDuration);
        break;
      case 'hour':
        return gantt._reCalcHourToDuration(startDate, newDuration);
        break;
      case 'minute':
        return gantt._reCalcMinuteToDuration(startDate, newDuration);
        break;
    }
  };

});
