var linkControlPoinerOffset = 19;

function createCSSClass(name, rules) {
  var style = document.createElement('style');
  style.type = 'text/css';
  document.getElementsByTagName('head')[0].appendChild(style);
  if(!(style.sheet||{}).insertRule)
    (style.styleSheet || style.sheet).addRule(name, rules);
  else
    style.sheet.insertRule(name+"{"+rules+"}", 0);
};

// Change positions of link controls
createCSSClass('.gantt_task_line .gantt_link_control.task_left ', 'left:-' + linkControlPoinerOffset + 'px');
createCSSClass('.gantt_task_line .gantt_link_control.task_right ', 'right:-' + linkControlPoinerOffset + 'px');
