var leftButton = 'gantt_expand_button_left';
var rightButton = 'gantt_expand_button_right';

var workloadTopButton = 'gantt_workload_expand_button_top';//'<div class="gantt_workload_expand_button_top"></div>';
var workloadBottomButton = 'gantt_workload_expand_button_bottom';//'<div class="gantt_workload_expand_button_bottom"></div><div class="gantt_workload_expand_button_text">Workload</div>';

Gantt.plugin(function(gantt) {
  var updateExpandButtonsPosition = gantt.updateExpandButtonsPosition = gantt.debounce(function () {
    if (gantt._expand_button._html) {
      gantt._workload_expand_button._html.style.display = 'block';
    }

    gantt._expand_button.expand_button_right_y = 32;
    gantt._expand_button.expand_button_left_y = 64;
    gantt._expand_button.expand_button_x = 18;

    gantt._workload_expand_button.workload_expand_button_y = 18;
    gantt._workload_expand_button.workload_expand_button_top_x = 30;
    gantt._workload_expand_button.workload_expand_button_bottom_x = 92;

    gantt._resize_expand_button();
    gantt._resize_workload_expand_button();
  }, 10);

  gantt._expand_button = {};
  gantt._expand_button._class = "gantt_expand_button";

  gantt._workload_expand_button = {};
  gantt._workload_expand_button._class = "gantt_workload_expand_button";

  gantt._resize_freese_workload = false;
  gantt._resize_freese_grid = false;


  gantt.templates.expand_button_class = function (expand) {
    return expand ? rightButton : leftButton;
  };

  gantt.templates.workload_expand_button_class = function (expand) {
    return expand ? workloadBottomButton : workloadTopButton;
  };

  gantt.attachEvent("onGanttReady", function () {
    if (GT.appMode && GT.appMode.isExport) {
      return;
    }

    gantt.initExpandButton();
    gantt.initWorkloadExpandButton();
  });

  gantt.attachEvent("onAfterGanttRender", function (oldWidth, newWidth) {
    setTimeout(function () {
      updateExpandButtonsPosition();
    }, 0);

    return true;
  });

  gantt.attachEvent("onGridResizeEnd", function (oldWidth, newWidth) {
    setTimeout(function () {
      updateExpandButtonsPosition();
    }, 200);

    return true;
  });

  gantt.attachEvent("onAfterGanttRender", function () {
    setTimeout(function () {
      updateExpandButtonsPosition();
    }, 0);

    return true;
  });

  gantt.initExpandButton = function () {
    var gb = gantt._create_expand_button();
    var el = document.getElementsByClassName('gantt_layout_root')[0];
    el.parentNode.insertBefore(gb, el.nextSibling);
  };

  gantt.initWorkloadExpandButton = function () {
    var gb = gantt._create_workload_expand_button();
    var el = document.getElementsByClassName('workload-body')[0];
    el && el.parentNode && el.parentNode.insertBefore(gb, el.nextSibling);
  };

  gantt._hide_expand_button = function () {
    if (gantt._expand_button._html && gantt._expand_button._html.parentNode)
      gantt._expand_button._html.parentNode.removeChild(gantt._expand_button._html);
  };

  gantt._hide_workload_expand_button = function () {
    if (gantt._workload_expand_button._html && gantt._workload_expand_button._html.parentNode)
      gantt._workload_expand_button._html.parentNode.removeChild(gantt._workload_expand_button._html);
  };

  gantt._create_expand_button = function () {
    if (gantt._expand_button._html) {
      return gantt._expand_button._html;
    }

    gantt._expand_button._html = document.createElement('div');
    gantt._expand_button._html.className = gantt._expand_button._class + " gantt_" + gantt.skin;
    gantt._expand_button._html.style.width = gantt._expand_button.expand_button_x + "px";

    gantt._expand_button._html.innerHTML = `<div class="gantt_expand_button_bottom"></div><div class="gantt_expand_button_text">Grid</div>`;
    gantt._expand_button._html.addEventListener("click", gantt._click_expand_button);

    gantt.event(window, "resize", function (e) {
      setTimeout(function () {
        updateExpandButtonsPosition();
      }, 50);
    });

    return gantt._expand_button._html;
  };

  gantt._create_workload_expand_button = function () {
    if (gantt._workload_expand_button._html) {
      return gantt._workload_expand_button._html;
    }

    var parentNodeSize = gantt.$task.getBoundingClientRectWrapper();

    gantt._workload_expand_button._html = document.createElement('div');
    gantt._workload_expand_button._html.className = gantt._workload_expand_button._class + " gantt_" + gantt.skin;
    gantt._workload_expand_button._html.style.height = gantt._workload_expand_button.workload_expand_button_y + "px";

    gantt._workload_expand_button._html.innerHTML = '<div class="gantt_workload_expand_button_bottom"></div><div class="gantt_workload_expand_button_text">Workload</div>';
    gantt._workload_expand_button._html.addEventListener("click", gantt._click_workload_expand_button);

    gantt.event(window, "resize", function (e) {
      setTimeout(function () {
        updateExpandButtonsPosition();
      }, 50);
    });

    return gantt._workload_expand_button._html;
  };

  gantt._click_expand_button = function () {
    gantt.saveTaskEditors(true);
    gantt.callEvent("expandButtonClick", []);
    gantt.callEvent("toggleWorkloadGrid", [gantt.config.show_grid]);
    gantt.config.show_grid = !gantt.config.show_grid;

    gantt.callEvent("ganttRender", [function () {
      gantt._resize_expand_button();
      gantt._expand_button._html.classList.add('after_resizer_click');
      setTimeout(function () {
        gantt._expand_button._html.classList.remove('after_resizer_click');
      }, 500);
    }]);
  };

  gantt._click_workload_expand_button = function (e) {
    gantt.saveTaskEditors(true);
    gantt.callEvent("workloadExpandButtonClick", [{}]);

    gantt.callEvent("ganttRender", [function () {
      setTimeout(function () {
        gantt._resize_workload_expand_button();
        gantt._workload_expand_button._html.classList.add('after_resizer_click');

        setTimeout(function () {
          gantt._workload_expand_button._html.classList.remove('after_resizer_click');
        }, 0);
      }, 0);
    }]);
  };

  gantt._resize_expand_button = function (force) {
    if (gantt._expand_button._html) {
      if (gantt._resize_freese_grid && !force) return;


      if (!force) gantt._resize_freese_grid = true;

      setTimeout(function () {
        gantt._resize_freese_grid = false;
      }, 50);

      var parentNodeSize = gantt.$task.getBoundingClientRectWrapper();
      var box = document.getElementsByClassName('timeline_cell')[0];

      if (!box) {
        gantt._expand_button._html.style.display = 'none';
        return;
      }

      var height = (gantt.config.show_grid ? gantt._expand_button.expand_button_right_y : gantt._expand_button.expand_button_left_y);

      gantt._expand_button._html.style.display = 'block';
      gantt._expand_button._html.style.width = gantt._expand_button.expand_button_x + "px";
      gantt._expand_button._html.firstChild.className = gantt.templates.expand_button_class(gantt.config.show_grid);
      gantt._expand_button._html.style.left = (gantt.config.show_grid ? gantt.config.grid_width : 1) + "px";
      gantt._expand_button._html.style.top = box.clientHeight / 2 - height / 2 + "px";
      gantt._expand_button._html.style.height = height + "px";
    }
  };

  gantt._resize_workload_expand_button = function () {
    if (gantt._workload_expand_button._html && typeof (gantt.init_workload) !== "undefined" && typeof (gantt.config.hide_workload) !== "undefined") {
      var parentNodeSize = gantt.$task.getBoundingClientRectWrapper();
      var box = document.getElementsByClassName('timeline_cell')[0];

      if (!box) {
        return;
      }

      var top = parentNodeSize.height + 1;

      var workloadResizer = document.querySelector(".workload-resizer");

      var width = (!gantt.config.hide_workload ? gantt._workload_expand_button.workload_expand_button_top_x : gantt._workload_expand_button.workload_expand_button_bottom_x);
      gantt._workload_expand_button._html.firstChild.className = gantt.templates.workload_expand_button_class(gantt.config.hide_workload);
      gantt._workload_expand_button._html.style.width = width + "px";
      gantt._workload_expand_button._html.style.top = top + "px";
      gantt._workload_expand_button._html.style.right = box.clientWidth / 2 - width / 2 + "px";
      gantt._workload_expand_button._html.style.height = gantt._workload_expand_button.workload_expand_button_y + "px";

      if (!gantt.init_workload) {
        gantt._workload_expand_button._html.style.display = "none";
      } else {
        gantt._workload_expand_button._html.style.display = "flex";
      }

      if (workloadResizer) {
        if (gantt.config.hide_workload) {
          workloadResizer.parentNode.style.display = "none";
        } else {
          workloadResizer.parentNode.style.display = "block";
        }
      }
    }
  };
});
