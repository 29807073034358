let _initModuleProjectSchedule = function(_, moment) {
  return function(gantt) {
    gantt.locale.labels.section_project_start = "Project Start";

    gantt._update_project_start = null;
    gantt._moveTasks = [];

    gantt.config.lightbox.project_sections = [
      {name: "project_start", type: "duration", single_date: true, map_to: "auto"}
    ];

    gantt.attachEvent("onBeforeTaskUpdate", function (id, item) {
      if (item.type == gantt.config.types.project) {
        gantt._update_project_start = item.start_date;
      }

      return true;
    });

    gantt.moveProjectHandler = function (id, item, start_date) {
      return new Promise((resolve, reject) => {
        gantt._moveTasks = [];
        gantt.callEvent("onBeforeProjectMove", [id]);
        gantt.moveProject(id, start_date);
        gantt._update_project_start = null;
        gantt.updateTasks(gantt._moveTasks, "projectMove", item.actionHash, gantt.getTaskName(item))
          .then(() => {
            gantt.callEvent("onAfterProjectMove", [id, item.actionHash]);
            resolve();
          });
      })
        .then(() => Promise.resolve());
    };

    gantt.moveProjectNodeHandler = function (id, start_date) {
      gantt._moveTasks = [];
      gantt.callEvent("onBeforeProjectMove", [id]);
      gantt.moveProject(id, start_date);
      gantt._update_project_start = null;
      let ganttChanges = [...gantt._moveTasks]
      gantt._moveTasks = [];
      return ganttChanges;
    };

    gantt.attachEvent("onAfterTaskUpdate", function (id, item) {
      if (gantt._update_project_start !== null &&
        item.type === gantt.config.types.project &&
        !gantt._ignore_project_move &&
        gantt._ignore_project_move_id != id &&
        !_.isEqual(new Date(gantt._update_project_start).valueOf(), new Date(item.start_date).valueOf())) {
        // gantt.moveProjectHandler(id, item, gantt._update_project_start);
      }

      gantt._update_project_start = null;
      gantt._moveTasks = [];

      if (gantt._ignore_project_move_id == id) {
        gantt._ignore_project_move_id = null;
      }
    });

    gantt.attachEvent("onBeforeProgress", function (projectId) {
      gantt._ignore_project_move = true;
    });

    gantt.attachEvent("onAfterProgress", function (projectId) {
      gantt._ignore_project_move = false;
    });

    gantt.attachEvent("setIgnoreMove", function (projectId) {
      gantt._ignore_project_move_id = projectId;
    });

    gantt.attachEvent("onLightboxSave", function (id, newTask, is_new) {
      var task = gantt.getTask(id),
        type = gantt._get_safe_type(task.type);

      if (!is_new && type == gantt.config.types.project && +task.start_date != +newTask.start_date) {
        gantt.moveProject(id, newTask.start_date);
      }
      return true;
    });

    const calculateOffset = function (task, oldDate, newDate) {
      let offset;

      if (oldDate.valueOf() < newDate.valueOf()) {
        offset = gantt.calculateDuration({
          start_date: oldDate,
          end_date: newDate,
          task: task
        });
      } else {
        offset = -gantt.calculateDuration({
          start_date: newDate,
          end_date: oldDate,
          task: task
        });
      }

      return offset;
    };

    gantt.moveProject = function (project_id, newDate) {
      if (gantt._tasks_recalculating) {
        return;
      }

      gantt._tasks_recalculating = true;

      if (newDate) {
        let offset;

        if (project_id === 1) {
          const children = gantt.getChildren(project_id);
          const projects = _.map(children, id => gantt.getTask(id));
          const projectWithMinStartDate = _.minBy(projects, (task) => task.start_date.valueOf());
          offset = calculateOffset(projectWithMinStartDate, projectWithMinStartDate.start_date, newDate);
        } else {
          const task = gantt._inline_edit && gantt._inline_edit._oldTask ? gantt._inline_edit._oldTask : gantt.getTask(project_id);
          offset = calculateOffset(task, task.start_date, newDate);
        }

        gantt._moveChildren(project_id, offset);
      }

      gantt._tasks_recalculating = false;
    };

    gantt._moveChildren = function (parentId, offset) {
      var children = gantt.getChildren(parentId);
      if (gantt.hasChild(parentId)) {
        for (var i = 0; i < children.length; i++) {
          gantt._moveTasksDates(children[i], offset);
        }
      }
    };
    gantt._moveTasksDates = function (taskId, offset) {
      var shouldUpdate = !!offset;

      if (taskId != gantt.config.root_id) {
        var task = gantt.getTask(taskId);

        if (task.type !== gantt.config.types.button) {
          task.start_date = gantt.calculateEndDate({
            start_date: task.start_date,
            duration: offset,
            unit: gantt.config.duration_unit,
            task: task
          });
          task.start_date = gantt.getClosestWorkTime({date: task.start_date, dir: 'future', task: task});

          task.constraint_type = gantt.config.constraint_types.ASAP;
          task.constraint_date = null;

          // if (offset < 0 && task.type !== gantt.config.types.project) {
          //   offset -= 1;
          // }

          task.end_date = gantt.calculateEndDate({
            start_date: task.start_date,
            unit: gantt.config.duration_unit,
            duration: task.duration,
            task: task
          });

          // if (offset < 0 && task.type !== gantt.config.types.project) {
          //   task.end_date = gantt.calculateEndDate(task.end_date, 1);
          // }

          if (shouldUpdate) {
            gantt._moveTasks.push(task);
          }
        }
      }

      gantt._moveChildren(taskId, offset);
    };
  }
} 

if (typeof module === 'object' && module.exports) {
  const _ = require('lodash');
  const moment = require('moment');
  module.exports = _initModuleProjectSchedule(_, moment);
} else {
  Gantt.plugin(_initModuleProjectSchedule(_, moment))
}