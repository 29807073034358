'use strict';

const _initModule = function (_, moment) {
  return function (gantt) {
    gantt.plugins({
      drag_timeline: true,
    });

    const originalCreateTask = gantt.createTask;
    const originalGetClosestWorkTime = gantt.getClosestWorkTime;
    const getWBSCodeOriginal = gantt.getWBSCode;
    const originalShowTask = gantt.showTask;
    const originalLocate = gantt.locate;

    gantt.globalCash = {
      isWorkDays: {},
    };

    gantt.debounce = function (func, wait, immediate) {
      let timeout;

      return function () {
        const context = this; const
          args = arguments;
        const later = function () {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
    };

    gantt.clearCash = function (key) {
      gantt.globalCash[key] = {};
    };

    gantt.createTask = function () {
      const scrollState = gantt.getScrollState();
      const task = arguments[0];

      if (task && task.type === gantt.config.types.milestone) {
        task.duration = 0;
      }

      const taskId = originalCreateTask.apply(this, arguments);

      gantt.unselectTask();

      // never enable if you don't know what are you doing
      // gantt.scrollTo(scrollState.x, scrollState.y);

      return taskId;
    };

    gantt.showTask = function (id) {
      const task = gantt.getTask(id);

      if (task.skipShowing) {
        delete task.skipShowing;
      } else {
        const taskId = originalShowTask.apply(this, arguments);
      }
    };

    gantt.getClosestWorkTime = function () {
      const params = arguments[0];

      if (params.dir === 'past') {
        params.date = new Date(params.date.valueOf() - 1);
      }

      return originalGetClosestWorkTime(params);
    };

    gantt.getWBSCode = function (task) {
      const wbs = getWBSCodeOriginal.apply(this, arguments);

      if (!wbs) {
        return '';
      }

      let resultWBS = wbs.substring(wbs.indexOf('.') + 1);

      if (gantt.config.multiview) {
        if (gantt.calculateTaskLevel(task) === 1) {
          return '';
        }

        resultWBS = resultWBS.substring(resultWBS.indexOf('.') + 1);
      }

      return resultWBS;
    };

    gantt.getWBSCodeOriginal = getWBSCodeOriginal;

    gantt.openAllParentsOfTask = function (taskId) {
      let task = gantt.getTask(taskId);

      while (task.parent) {
        task = gantt.getTask(task.parent);

        if (task && !task.$open) {
          gantt.blockEvents();

          task.$open = 1;
          task.open = 1;

          gantt.unblockEvents();

          // gantt.updateTask(task.id);
          gantt.callEvent('silentUpdate', [task.id, task]);
          gantt.$ui.getView('scrollVer').resize();
        }
      }
    };

    gantt.serializeWithoutButtons = function () {
      const ganttData = {
        data: [],
        links: [],
      };
      const ganttCurrentData = gantt.serialize();

      ganttData.data = _.filter(ganttCurrentData.data, task => (task.type !== gantt.config.types.button));

      ganttData.links = ganttCurrentData.links;

      return gantt.copy(ganttData);
    };

    gantt.getChildCount = function (taskId) {
      return gantt.getChildren(taskId).length;
    };

    gantt.getClearTasksData = function (tasksArray) {
      return _.map(tasksArray, taskData => {
        _.each(Object.keys(taskData), taskKey => {
          if (taskKey.indexOf('$') !== -1) {
            delete taskData[taskKey];
          }
        });

        return taskData;
      });
    };

    gantt.setHoverTaskRow = function (task_id) {
      // const __cnDisableHov = 'hover-disable';

      // if (gantt.$container.classList.contains(__cnDisableHov)) {
      //   return;
      // }

      if (!task_id) {
        gantt._currentHoverRowId = null;
      }

      if (gantt.getState().link_source_id) {
        return;
      }

      const old_tasks = gantt.$container.querySelectorAll(`.row_hover:not([task_id='${task_id}'])`);

      _.each(old_tasks, t => {
        gantt.removeHoverForTaskRow(t);
      });

      if (~~gantt._currentHoverRowId === ~~task_id) {
        return;
      }

      const task_row = gantt.$container.querySelector(`.gantt_task_row[task_id='${task_id}']`);
      const grid_row = gantt.$container.querySelector(`.gantt_row[task_id='${task_id}']`);

      if (!grid_row || !task_row) {
        return;
      }

      gantt._currentHoverRowId = task_id;

      grid_row.classList.add('row_hover');
      task_row.classList.add('row_hover');

      gantt.callEvent('onAfterTaskRowHover', [task_id]);
    };

    gantt.removeHoverTaskRow = function (task_id, e) {
      if (!e.relatedTarget || (!e.relatedTarget.closest('.gantt_grid_data') && !e.relatedTarget.closest('.gantt_data_area') && !e.relatedTarget.closest(`.column-custom-options[task_id='${task_id}']`))) {
        const old_tasks = gantt.$container.querySelectorAll(`.row_hover[task_id='${task_id}']`);

        _.each(old_tasks, t => {
          gantt.removeHoverForTaskRow(t);
        });

        gantt._currentHoverRowId = null;
      }
    };

    gantt.removeHoverForTaskRow = function (element) {
      gantt._currentHoverRowId = null;
      element && element.classList && element.classList.remove('row_hover');
    };

    gantt.taskDataStoreIndex = function (id) {
      const calculateOrder = 0;
      const buttonType = gantt.config.types.button;
      let taskData = {};

      if (id) {
        taskData = gantt.isTaskExists(id) && gantt.getTask(id);

        if (taskData && taskData.sortorder) {
          return taskData.sortorder;
        }
      }

      return -1;
    };

    gantt.elementPosition = function (elem) {
      let top = 0; let left = 0; let right = 0; let
        bottom = 0;

      if (elem.getBoundingClientRect) { // HTML5 method
        const box = elem.getBoundingClientRectWrapper();
        const body = document.body;
        const docElem = (document.documentElement
          || document.body.parentNode
          || document.body);

        const scrollTop = window.pageYOffset || docElem.scrollTop || body.scrollTop;
        const scrollLeft = window.pageXOffset || docElem.scrollLeft || body.scrollLeft;
        const clientTop = docElem.clientTop || body.clientTop || 0;
        const clientLeft = docElem.clientLeft || body.clientLeft || 0;

        top = box.top + scrollTop - clientTop;
        left = box.left + scrollLeft - clientLeft;

        right = document.body.offsetWidth - box.right;
        bottom = document.body.offsetHeight - box.bottom;
      } else { // fallback to naive approach
        while (elem) {
          top += parseInt(elem.offsetTop, 10);
          left += parseInt(elem.offsetLeft, 10);
          elem = elem.offsetParent;
        }

        right = document.body.offsetWidth - elem.offsetWidth - left;
        bottom = document.body.offsetHeight - elem.offsetHeight - top;
      }

      return {
        y: Math.round(top),
        x: Math.round(left),
        width: elem.offsetWidth,
        height: elem.offsetHeight,
        right: Math.round(right),
        bottom: Math.round(bottom),
      };
    };

    gantt.getRelativeEventPosition = function (ev, node) {
      if (ev.pageX || ev.pageY) var pos = { x: ev.pageX, y: ev.pageY };

      const d = document.documentElement;
      var pos = {
        x: ev.clientX + d.scrollLeft - d.clientLeft,
        y: ev.clientY + d.scrollTop - d.clientTop,
      };

      const box = gantt.elementPosition(node);

      pos.x = pos.x - box.x + node.scrollLeft;
      pos.y = pos.y - box.y + node.scrollTop;

      return pos;
    };

    gantt.showTaskY = function (id) {
      const pos = this.getTaskPosition(this.getTask(id));
      const dataHeight = gantt.getScrollState().y;
      let top;

      if (!dataHeight) {
        top = pos.top;
      } else {
        top = pos.top - (dataHeight - this.config.row_height) / 2;
      }

      this.scrollTo(gantt.getScrollState().x, top);
    };

    gantt.getLinksByTaskId = function (taskId) {

    };

    gantt.getMasterLinksByTaskId = function (taskId) {
      let taskLinks = [];
      let masterLinkData = {};
      const resultData = [];

      if (gantt.isTaskExists(taskId)) {
        taskLinks = gantt.getTask(taskId).$target;

        taskLinks.forEach(masterLinkId => {
          if (!gantt.isLinkExists(masterLinkId)) {
            return false;
          }

          masterLinkData = gantt.getLink(masterLinkId);

          if (!gantt.isTaskExists(masterLinkData.source)) {
            return false;
          }

          resultData.push({
            linkId: masterLinkData.id,
            linkType: masterLinkData.type,
            dependencyTaskId: parseInt(masterLinkData.source, 10),
          });
        });
      }

      return resultData;
    };

    gantt.getSlaveLinksByTaskId = function (taskId) {
      let taskLinks = [];
      let slaveLinkData = {};
      const resultData = [];

      if (gantt.isTaskExists(taskId)) {
        taskLinks = gantt.getTask(taskId).$source;

        taskLinks.forEach(masterLinkId => {
          if (!gantt.isLinkExists(masterLinkId)) {
            return false;
          }

          slaveLinkData = gantt.getLink(masterLinkId);

          if (!gantt.isTaskExists(slaveLinkData.target)) {
            return false;
          }

          resultData.push({
            linkId: slaveLinkData.id,
            linkType: slaveLinkData.type,
            dependencyTaskId: parseInt(slaveLinkData.target, 10),
          });
        });
      }

      return resultData;
    };

    gantt.hideTaskOptions = function (e) {
      this.parentNode.removeChild(this);
    };

    gantt._task_default_render = function (task) {
      if (this._isAllowedUnscheduledTask(task)) {
        return;
      }

      const pos = this._get_task_pos(task);

      const cfg = this.config;
      const height = this._get_task_height();

      let padd = Math.floor((this.config.row_height - height) / 2);

      if (this._get_safe_type(task.type) == cfg.types.milestone && cfg.link_line_width > 1) {
        // little adjust milestone position, so horisontal corners would match link arrow when thickness of link line is more than 1px
        padd += 1;
      }

      const div = document.createElement('div');
      const width = gantt._get_task_width(task);
      const type = this._get_safe_type(task.type);

      div.setAttribute(this.config.task_attribute, task.id);

      if (cfg.show_progress && type != this.config.types.milestone) {
        this._render_task_progress(task, div, width);
      }

      // use separate div to display content above progress bar
      const content = gantt._render_task_content(task, width);

      if (task.textColor) {
        content.style.color = task.textColor;
      }

      div.appendChild(content);

      let css = this._combine_item_class(
        'gantt_task_line',
        this.templates.task_class(task.start_date, task.end_date, task),
        task.id,
      );

      if (task.color || task.progressColor || task.textColor) {
        css += ' gantt_task_inline_color';
      }

      div.className = css;

      const styles = [
        `left:${pos.x}px`,
        `top:${padd + pos.y}px`,
        `height:${height}px`,
        `line-height:${Math.max(height < 30 ? height - 2 : height, 0)}px`,
        `width:${width}px`,
      ];

      if (task.color) {
        styles.push(`background-color:${task.color}`);
      }

      if (task.textColor) {
        styles.push(`color:${task.textColor}`);
      }

      div.style.cssText = styles.join(';');

      let side = this._render_leftside_content(task);

      if (side) {
        div.appendChild(side);
      }

      side = this._render_rightside_content(task);

      if (side) {
        div.appendChild(side);
      }

      gantt._waiAria.setTaskBarAttr(task, div);

      if (!this._is_readonly(task)) {
        if (cfg.drag_resize && type != this.config.types.milestone) {
          if (!this._is_flex_task(task)) {
            gantt._render_pair(div, 'gantt_task_drag', task, css => {
              const el = document.createElement('div');

              el.className = css;

              return el;
            });
          } else {
            gantt._render_pair(div, 'gantt_task_no_drag tooltip-gantt', task, css => {
              const el = document.createElement('div');

              el.setAttribute('data-key', 'gantt_parent_no_drug');
              el.className = css;

              return el;
            });
          }
        }

        if (cfg.drag_links && this.config.show_links) {
          gantt._render_pair(div, 'gantt_link_control', task, css => {
            const outer = document.createElement('div');

            outer.className = css;
            outer.style.cssText = [
              `height:${height}px`,
              `line-height:${height}px`,
            ].join(';');

            const inner = document.createElement('div');

            inner.className = 'gantt_link_point';
            inner.style.display = gantt._show_link_points ? 'block' : '';
            outer.appendChild(inner);

            return outer;
          });
        }
      }

      return div;
    };

    const getWeekDays = function (date) {
      if (_.isString(date)) {
        date = moment(date, 'YYYY-MM-DD HH:mm:ss').toDate();
      } else {
        date = new Date(date);
      }

      const weekStart = gantt.date.week_start(date);
      const weekEnd = gantt.date.add(weekStart, 7, 'day');

      const workStart = gantt.getClosestWorkTime({ date: weekStart, dir: 'future', unit: gantt.config.duration_unit });
      const workEnd = gantt.getClosestWorkTime({ date: weekEnd, dir: 'past', unit: gantt.config.duration_unit });

      if (gantt.date.week_start(new Date(workStart)).valueOf() != gantt.date.week_start(new Date(workEnd)).valueOf()) {
        return {
          firstDay: 0,
          lastDay: 0,
          daysPerWeek: 0,
          workStart,
          workEnd,
        };
      }

      let firstDay = 0;
      let lastDay = 0;

      if (gantt.config.start_on_monday) {
        firstDay = workStart.getDay() - 1;
        lastDay = workEnd.getDay() - 1;

        firstDay = firstDay < 0 ? 6 : firstDay;
        lastDay = lastDay < 0 ? 6 : lastDay;
      } else {
        firstDay = workStart.getDay();
        lastDay = workEnd.getDay();
      }

      return {
        firstDay,
        lastDay,
        daysPerWeek: lastDay - firstDay + 1,
        workStart,
        workEnd,
      };
    };

    gantt.sync_link = function (link) {
      if (gantt.isTaskExists(link.source)) {
        const sourceTask = gantt.getTask(link.source);

        sourceTask.$source = sourceTask.$source || [];

        if (sourceTask.$source.indexOf(link.id) === -1) {
          sourceTask.$source.push(link.id);
        }
      }

      if (gantt.isTaskExists(link.target)) {
        const targetTask = gantt.getTask(link.target);

        targetTask.$target = targetTask.$target || [];

        if (targetTask.$target.indexOf(link.id) === -1) {
          targetTask.$target.push(link.id);
        }
      }
    };

    gantt.sync_links = function () {
      let task = null;
      const tasks = gantt.$data.tasksStore.getItems();

      for (var i = 0, len = tasks.length; i < len; i++) {
        task = tasks[i];
        task.$source = [];
        task.$target = [];
      }

      const links = gantt.$data.linksStore.getItems();

      for (var i = 0, len = links.length; i < len; i++) {
        const link = links[i];

        if (gantt.isLinkExists(link.id)) {
          gantt.sync_link(link);
        }
      }
    };

    gantt.sync_link_delete = function (link) {
      if (gantt.isTaskExists(link.source)) {
        const sourceTask = gantt.getTask(link.source);

        _.remove(sourceTask.$source, linkId => +linkId === +link.id);

        gantt.refreshTask(link.source);
      }

      if (gantt.isTaskExists(link.target)) {
        const targetTask = gantt.getTask(link.target);

        _.remove(targetTask.$target, linkId => +linkId === +link.id);

        gantt.refreshTask(link.target);
      }
    };

    gantt.scaleWeekDate = function (date) {
      if (!date || gantt.getState().scale_unit != 'week') {
        return date;
      }

      if (_.isString(date)) {
        date = moment(date, 'YYYY-MM-DD HH:mm:ss').toDate();
      } else {
        date = new Date(date);
      }

      const workHours = getWeekDays(date);

      if (!workHours.daysPerWeek) {
        return date;
      }

      let startDayOfWeek = 0;

      if (gantt.config.start_on_monday) {
        startDayOfWeek = date.getDay() - 1;

        startDayOfWeek = startDayOfWeek < 0 ? 6 : startDayOfWeek;
      } else {
        startDayOfWeek = date.getDay();
      }

      const diffDate = startDayOfWeek - workHours.firstDay;

      let newDay = (diffDate / workHours.daysPerWeek) * 7;

      newDay = newDay < 0 ? 6 : newDay;
      newDay = newDay * 1000 * 60 * 60 * 24;

      let result = new Date(date);

      result = gantt.date.week_start(result);
      result = new Date(result.valueOf() + newDay);

      result = gantt.date.add(result, date.getHours(), 'hour');

      return result;
    };

    gantt.isDeepEmpty = function (obj) {
      return _.every(obj, prop => {
        if (!prop || _.isNull(prop) || prop === '' || prop.length === 0) {
          return true;
        } if (_.isDate(prop)) {
          return false;
        } if (_.isObject(prop)) {
          return gantt.isDeepEmpty(prop);
        }

        return false;
      });
    };

    gantt.isWorkDay = function (date, calendar) {
      if (_.isString(date)) {
        date = moment(date, 'YYYY-MM-DD HH:mm:ss').toDate();
      } else {
        date = new Date(date);
      }

      let isWorkDay = true;

      if (!calendar && !gantt.config.worktimes) {
        return isWorkDay;
      }

      let workDates = calendar ? calendar.getConfig().dates : gantt.config.worktimes.global.dates;
      // var dayDate = gantt.date.day_start(date).valueOf();
      // calculate timestamp like in dhtmlx _timestamp()
      const dayDate = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());

      if (gantt.globalCash.isWorkDays[dayDate] && !calendar) {
        return gantt.globalCash.isWorkDays[dayDate];
      }

      if (calendar) {
        workDates = calendar.getConfig().dates;
      }

      const day = date.getDay();

      const isCustomDay = _.find(workDates, (value, key) => {
        if (key.length < 2 || dayDate !== +key) {
          return false;
        }

        return true;
      });

      if (_.isUndefined(isCustomDay)) {
        isWorkDay = !!workDates[day];
      } else {
        isWorkDay = !!isCustomDay;
      }

      if (!calendar) {
        gantt.globalCash.isWorkDays[dayDate] = isWorkDay;
      }

      return isWorkDay;
    };

    gantt.isWorkStartTime = function (date, calendar) {
      if (_.isString(date)) {
        date = moment(date, 'YYYY-MM-DD HH:mm:ss').toDate();
      } else {
        date = new Date(date);
      }

      // const tempHours = gantt.getWorkHours(calendar ? { date, calendar } : date);
      const tempHours = calendar._worktime.parsed.hours;
      const startHours = date.getHours();

      let workHoursInDay = false;
      const hoursLength = tempHours.length;

      for (let i = 0; i < hoursLength; i += 1) {
        const startH = tempHours[i].startHour;
        const endH = tempHours[i].endHour;

        for (let j = startH; j < endH; j += 1) {
          if (j === startHours) {
            workHoursInDay = true;
          }
        }
      }

      return workHoursInDay;
    };

    gantt.isWorkEndTime = function (date, calendar) {
      if (_.isString(date)) {
        date = moment(date, 'YYYY-MM-DD HH:mm:ss').toDate();
      } else {
        date = new Date(date);
      }

      // const tempHours = gantt.getWorkHours(calendar ? { date, calendar } : date);
      const tempHours = calendar._worktime.parsed.hours;
      const endHours = date.getHours();

      let workHoursInDay = false;
      const hoursLength = tempHours.length;

      for (let i = 0; i < hoursLength; i += 1) {
        const startH = tempHours[i].startHour;
        const endH = tempHours[i].end / (60 * 60);

        for (let j = startH; j <= endH; j += 1) {
          if (j === endHours) {
            workHoursInDay = true;
          }
        }
      }

      return workHoursInDay;
    };

    gantt.getWorkDaysBetweenDates = function (startDate, endDate, calendar) {
      if (_.isString(startDate)) {
        startDate = moment(startDate, 'YYYY-MM-DD HH:mm:ss').toDate();
      } else {
        startDate = new Date(startDate);
      }

      if (_.isString(endDate)) {
        endDate = moment(endDate, 'YYYY-MM-DD HH:mm:ss').toDate();
      } else {
        endDate = new Date(endDate);
      }

      if (endDate && endDate.getHours() === 0) {
        endDate = moment(endDate).subtract(1, 'days').toDate();
      }

      let startPoint = gantt.date.day_start(startDate);
      const endPoint = gantt.date.day_start(endDate);
      let dayCount = 0;

      while (startPoint <= endPoint) {
        if (gantt.isWorkDay(startPoint, calendar)) {
          dayCount += 1;
        }

        startPoint = gantt.date.add(startPoint, 1, 'day');
      }

      return dayCount;
    };

    gantt.getWorkHoursBetweenDates = function (startDate, endDate, calendar) {
      if (startDate) {
        if (_.isString(startDate)) {
          startDate = moment(startDate, 'YYYY-MM-DD HH:mm:ss').toDate();
        } else {
          startDate = new Date(startDate);
        }
      }

      if (endDate) {
        if (_.isString(endDate)) {
          endDate = moment(endDate, 'YYYY-MM-DD HH:mm:ss').toDate();
        } else {
          endDate = new Date(endDate);
        }
      }

      if (!startDate && endDate && endDate.getHours() === 0) {
        endDate = moment(endDate).subtract(1, 'days').toDate();
      }

      const tempHours = calendar._worktime.parsed.hours;
      const startHours = startDate ? startDate.getHours() : 0;

      const endHours = endDate ? endDate.getHours() || 24 : 24;
      const startDateMinutes = startDate ? startDate.getMinutes() : 0;
      const endDateMinutes = endDate ? endDate.getMinutes() : 0;
      let workMinutesInDay = 0;
      const hoursLength = tempHours.length;

      for (let i = 0; i < hoursLength; i += 1) {
        const startH = +tempHours[i].startMinute / 60;
        const endH = +tempHours[i].endMinute / 60;

        for (let j = startH; j < endH; j += 0.5) {
          if (j >= (startHours + 1) && j < endHours) {
            workMinutesInDay += 30;
          }
          if (j === endHours) {
            workMinutesInDay += endDateMinutes;
          } else if (j === startHours) {
            workMinutesInDay += 60 - startDateMinutes;
          }
        }
      }

      return +parseFloat(workMinutesInDay / 60).toFixed(2);
    };

    gantt.getWorkHoursByDate = function (date, calendar) {
      if (_.isString(date)) {
        date = moment(date, 'YYYY-MM-DD HH:mm:ss').toDate();
      } else {
        date = new Date(date);
      }

      let tempHours = [];
      let workHoursInDay = 0;

      tempHours = gantt.getWorkHours(calendar ? { date, calendar } : date);
      workHoursInDay = gantt.getDefaultWorkHoursPerDay(tempHours);

      return workHoursInDay;
    };

    gantt.getDefaultWorkHoursPerDay = function (dayHours) {
      const hoursIntervals = dayHours || gantt.config.durationData.showTime;
      let workHoursInDay = 0;
      let tempHours = [];

      if (typeof hoursIntervals[0] === 'string') {
        tempHours = hoursIntervals.map(i => i.split('-')).flat(2).map(i => {
          const hours = i.split(':');
          let res = Number(hours[0]);

          if (Number(hours[1])) {
            res += 0.5;
          }

          return res;
        });
      } else {
        tempHours = hoursIntervals;
      }

      for (let i = 0; i < tempHours.length; i += 2) {
        workHoursInDay += tempHours[i + 1] - tempHours[i];
      }

      return workHoursInDay;
    };

    gantt.getDateRangeByDays = function (startDate, endDate, calendar) {
      if (_.isString(startDate)) {
        startDate = moment(startDate, 'YYYY-MM-DD HH:mm:ss').toDate();
      } else {
        startDate = new Date(startDate);
      }

      if (_.isString(endDate)) {
        endDate = moment(endDate, 'YYYY-MM-DD HH:mm:ss').toDate();
      } else {
        endDate = new Date(endDate);
      }

      let startPoint = gantt.date.day_start(startDate);
      const endPoint = gantt.date.day_start(endDate);
      let dayCount = 0;
      const arrayDays = {};

      while (startPoint <= endPoint) {
        if (gantt.isWorkDay(startPoint, calendar)) {
          dayCount += 1;
          arrayDays[dayCount] = startPoint;
        }

        startPoint = gantt.date.add(startPoint, 1, 'day');
      }

      return arrayDays;
    };

    gantt.getDateByDayPart = function (startDate, dayPart, calendar) {
      if (_.isString(startDate)) {
        startDate = moment(startDate, 'YYYY-MM-DD HH:mm:ss').toDate();
      } else {
        startDate = new Date(startDate);
      }

      let startPoint = gantt.date.day_start(startDate);
      let dayCount = 0;

      while (dayCount < dayPart) {
        if (gantt.isWorkDay(startPoint, calendar)) {
          dayCount += 1;
        }

        if (dayCount < dayPart) {
          startPoint = gantt.date.add(startPoint, 1, 'day');
        }
      }

      return startPoint;
    };

    gantt.getDayHours = function (date, calendar) {
      if (_.isString(date)) {
        date = moment(date, 'YYYY-MM-DD HH:mm:ss').toDate();
      } else {
        date = moment(date).toDate();
      }

      const dayStart = gantt.date.day_start(date);
      const dayEnd = gantt.date.add(dayStart, 1, 'day');

      const workStart = gantt.getClosestWorkTime({
        date: dayStart, dir: 'future', unit: gantt.config.duration_unit, calendar,
      });
      let workEnd = gantt.getClosestWorkTime({
        date: dayEnd, dir: 'past', unit: gantt.config.duration_unit, calendar,
      });

      if (workEnd.valueOf() < workStart.valueOf()) {
        workEnd = gantt.date.add(workEnd, 1, 'day');
      }

      if (gantt.date.date_part(gantt.copy(workStart)).valueOf() !== gantt.date.date_part(gantt.copy(workEnd)).valueOf()) {
        return {
          firstHour: 0,
          lastHour: 0,
          hoursPerDay: 0,
        };
      }

      return {
        firstHour: workStart.getHours(),
        lastHour: workEnd.getHours(),
        hoursPerDay: workEnd.getHours() - workStart.getHours(),
      };
    };

    gantt.scaleDayDate = function (date, calendar) {
      if (_.isString(date)) {
        date = moment(date).toDate();
      }

      const workHours = gantt.getDayHours(date, calendar);

      if (!date || !workHours.hoursPerDay) {
        return date;
      }

      const startHour = date.getHours();
      let newHour = ((startHour - workHours.firstHour) / workHours.hoursPerDay) * 24;

      if (newHour < 0) {
        newHour = 0;
      }

      const result = new Date(date);

      result.setHours(newHour);

      return result;
    };

    gantt._checkTimeout = function (host, updPerSecond) {
      if (!updPerSecond) {
        return true;
      }

      if (host._on_timeout) {
        return false;
      }

      const timeout = Math.ceil(1000 / updPerSecond);

      if (timeout < 2) {
        return true;
      }

      setTimeout(() => {
        delete host._on_timeout;
      }, timeout);

      host._on_timeout = true;

      return true;
    };

    gantt.__scaleTask = function (task) {
      const copy = gantt.copy(task);

      if (!gantt.config.disableScale && gantt.config.durationData.mode !== 'hour') {
        const calendar = gantt.getCalendar(task.gantt_id);

        copy.start_date = gantt.scaleWeekDate(gantt.scaleDayDate(task.start_date, calendar));
        copy.end_date = gantt.scaleWeekDate(gantt.scaleDayDate(task.end_date, calendar));
      }

      return copy;
    };

    gantt._init_tasks_range = function () {
      const unit = this._scale_range_unit();

      if (this.config.start_date && this.config.end_date) {
        this._min_date = this.date[`${unit}_start`](new Date(this.config.start_date));

        let end = new Date(this.config.end_date);
        const start_interval = this.date[`${unit}_start`](new Date(end));

        if (+end != +start_interval) {
          end = this.date.add(start_interval, 1, unit);
        } else {
          end = start_interval;
        }

        this._max_date = end;

        return;
      }

      // reset project timing
      this._get_tasks_data();

      const range = this.getSubtaskDates();

      this._min_date = range.start_date;
      this._max_date = range.end_date;

      if (!(this._max_date && this._min_date)) {
        this._min_date = new Date();
        this._max_date = new Date();
      }

      this._min_date = this.date[`${unit}_start`](this._min_date);
      this._min_date = this.calculateEndDate({
        start_date: this.date[`${unit}_start`](this._min_date),
        duration: -2,
        unit,
      });// one free column before first task

      this._max_date = this.date[`${unit}_start`](this._max_date);
      this._max_date = this.calculateEndDate({ start_date: this._max_date, duration: 3, unit });// one free column after last task
    };

    // ------------------ for workers helpers --------------------//

    gantt._setCalendarConfigs = function (durationData) { // TODO reuse maybe
      let work_days = [];
      let arrHours = []; // durationData.startTime < durationData.endTime ? [durationData.startTime, durationData.endTime] : [durationData.startTime];
      const allDays = [0, 1, 2, 3, 4, 5, 6];

      gantt.clearCash('isWorkDays');

      const setWorkTime = function (durationData) {
        let hoursWork;

        if (durationData.showTime.length) {
          hoursWork = durationData.showTime;
        } else if (durationData.startTime !== undefined && durationData.endTime) {
          hoursWork = [durationData.startTime, durationData.endTime];
        } else {
          hoursWork = [0, 24];
        }

        return hoursWork;
      };

      arrHours = setWorkTime(durationData);

      if (durationData.showDay && durationData.showDay.length) {
        work_days = durationData.showDay;
      } else {
        work_days = [1, 2, 3, 4, 5, 6, 7];
      }

      work_days.map((day, index) => {
        if (day == 7) {
          work_days[index] = 0;
        }
      });

      gantt.config.worktimes = {
        global: gantt.getCalendar('global').getConfig(),
      };

      // gantt.config.worktimes.global.dates = {};

      allDays.map(dayOfWeekNumber => {
        if (
          work_days.indexOf(dayOfWeekNumber) !== -1
          && dayOfWeekNumber < 7
        ) {
          gantt.setWorkTime({ day: dayOfWeekNumber, hours: arrHours });
        } else {
          gantt.setWorkTime({ day: dayOfWeekNumber, hours: false });
        }
      });

      const datesKeys = Object.keys(gantt.config.worktimes.global.dates);

      datesKeys.forEach(key => {
        if (key > 7) {
          gantt.setWorkTime({ date: new Date(+key), hours: work_days[key] });
        }
      });
    };

    gantt._initCustomWorkTime = function (customDaysData, totalEstimate, calendar) {
      const _getSortingDays = function (customDaysData) {
        const sortingResult = {
          exception: [],
          repeat: [],
        };

        _.each(customDaysData, dayData => {
          if (dayData && dayData.hours && _.isString(dayData.hours)) {
            dayData.hours = JSON.parse(dayData.hours);
          }

          if (typeof dayData !== 'string' && dayData?.repeat) {
            sortingResult.repeat.push(dayData);
          } else {
            sortingResult.exception.push(dayData);
          }
        });

        sortingResult.exception.sort((a, b) => {
          if (a.to && !b.to) {
            return -1;
          }

          if (a.to && b.to) {
            if (new Date(a.to).valueOf() - new Date(a.from).valueOf() <= new Date(b.to).valueOf() - new Date(b.from).valueOf()) {
              return 1;
            }

            return 0;
          }

          return 1;
        });

        sortingResult.repeat.sort((a, b) => {
          if (a.repeat !== 'week' && b.repeat === 'week') {
            return -1;
          }

          if (a.repeat === 'month' && b.repeat === 'year') {
            return -1;
          }

          if (a.repeat === 'week' && b.repeat !== 'week') {
            return 0;
          }

          if (a.repeat === b.repeat) {
            return 0;
          }

          return 1;
        });

        return sortingResult;
      };

      const _createDayStartedAt00 = function (date) {
        return gantt.date.day_start(moment(date).toDate());
      };

      const _setWorkTimeByDate = function (dayFrom, dayTo, dayHours) {
        if (!dayHours) {
          dayHours = false;
        }

        if (!dayTo) {
          // if single date
          if (calendar) {
            calendar.setWorkTime({ date: dayFrom, hours: dayHours });
          } else {
            gantt.setWorkTime({ date: dayFrom, hours: dayHours });
          }
        } else {
          // if repeatable
          while (dayFrom.valueOf() <= dayTo.valueOf()) {
            if (calendar) {
              calendar.setWorkTime({ date: dayFrom, hours: dayHours });
            } else {
              gantt.setWorkTime({ date: dayFrom, hours: dayHours });
            }
            dayFrom.setDate(dayFrom.getDate() + 1);
          }
        }
      };

      const customDays = _getSortingDays(customDaysData);
      let stepDay = {};
      let tempDate = {};
      let tempDateTo = {};
      const datesPeriod = {
        maxDate: totalEstimate.end_date,
        minDate: totalEstimate.start_date,
      };
      let tempEndDate = {};

      // set repeat days
      customDays.repeat?.forEach(repeatDayData => {
        if (!repeatDayData.hours || !repeatDayData.hours.length) {
          // if weekend
          repeatDayData.hours = false;
        }

        switch (repeatDayData.repeat) {
        case 'week':
          stepDay = _createDayStartedAt00(repeatDayData.from);

          tempEndDate = repeatDayData.repeat_to ? _createDayStartedAt00(repeatDayData.repeat_to) : datesPeriod.maxDate;
          tempDateTo = _createDayStartedAt00(repeatDayData.to);

          while (stepDay.valueOf() <= tempEndDate.valueOf()) {
            _setWorkTimeByDate(
              new Date(stepDay),
              repeatDayData.to ? tempDateTo : null,
              repeatDayData.hours,
            );

            stepDay.setDate(stepDay.getDate() + 7);
          }

          break;
        case 'month':
          tempDate = _createDayStartedAt00(repeatDayData.from).getDate();

          tempDateTo = _createDayStartedAt00(repeatDayData.to).getDate();
          stepDay = _createDayStartedAt00(repeatDayData.from);
          tempEndDate = repeatDayData.repeat_to ? _createDayStartedAt00(repeatDayData.repeat_to) : datesPeriod.maxDate;

          while (stepDay.valueOf() <= tempEndDate.valueOf()) {
            _setWorkTimeByDate(
              new Date(stepDay.getFullYear(), stepDay.getMonth(), tempDate),
              repeatDayData.to ? new Date(stepDay.getFullYear(), stepDay.getMonth(), tempDate) : null,
              repeatDayData.hours,
            );

            stepDay.setMonth(stepDay.getMonth() + 1);
          }
          break;
        case 'year':
          tempDate = _createDayStartedAt00(repeatDayData.from);
          tempDateTo = _createDayStartedAt00(repeatDayData.to);
          stepDay = _createDayStartedAt00(repeatDayData.from);

          tempEndDate = repeatDayData.repeat_to
            ? _createDayStartedAt00(repeatDayData.repeat_to)
            : datesPeriod.maxDate;

          while (stepDay.valueOf() <= tempEndDate.valueOf()) {
            _setWorkTimeByDate(
              new Date(stepDay),
              repeatDayData.to
                ? _createDayStartedAt00(repeatDayData.to)
                : null,
              repeatDayData.hours,
            );

            stepDay.setFullYear(stepDay.getFullYear() + 1);
          }
          break;
        }
      });

      customDays.exception?.forEach(customDayData => {
        if (!customDayData.from && !customDayData.to) {
          return false;
        }

        if (!customDayData.hours || !customDayData.hours.length) {
          // if weekend
          customDayData.hours = false;
        }
        _setWorkTimeByDate(
          _createDayStartedAt00(customDayData.from),
          customDayData.to
            ? _createDayStartedAt00(customDayData.to)
            : null,
          customDayData.hours,
        );
      });
    };

    gantt.getAllWorkHoursBetweenDates = function (startDate, endDate, calendar) {
      let result = 0;

      if (!calendar) {
        calendar = gantt.getCalendar('global');
      }

      if (_.isString(startDate)) {
        startDate = moment(startDate, 'YYYY-MM-DD HH:mm:ss').toDate();
      }

      if (_.isString(endDate)) {
        endDate = moment(endDate, 'YYYY-MM-DD HH:mm:ss').toDate();
      }

      const dayStartWorkData = gantt.getDayHours(startDate, calendar);
      const dayEndWorkData = gantt.getDayHours(endDate, calendar);

      if (dayStartWorkData.firstHour < startDate.getHours()) {
        result += gantt.getWorkHoursBetweenDates(startDate, moment(startDate).add(dayStartWorkData.hoursPerDay, 'hours').toDate(), calendar);
        startDate = moment(startDate).add(1, 'days').toDate();
      }

      const endHour = endDate.getHours() || 24;

      if (dayEndWorkData.lastHour > endHour) {
        result += gantt.getWorkHoursBetweenDates(moment(endDate).startOf('day').toDate(), endDate, calendar);
        endDate = moment(endDate).subtract(1, 'days').toDate();
      }

      while (startDate <= endDate) {
        if (gantt.isWorkDay(startDate, calendar)) {
          result += gantt.getWorkHoursByDate(startDate, calendar);
        }

        startDate = moment(startDate).add(1, 'days').toDate();
      }

      return result;
    };

    /**
     * return sum of resource work hours
     *
     * @param {date} date - current date
     * @param {string} period - day, week, month...
     * @param {object} resourceCalendar
     * @param {array} resourceCustomDays
     * @return {float number}
     */
    gantt.getResourceWorkHoursForPeriod = function (date, period, resourceData) {
      let startPoint = gantt.date[`${period}_start`](_.clone(date));
      const endPoint = gantt.date.add(startPoint, 1, period);
      let hoursCount = 0;
      const cashDates = {};

      while (startPoint.valueOf() < endPoint.valueOf()) {
        let workingResourceHours = null;

        _.some(resourceData.customDays, item => {
          let sD = {};
          let eD = {};
          let toDate = item.to;
          let repeatDate = null;

          if (cashDates[item.from]) {
            sD = cashDates[item.from];
          } else {
            sD = gantt.date.day_start(moment(item.from).utc().toDate()).valueOf();
            cashDates[item.from] = sD;
          }

          if (_.isNull(toDate) || toDate === undefined) {
            toDate = moment(item.from).add(1, 'day').format();
          }

          if (cashDates[toDate]) {
            eD = cashDates[toDate];
          } else {
            eD = gantt.date.day_start(moment(toDate).utc().toDate()).valueOf();
            cashDates[toDate] = eD;
          }

          if (item.repeat) {
            let checked = false;
            let stopChecking = false;
            const repeatTo = item.repeatTo && moment(item.repeatTo).utc().toDate();

            repeatDate = moment(item.from).utc().toDate();

            while (repeatDate.valueOf() <= startPoint && !checked && !stopChecking) {
              const currDate = repeatDate.valueOf();

              if (repeatTo && currDate > repeatTo.valueOf()) {
                stopChecking = true;

                return;
              }

              if (currDate >= startPoint && currDate < endPoint) {
                workingResourceHours = item.workingHours;
                checked = true;
              }

              if (item.repeat === 'week') {
                repeatDate.setDate(repeatDate.getDate() + 7);
              }

              if (item.repeat === 'month') {
                repeatDate.setMonth(repeatDate.getMonth() + 1);
              }

              if (item.repeat === 'year') {
                repeatDate.setFullYear(repeatDate.getFullYear() + 1);
              }
            }

            if (checked) {
              return true;
            }
          }

          const cD = startPoint.valueOf();

          if (cD >= sD && ((item.to && cD <= eD) || cD < eD)) {
            workingResourceHours = item.workingHours;

            return true;
          }

          return false;
        });

        if (_.isNull(workingResourceHours)) {
          const workingHours = _.map(resourceData.workingDays, day => day % 7);

          if (_.includes(workingHours, startPoint.getDay())) {
            workingResourceHours = resourceData.workingHours;
          } else {
            workingResourceHours = 0;
          }
        }

        hoursCount += +workingResourceHours;

        startPoint = gantt.date.add(startPoint, 1, 'day');
      }

      return hoursCount;
    };

    gantt.estimationCalculator = {
      MODES: {
        fixEstimation: 1,
        fixDuration: 2,
        fixEstimationAndDuration: 3,
      },

      // _resourceInfinityIssue: null,

      _prepareTaskData(task) {
        const taskData = gantt.copy(task);

        if (_.isString(taskData.start_date)) {
          taskData.start_date = moment(taskData.start_date, 'YYYY-MM-DD HH:mm:ss').toDate();
        }
        if (_.isString(taskData.end_date)) {
          taskData.end_date = moment(taskData.end_date, 'YYYY-MM-DD HH:mm:ss').toDate();
        }

        return taskData;
      },

      _getEstimationMode(ganttId) {
        if (!gantt.config._isWorkerMode) {
          gantt.callEvent('updateGanttEstimationConfig', [ganttId]);
        }

        const defaultMode = this.MODES.fixDuration;

        if (!gantt.config.estimation_mode) {
          return defaultMode;
        }

        return +gantt.config.estimation_mode;
      },

      _checkApplyResourceCalendar(ganttId) {
        const defaultState = false;

        if (this._getEstimationMode(ganttId) !== this.MODES.fixEstimation) {
          return defaultState;
        }

        return gantt.config.apply_resource_calendar;
      },

      /**
       * return collection resource estimation by every day on task
       *
       * @param {object} task
       * @param {object} resource - prepared task resource(time type only!)
       * @return {object} map of days data
       */
      getEstimationByDays(task, resource) {
        const taskData = this._prepareTaskData(task);
        const taskCalendar = gantt.getCalendar(taskData.gantt_id) || gantt.getCalendar('global');
        const isApplyResourceCalendar = this._checkApplyResourceCalendar(taskData.gantt_id);
        const dateStartDay = gantt.date.day_start(_.clone(taskData.start_date));
        const dateEndDay = gantt.date.day_start(_.clone(taskData.end_date));
        const workDaysCount = gantt.getWorkDaysBetweenDates(_.clone(taskData.start_date), _.clone(taskData.end_date), taskCalendar);
        const resultDaysMap = {};

        const resourceToTask = _.find(taskData.resources, taskRes => (+taskRes.task_id === +taskData.id && +taskRes.resource_id === (resource.resource_id || resource.id)));

        if (!resourceToTask) {
          return {};
        }

        let resourceEstimation = resourceToTask.value;
        let taskDuration = (taskData.duration / 60).toFixed(2);

        const workDaysOnTask = gantt.getDateRangeByDays(dateStartDay, dateEndDay, taskCalendar);

        resourceToTask.custom_days && resourceToTask.custom_days.forEach(customDay => {
          if (customDay.day_part > workDaysCount || _.isNull(customDay.estimation)) {
            return;
          }

          let taskWorkHoursInCurrentDay = 0;

          if (customDay.day_part === 1) {
            taskWorkHoursInCurrentDay = gantt.getWorkHoursBetweenDates(
              _.clone(taskData.start_date),
              workDaysCount === 1 ? _.clone(taskData.end_date) : null,
              taskCalendar,
            );
          } else {
            taskWorkHoursInCurrentDay = gantt.getWorkHoursByDate(workDaysOnTask[customDay.day_part], taskCalendar);
          }

          resultDaysMap[customDay.day_part] = {
            estimation: customDay.estimation,
            isCustom: true,
          };

          resourceEstimation -= customDay.estimation;
          taskDuration -= taskWorkHoursInCurrentDay;
        });

        let workDayNumber = 0;
        const notPreparedDays = {};
        let resourceWorkAvailabilityOnTask = 0;

        while (workDayNumber < workDaysCount) {
          const currentDuration = 0;
          let taskWorkHoursInCurrentDay = 0;

          workDayNumber++;

          if (resultDaysMap[workDayNumber] === undefined) {
            if (workDayNumber === 1) {
              taskWorkHoursInCurrentDay = gantt.getWorkHoursBetweenDates(
                _.clone(taskData.start_date),
                workDaysCount === 1 ? _.clone(taskData.end_date) : null,
                taskCalendar,
              );
            } else if (workDayNumber === workDaysCount) {
              taskWorkHoursInCurrentDay = gantt.getWorkHoursBetweenDates(null, _.clone(taskData.end_date), taskCalendar);
            } else {
              taskWorkHoursInCurrentDay = gantt.getWorkHoursByDate(workDaysOnTask[workDayNumber], taskCalendar);
            }

            let resourceAvailabilityInCurrentDay = isApplyResourceCalendar
              ? gantt.getResourceWorkHoursForPeriod(workDaysOnTask[workDayNumber], 'day', resource) : taskWorkHoursInCurrentDay;

            if (resourceAvailabilityInCurrentDay >= taskWorkHoursInCurrentDay) {
              resourceAvailabilityInCurrentDay = taskWorkHoursInCurrentDay;
            }

            notPreparedDays[workDayNumber] = {
              estimation: resourceAvailabilityInCurrentDay,
              isCustom: false,
            };

            resourceWorkAvailabilityOnTask += resourceAvailabilityInCurrentDay;
          }
        }

        let diff = 0;
        let ratio = resourceEstimation / resourceWorkAvailabilityOnTask;
        const delta = resourceEstimation - resourceWorkAvailabilityOnTask;
        let tempEstimationSum = 0;

        if (Math.abs(delta) <= 0.01) {
          ratio = 1;
          diff = Math.round(delta * 100) / 100;
        }

        Object.keys(notPreparedDays).forEach((workDayNumber, i) => {
          let dayEstimate = notPreparedDays[workDayNumber].estimation;
          let dayEstimation = Math.round(dayEstimate * ratio * 100) / 100;

          if (i + 1 === Object.keys(notPreparedDays).length && diff) {
            dayEstimate += diff;
          }

          if (tempEstimationSum >= resourceToTask.value) {
            dayEstimation = 0;
          } else {
            tempEstimationSum += dayEstimation;
          }

          resultDaysMap[workDayNumber] = {
            ...notPreparedDays[workDayNumber],
            estimation: dayEstimation,
          };
        });

        const calcSum = Object.values(resultDaysMap).reduce((sum, d) => {
          sum += d.estimation;

          return sum;
        }, 0);

        if (calcSum !== resourceToTask.value) {
          let lastDay = Object.values(resultDaysMap).length;

          while (resultDaysMap[lastDay] && !resultDaysMap[lastDay].estimation) {
            --lastDay;
          }

          if (resultDaysMap[lastDay]) {
            resultDaysMap[lastDay].estimation += Math.round((resourceToTask.value - calcSum) * 100) / 100;
          }
        }

        return resultDaysMap;
      },

      /**
       * return new duration based on changed resources estimation
       *
       * @param {object} task
       * @param {array} taskResources - prepared task resources(time type only!) with new values and resource calendar, calendar custom days
       * @return {number} new duration in minutes
       */
      calculateDuration(task, taskResources) {
        const taskData = this._prepareTaskData(task);
        const taskCalendar = gantt.getCalendar(taskData.gantt_id) || gantt.getCalendar('global');
        const isApplyResourceCalendar = this._checkApplyResourceCalendar(taskData.gantt_id);
        const taskResourcesEstimationMap = {};
        const taskStartDay = gantt.date.day_start(_.clone(taskData.start_date));
        // const taskWorkDaysCount = gantt.getWorkHoursBetweenDates(_.clone(taskData.start_date), _.clone(taskData.end_date), taskCalendar);
        let resultDuration = 0;
        let restTaskEstimation = 0;
        // let defaultTaskEstimation = 0;
        let dayCount = 0;
        let workDayCount = 1;
        let isLastDay = false;

        taskResources.forEach(obj => {
          taskResourcesEstimationMap[obj.resource_id] = +obj.value;
          restTaskEstimation += +obj.value;
        });

        // defaultTaskEstimation = restTaskEstimation;

        while (restTaskEstimation > 0) {
          const currentDay = gantt.date.add(taskStartDay, dayCount, 'day');

          dayCount++;

          if (gantt.isWorkDay(currentDay, taskCalendar)) {
            let taskWorkHoursInCurrentDay = 0;
            let biggestTaskResourceEstimationRest = 0;

            if (workDayCount === 1) {
              taskWorkHoursInCurrentDay = gantt.getWorkHoursBetweenDates(_.clone(taskData.start_date), null, taskCalendar);
            } else {
              taskWorkHoursInCurrentDay = gantt.getWorkHoursByDate(currentDay, taskCalendar);
            }

            isLastDay = true;

            taskResources.forEach(resource => {
              const resourceAvailabilityInCurrentDay = isApplyResourceCalendar
                ? gantt.getResourceWorkHoursForPeriod(currentDay, 'day', resource) : taskWorkHoursInCurrentDay;

              let resourceWorkHoursInCurrentDay = resourceAvailabilityInCurrentDay >= taskWorkHoursInCurrentDay
                ? taskWorkHoursInCurrentDay : resourceAvailabilityInCurrentDay;

              if (taskResourcesEstimationMap[resource.resource_id] > resourceWorkHoursInCurrentDay) {
                isLastDay = false;
              }

              if (taskResourcesEstimationMap[resource.resource_id] >= resourceWorkHoursInCurrentDay) {
                taskResourcesEstimationMap[resource.resource_id] -= resourceWorkHoursInCurrentDay;
              } else {
                resourceWorkHoursInCurrentDay = taskResourcesEstimationMap[resource.resource_id];
                taskResourcesEstimationMap[resource.resource_id] = 0;
              }

              restTaskEstimation = Math.round((restTaskEstimation - resourceWorkHoursInCurrentDay) * 100) / 100;
              biggestTaskResourceEstimationRest = Math.max(biggestTaskResourceEstimationRest, resourceWorkHoursInCurrentDay);
            });

            resultDuration += isLastDay ? biggestTaskResourceEstimationRest : taskWorkHoursInCurrentDay;
            workDayCount++;

            // if (workDayCount > taskWorkDaysCount * 1000) {
            //   resultDuration = taskData.duration;
            //   webix.message({type: 'warning', text: __('resource_calendar_infinite_issue'), expire: 10000});
            //   restTaskEstimation = 0;
            //   this._resourceInfinityIssue = true;
            // }
          }
        }

        resultDuration = Math.round(resultDuration * 60);

        if (resultDuration === taskData.duration) {
          return false;
        }

        return resultDuration;
      },

      /**
       * return calculated resources collection in task assign after change estimation on task
       *
       * @param {object} taskData
       * @param {array} taskResources - prepared task resources(time type only!) with resource calendar, calendar custom days and workload custom days
       * @return {object}
       */
      calculateTaskResourcesAfterChangeEstimation(taskData, taskResources) {
        const calculatedTaskResources = [];
        const taskEstimationMode = this._getEstimationMode(taskData.gantt_id);
        const result = {};

        const newResourceEstimate = taskData.estimation / taskResources.length;

        let value = 0;

        if (newResourceEstimate !== 0) {
          value = Math.round(newResourceEstimate * 100) / 100;
        }

        const diff = taskData.estimation - value * taskResources.length;

        taskResources.forEach((resource, i) => {
          if (diff !== 0 && (taskResources.length - 1 === i)) {
            value += diff;
          }

          if (resource.value === value && !resource.custom_days.length) {
            return;
          }

          resource.value = value;

          calculatedTaskResources.push({
            user_id: resource.user_id,
            action: 'update',
            resource_id: resource.resource_id,
            value,
            customEstimation: [{
              resource_task_id: resource.id,
              action: 'delete',
            }],
          });
        });

        if (taskEstimationMode === this.MODES.fixEstimation) {
          const newDuration = this.calculateDuration(taskData, taskResources);

          if (newDuration && newDuration !== taskData.duration) {
            result.taskChanges = {
              duration: newDuration,
              end_date: moment(gantt.calculateEndDate({
                start_date: taskData.start_date,
                duration: newDuration,
                task: taskData,
              })).format('YYYY-MM-DD HH:mm:ss'),
            };
          }
        }

        if (taskEstimationMode === this.MODES.fixDuration) {

        }

        if (taskEstimationMode === this.MODES.fixEstimationAndDuration) {

        }

        result.taskResources = calculatedTaskResources;

        return result;
      },

      /**
       * return calculated resources collection in task assign after change estimation on task
       *
       * @param {object} taskData
       * @param {float number} oldDuration
       * @param {array} taskResources - prepared task resources(time type only!) with resource calendar, calendar custom days and workload custom days
       * @return {array} calculatedTaskResources
       */
      calculateTaskResourcesAfterChangeDuration(taskData, oldDuration, taskResources) {
        const taskEstimationMode = this._getEstimationMode(taskData.gantt_id);
        const calculatedTaskResources = [];
        const result = {};

        if (taskEstimationMode === this.MODES.fixEstimation || taskEstimationMode === this.MODES.fixEstimationAndDuration) {
          taskResources.forEach(resource => {
            if (!resource.custom_days.length) {
              return;
            }

            calculatedTaskResources.push({
              user_id: resource.user_id,
              action: 'update',
              resource_id: resource.resource_id,
              value: resource.value,
              customEstimation: [{
                resource_task_id: resource.id,
                action: 'delete',
              }],
            });
          });
        }

        if (taskEstimationMode === this.MODES.fixDuration) {
          let newEstimation = 0;

          taskResources.forEach(resource => {
            const newResourceValue = Math.round((resource.value * taskData.duration / oldDuration) * 100) / 100;

            newEstimation += newResourceValue;

            calculatedTaskResources.push({
              user_id: resource.user_id,
              action: 'update',
              resource_id: resource.resource_id,
              value: Math.round(newResourceValue * 100) / 100,
              customEstimation: [{
                resource_task_id: resource.id,
                action: 'delete',
              }],
            });
          });

          result.taskChanges = {
            estimation: newEstimation,
          };
        }

        result.taskResources = calculatedTaskResources;

        return result;
      },

      /**
       * returns collection of all tasks from single project with assign specific resource
       *
       * @param {object} taskData
       * @param {array} taskResources - prepared task resources(time type only!) with new values and resource calendar, calendar custom days
       * @return {object}
       */
      calculateTaskChangesAfterAssign(taskData, taskResources) {
        const taskEstimationMode = this._getEstimationMode(taskData.gantt_id);
        let newEstimation = 0;
        let newDuration = 0;
        let taskIsChanged = false;
        const changes = {};

        taskResources.forEach(resource => {
          newEstimation += +resource.value;
        });

        newEstimation = Math.round(newEstimation * 100) / 100;

        if (taskEstimationMode === this.MODES.fixEstimation) {
          newDuration = this.calculateDuration(taskData, taskResources);
        }

        if ((taskResources.length || taskEstimationMode === this.MODES.fixDuration) && newEstimation !== taskData.estimation) {
          changes.estimation = newEstimation;
          taskIsChanged = true;
        }

        if (newDuration && newDuration !== taskData.duration) {
          changes.duration = newDuration;
          changes.end_date = moment(
            gantt.calculateEndDate({
              start_date: taskData.start_date,
              duration: newDuration,
              task: taskData,
            }),
          ).format('YYYY-MM-DD HH:mm:ss');

          taskIsChanged = true;
        }

        // if (this._resourceInfinityIssue) {
        //   changes.unassign = true;
        //   this._resourceInfinityIssue = null;
        // }

        return taskIsChanged ? changes : null;
      },

      /**
       * return data for mass change assign
       *
       * @param {collection} tasks
       * @param {object} tasksTimeResourcesMap - prepared task resources(time type only!)
       * @return {array} resultData - in format for massChange model
       */
      calculateAfterChangeResourceOnProject(tasks, tasksTimeResourcesMap, changedResourceId, action) {
        const resultData = [];

        tasks.forEach(task => {
          const isFixDurationMode = this._getEstimationMode(task.gantt_id) === this.MODES.fixDuration;
          const taskChanges = {
            estimation: task.estimation,
          };
          const oldEstimation = task.estimation;
          const newEstimation = tasksTimeResourcesMap[task.id].reduce((sum, res) => {
            if (changedResourceId === res.resource_id && action === 'update' && res.isNotTimeType) {
              return sum;
            }
            sum += res.value;

            return sum;
          }, 0);
          const newResources = [];

          tasksTimeResourcesMap[task.id].forEach(res => {
            const isReset = changedResourceId === res.resource_id && action === 'update' && res.isNotTimeType;
            let newValue = res.value * task.estimation / newEstimation;

            if (isReset) {
              newValue = 0;
            }

            if (isFixDurationMode) {
              if (isReset) {
                newResources.push({
                  idResource: res.resource_id,
                  value: 0,
                });
              }
            } else if (newValue !== res.value) {
              newResources.push({
                idResource: res.resource_id,
                value: newValue,
              });
            }
          });

          if (isFixDurationMode) {
            taskChanges.estimation = newEstimation;
          }

          if (oldEstimation !== taskChanges.estimation || newResources.length) {
            resultData.push({
              idTask: task.id,
              resourceIdsToRm: action === 'remove' ? [changedResourceId] : [],
              resourcesToAdd: newResources,
              taskChanges,
              taskData: task,
            });
          }
        });

        return resultData.length ? [{
          actionType: 0,
          actionPayload: {
            tasksResources: resultData,
          },
        }] : false;
      },

      /**
       * return resource estimation value before applying assign to task
       *
       * @param {object} taskData
       * @param {number} countOfResources
       * @return {float number} resultEstimation
       */
      preCalculateEstimationForResource(taskData, countOfResources, isLast) {
        // const taskEstimationMode = this._getEstimationMode(taskData);
        let resultEstimation = 0;

        // if (taskEstimationMode === this.MODES.fixEstimation) {
        if (taskData.estimation) {
          resultEstimation = Math.round(taskData.estimation / countOfResources * 100) / 100;
        } else {
          resultEstimation = Math.round((taskData.duration / 60) * 100) / 100;
        }
        // }

        if (isLast && countOfResources * resultEstimation !== taskData.estimation && taskData.estimation) {
          resultEstimation += Math.round((taskData.estimation - countOfResources * resultEstimation) * 100) / 100;
        }

        return Math.round(resultEstimation * 100) / 100;
      },

      /**
       * return resource estimation workload data
       *
       * @param {object} taskData
       * @param {object} taskResourceData - time type only
       * @param {float number} inputValue
       * @param {float number} inputValuePerDay
       * @return {float number} result
       */
      calculateEstimationPerDay(taskData, taskResourceData, inputValue, inputValuePerDay) {
        let result = 0;
        const taskCalendar = gantt.getCalendar(taskData.gantt_id) || gantt.getCalendar('global');
        const workDaysCount = gantt.getWorkDaysBetweenDates(_.clone(taskData.start_date), _.clone(taskData.end_date), taskCalendar);
        const taskStartDay = gantt.date.day_start(_.clone(taskData.start_date));
        const defaultHours = gantt.getCalendar(taskData.gantt_id).getHoursPerDay();
        const resultEstimation = 0;
        let freeEstimation = 0;
        let customEstimation = 0;
        let freeDuration = (taskData.duration / 60).toFixed(2);

        if (!taskResourceData) {
          customEstimation = 0;
          freeEstimation = +inputValue;
        } else {
          freeEstimation = taskResourceData.value;

          taskResourceData.custom_days.forEach(customDay => {
            if (customDay.day_part > workDaysCount || _.isNull(customDay.estimation)) {
              return;
            }

            const currentDay = gantt.date.add(taskStartDay, customDay.day_part, 'day');

            if (gantt.isWorkDay(currentDay, taskCalendar)) {
              let taskWorkHoursInCurrentDay = 0;

              if (customDay.day_part === 1) {
                taskWorkHoursInCurrentDay = gantt.getWorkHoursBetweenDates(
                  _.clone(taskData.start_date),
                  workDaysCount === 1 ? _.clone(taskData.end_date) : null,
                  taskCalendar,
                );
              } else {
                taskWorkHoursInCurrentDay = gantt.getWorkHoursByDate(currentDay, taskCalendar);
              }

              freeEstimation -= customDay.estimation;
              freeDuration -= taskWorkHoursInCurrentDay;
            }
          });

          customEstimation = taskResourceData.value - freeEstimation;
        }

        if (inputValue) {
          result = Math.round((+inputValue - customEstimation) / freeDuration * defaultHours * 100) / 100;

          if (!result) {
            result = 0;
          }

          if (gantt.config.resource_loading_type === 'percents') {
            result = Math.round(result / defaultHours * 100);
          }

          if (result < 0 || result === 0 || result === Infinity) {
            result = '0';
          }
        }

        if (inputValuePerDay) {
          if (gantt.config.resource_loading_type === 'percents') {
            const calcVal = (((+inputValuePerDay / 100) * defaultHours) * freeDuration) / defaultHours;

            result = Math.round(calcVal * 100) / 100 + customEstimation;
          } else {
            const calcVal = (+inputValuePerDay * freeDuration) / defaultHours;

            result = Math.round(calcVal * 100) / 100 + customEstimation;
          }

          result += '';
        }

        return result;
      },
    };
  };
};

if (typeof module === 'object' && module.exports) {
  const _ = require('lodash');
  const moment = require('moment');

  module.exports = _initModule(_, moment);
} else {
  Gantt.plugin(_initModule(_, moment));
}
