const spriteUrls = [
  '/svg/sprites/16_color-bold_sprite.svg',
  '/svg/sprites/16_color-regular_sprite.svg',
  '/svg/sprites/16_bold_sprite.svg',
  '/svg/sprites/16_regular_sprite.svg',
  '/svg/sprites/20_color-bold_sprite.svg',
  '/svg/sprites/20_color-regular_sprite.svg',
  '/svg/sprites/20_bold_sprite.svg',
  '/svg/sprites/20_regular_sprite.svg',
  '/svg/sprites/24_bold_sprite.svg',
  '/svg/sprites/24_color-bold_sprite.svg',
  '/svg/sprites/24_color-regular_sprite.svg',
  '/svg/sprites/24_regular_sprite.svg',
  '/svg/sprites/72_regular_sprite.svg',
];

const wrap = document.createElement('div');

wrap.setAttribute('id', 'svg-sprite-wrap');

for (const url of spriteUrls) {
  fetch(url).then(async response => {
    if (!response.ok) {
      throw new TypeError('Bad response status');
    }

    const iconSvg = await response.text();

    wrap.innerHTML += iconSvg;
  });
}

document.body.appendChild(wrap);
