/* eslint-disable */
"use strict";

function _isExpandCollapseArea(node) {
  return node.classList.contains('gantt_tree_icon_item') || node.closest('gantt_tree_icon_item') ||
    (node.nodeName === 'svg' && node.parentNode.classList.contains("gantt_tree_icon_item")) ||
    (node.nodeName === 'use' && node.parentNode.nodeName === 'svg' && node.parentNode.parentNode.classList.contains('gantt_tree_icon_item'));
}
function _checkIsOverdue (task) {
  const result = !!(moment(task.end_date).isBefore() && task.progress < 1 && task.type === 'task');

  return result;
}

function wrapWithPTag(str) {
  const start = str.slice(0, 3);
  const end = str.slice(-4);
  return start === '<p>' && end === '</p>'
    ? str
    : `<p>${str}</p>`;
}

Gantt.plugin(function (gantt) {
  var COMMON_EMPTY = "notAssigned";

  gantt.config.inline_editor_free_height = 2;

  gantt._inline_edit = {
    cellContentClass: "gantt_tree_content",
    _currentTask: null,
    _controllers: [],
    _oldTask: null,

    /**
     * Inline controller class
     */
    _InlineController: function (config) {
      this.config = config;
      this.config.container = null;
    },

    _onTaskDblClick: function (id, e) {
      var task = gantt.getTask(id);
      if(gantt.config.multiview && task.$level === 1) {
        return false
      }
      gantt.saveTaskEditors(true);
      return true;
    },

    _onTaskClick: function (id, e) {
      var task = gantt.getTask(id);
      var visibleColumns = gantt.getGridColumns();
      var columns = gantt.config.columns;
      var cell = e.target || e.srcElement;
      var gridRow;// grid row dom-element
      var colIndex = 0;
      var i = 0;
      var isDefined = !!(cell.classList && cell.classList.contains);// for svg in IE11

      if (task.$dataprocessor_class && task.$dataprocessor_class.trim() === 'gantt_inserted' && task.type !== gantt.config.types.button) {
        return;
      }

      if (cell.closest('.mc-item-arrow')) {
        gantt.callEvent('onMassChangeShowSelectMenu', [id, e]);

        e.preventDefault();
        e.stopPropagation();

        return;
      }

      if (gantt._inline_edit._on_text_dnd) {
        return;
      }

      if (
        task.type !== gantt.config.types.button &&
        cell.closest('.gantt_data_area') &&
        (cell.classList.contains("gantt_task_row") || cell.classList.contains("gantt_task_cell"))) {
        gantt.selectAndShowTask(id);

        return;
      }

      if (isDefined && _isExpandCollapseArea(cell)) {
        gantt.saveTaskEditors(true);
        toggleTask(task, gantt);

        return true;
      }

      if (!gantt.callEvent("onBeforeTaskClick", [id])) {
        return true;
      }

      if (gantt.config.readonly || gantt.config.masschange) {
        if (isDefined && _isExpandCollapseArea(cell)
          // (
          //   cell.classList.contains("gantt_tree_icon_item") ||
          //   (cell.nodeName === 'svg' && cell.parentNode.classList.contains("gantt_tree_icon_item")) ||
          //   (cell.nodeName === 'path' && cell.parentNode.nodeName === 'svg' &&  cell.parentNode.parentNode.classList.contains("gantt_tree_icon_item"))
          // )
        ) {
          toggleTask(task, gantt);

          return true;
        }

        return true;
      }

      if (task.type === gantt.config.types.button) {
        if (cell.classList.contains('button') || cell.parentNode.classList.contains('button-icon') ) {
          gantt.config.creatingMilestoneFlag = cell.classList.contains('js_add_milestone');
          gantt.callEvent('setFocusToCreateTaskField', [gantt.getGlobalTaskIndex(task.id)]);
          gantt.saveTaskEditors(true);
        }
        return;
      }

      if (isDefined && cell.classList.contains('gantt_tree_icon')) {
        return true;
      }

      if (cell.closest('.select_control') || cell.closest('.inline_editor') || cell.closest('.gantt_notification_tooltip')) {
        return true;
      }

      if (isDefined && cell.closest('.notification_cell_comments')) {
        gantt.callEvent("onTaskCommentsClick", [id]);
        userExtAnalytics.log('gantt_grid_fast_button_click', {type: 'comments'});
        userExtAnalytics.log('task_grid_action', { action: 'comments_open' });
        return true;
      }

      if (isDefined && cell.closest('.notification_cell_attachments')) {
        gantt.callEvent("onTaskAttachmentsClick", [id]);
        userExtAnalytics.log('gantt_grid_fast_button_click', {type: 'attachments'});
        userExtAnalytics.log('task_grid_action', { action: 'attachments_open' });
        return true;
      }

      if (isDefined && cell.closest('.gantt_grid_fast_button_delete')) {
        gantt.unloadTaskEditors();//for unfreeze collaboration
        gantt.callEvent("onTaskDeleteClick", [id]);
        e.stopPropagation();
        userExtAnalytics.log('gantt_grid_fast_button_click', {type: 'delete'});
        userExtAnalytics.log('task_grid_action', { action: 'delete' });
        return true;
      }

      if (isDefined && cell.closest('.gantt_grid_fast_button_info')) {
        userExtAnalytics.log('gantt_grid_fast_button_click', {type: 'info'});
        return true;
      }

      if (isDefined && (cell.closest('.gantt_grid_fast_button_edit')
      || (cell.closest('div[data-name="text"]') && !cell.closest('div[data-name="text"] .text_value_content')))) {
        gantt.unloadTaskEditors(); //for unfreeze collaboration
        gantt.callEvent("onTaskEditClick", [id]);
        e.stopPropagation();
        userExtAnalytics.log('gantt_grid_fast_button_click', {type: 'task settings'});
        return true;
      }
      /*cell = cell.closest(".gantt_cell") || cell;

      if (isDefined && cell.classList.contains('gantt_row')) {
        gridRow = cell;
      } else {
        gridRow = cell.parentNode;
      }*/

      // If we clicked not on the gantt grid the return
      if (isDefined && cell.classList.contains("gantt_button_grid")) {
        return true;
      }

      if (task.$rendered_type == "button") {
        gantt.saveTaskEditors(true);
        return true;
      }

      if (cell.closest('.actions_panel')) {
        gantt.saveTaskEditors(true);
        return true;
      }

      // if (isDefined && _isExpandCollapseArea(cell)) {
      //   gantt.saveTaskEditors(true);
      //   toggleTask(task, gantt);

      //   return true;
      // }

      if (cell.classList && !cell.classList.contains('gantt_cell') && cell.closest('.gantt_cell')) {
        cell = cell.closest('.gantt_cell');
      }

      if (isDefined && cell.classList.contains('gantt_row')) {
        gridRow = cell;
      } else {
        gridRow = cell.closest('.gantt_row');
      }

      var cellAttributeDataName = cell.getAttribute('data-name');

      //Calculate columns position
      if (gridRow) {
        _.each(gridRow.childNodes, function (item, index) {
          var isDefine = item.getAttribute && !_.isUndefined(item.getAttribute('data-name'));

          if (isDefine && item.getAttribute('data-name') === cellAttributeDataName) {
            colIndex = index;
          }
        });

        gantt.callEvent("onBeforeTaskRowClick", [task, colIndex, ~~id === gantt._inline_edit._currentTask]);

        if (!gridRow.parentNode.classList.contains('gantt_grid_data') && ~~id !== gantt._inline_edit._currentTask) {
          gantt.saveTaskEditors(true);
          return true;
        }
      }

      if (gantt.config.updateWithoutChangeFocus && +gantt._inline_edit._currentTask !== +id) {
        gantt.saveTaskEditors(true);
        return true;
      }

      if (gantt.checkEvent("onBeforeInlineEdit")) {
        var propertyName = gantt._inline_edit.hasEditor(colIndex, task) ? columns[colIndex].name : null;

        if (!gantt.callEvent("onBeforeInlineEdit", [id, task, propertyName, e])) {
          gantt.saveTaskEditors(true);
          return true;
        }
      }

      if (!gantt.config.updateWithoutChangeFocus) {
        gantt.showTaskEditors(id, colIndex);
      }

      function toggleTask(task, gantt) {
        if (!GT.appMode.isBase) {
          if (!task.$open) {
            gantt.open(task.id);
            task.$open = 1;
            task.open = 1;
          } else {
            gantt.close(task.id);
            task.$open = 0;
            task.open = 0;
          }

          gantt.callEvent("silentUpdate", [task.id, task]);

          return true;
        }

        gantt.blockEvents();

        if (!task.$open) {
          task.$open = 1;
          task.open = 1;
        } else {
          task.$open = 0;
          task.open = 0;
        }

        gantt.unblockEvents();
        gantt.updateTask(task.id);
        gantt.callEvent('onTaskExpandClick', [task, task.$open]);
      }

      return false;
    },

    _onEmptyClick: function (e) {
      var target = e.target || e.srcElement;
      var targetClassName = target.className;
      var targetClassList = target.classList;
      var targetParent = target.parentNode;

      if (gantt._inline_edit._on_text_dnd) {
        return;
      }

      if (
        (typeof targetClassName === "string" && !targetClassList.contains('add_task_button') &&
          (targetParent && !targetParent.classList.contains('add_task_button')) && !targetClassList.contains('gantt_grid_head_add')) ||
        typeof targetClassName === "object"
      ) {
        gantt.saveTaskEditors(true);
      }
    },

    _onDataRender: function () {
      var ganttInlineEdit = gantt._inline_edit;
      var controllers = ganttInlineEdit._controllers;
      var ganttCurrenTask = ganttInlineEdit._currentTask;
      var task = {};
      var i = 0;

      if (ganttCurrenTask && !ganttInlineEdit._isRendering) {
        if (gantt.isTaskExists(ganttCurrenTask) && gantt.isTaskVisible(ganttCurrenTask)) {
          task = gantt.getTask(ganttCurrenTask);

          if (task && (!task.$dataprocessor_class || task.$dataprocessor_class === '')) {
            ganttInlineEdit._isRendering = true;

            _.each(controllers, function (item) {
              item.unload();
              item.render();
              item.setValue(task[item.config.colName]);
              item.focus();
            });

            ganttInlineEdit._isRendering = false;
          }
        }
      }
    },

    _onBeforeLightBox: function (id) {
      _.delay(function () {
        gantt.saveTaskEditors(true);
      });

      return true;
    },

    _onTaskClosed: function (taskId) {
      if (gantt._inline_edit._isEditorsOpened()) {
        gantt.saveTaskEditors(true);
      }
    },

    _onAddedNewAttachment: function (gantt_id, task_id, countAdded, countTotal, hasUnreadComments) {
      var taskItem = gantt.isTaskExists(task_id) && gantt.getTask(task_id);

      if (taskItem) {
        taskItem.hasAttachments = countTotal;

        // if (hasUnreadComments) {
        //   taskItem.newComments = true;
        // }

        gantt.callEvent('ganttRender');
      }
    },

    _onRemovedAttachment: function (gantt_id, task_id, countRemoved, countTotal) {
      var taskItem = gantt.isTaskExists(task_id) && gantt.getTask(task_id);

      if (taskItem) {
        taskItem.hasAttachments = countTotal;
        gantt.callEvent('ganttRender');
      }
    },

    _onAddedNewComment: function (data, is_read) {
      if (_.isEmpty(data)) {
        return;
      }

      _.each(data, function (comment) {
        var taskId = comment.task_id;
        var ganttId = comment.gantt_id;
        var taskItem = gantt.isTaskExists(taskId) && gantt.getTask(taskId);

        if (taskItem) {
          if (!is_read) {
            taskItem.newComments = true;
          }

          taskItem.hasComments = true;
        }
      });

      var specialPasteOptions = document.querySelector('.special-paste-options:not(.hidden)');

      if (specialPasteOptions && specialPasteOptions.parentNode.parentNode.parentNode) {
        var taskWithSP = gantt.getTask(specialPasteOptions.parentNode.parentNode.parentNode.getAttribute('task_id'));
        taskWithSP.specialPaste = true;
      }

      gantt.callEvent("ganttRender");
    },

    _onReadNewComment: function (taskId) {
      var taskItem = gantt.isTaskExists(taskId) && gantt.getTask(taskId);

      if (!taskItem) {
        return;
      }

      taskItem.newComments = false;
            gantt.callEvent("ganttRender");
    },

    _onBeforeTaskDrag: function () {
      gantt.saveTaskEditors(true);
      return true;
    },

    _onRowDragStart: function (id, target, e) {
      var ganttInlineEdit = gantt._inline_edit;
      var ganttCurrenTask = ganttInlineEdit._currentTask;

      if (ganttCurrenTask && ganttCurrenTask == parseInt(id, 10) && ganttInlineEdit._isEditorElement(target)) {
        _.delay(function () {
          gantt.callEvent("manualResetDragDrop");
        });

        return false;
      }

      if (ganttInlineEdit._isEditorsOpened()) {
        gantt.saveTaskEditors(true);
        return false;
      }

      return true;
    },

    _onBeforeTaskDelete: function (id) {
      let task = gantt.isTaskExists(id) && gantt.getTask(id);
      let isButton = task && task.type === gantt.config.types.button;

      if (isButton) {
        return true;
      }

      gantt.saveTaskEditors(true);
      return true;
    },

    _createControl: function (taskId, colIndex, showColIndex, type, isForceOpening, noSelect, columnData) {
      const taskItem = gantt.isTaskExists(taskId) && gantt.getTask(taskId);

      var config = {
        taskId: taskId,
        colIndex: showColIndex,
        showColIndex: colIndex,
        colName: columnData.name,
        control: this.editors[type],
        isCustomColumn: columnData.isCustomColumn || false,
        isUserCustomColumn: columnData.isUserCustomColumn,
        columnType: columnData.columnType,
        data_type: columnData.data_type,
        isForceOpening: isForceOpening,
        noSelect: noSelect,
        task: taskItem,
      };

      var controller = new this._InlineController(config);

      this._controllers.push(controller);

      if (controller.hasRights()) {
        controller.render();
      }

      if (isForceOpening && controller.hasRights()) {
        setTimeout(function () {
          controller.focus();
          gantt.callEvent('openedInlineEditor', [taskId, showColIndex]);
        }, 0);
      }

      return controller;
    },

    _isEditorElement: function (element) {
      _.each(gantt._inline_edit._controllers, function (item) {
        if (item.config.container.contains(element)) {
          return true;
        }
      });

      return false;
    },

    _isEditorsOpened: function () {
      var ganttInlineEdit = gantt._inline_edit;

      return ganttInlineEdit._currentTask && ganttInlineEdit._controllers.length !== 0;
    },

    /**
     * Controls available
     */
    editors: {
      text: {
        render: function (container, id) {
          var height = (parseInt(container.style.height, 10) - 2);

          // if (gantt.config.baseline === 1) {
          //   container.innerHTML = '<input maxlength="250" class="inline_editor_task_name mousetrap" style="height: ' + height + 'px;line-height: ' + height + 'px" >';
          // }  else {
            container.innerHTML = '<input maxlength="250" class="inline_editor_task_name mousetrap" style="height: ' + 100 + '%;line-height: ' + 100 + '%" >';
          //}
          this.input = container.childNodes[0];
        },

        setValue: function (container, value) {
          this._settingValue = true;
          container.childNodes[0].value = value;
        },

        getValue: function (container) {
          return container.childNodes[0].value;
        },

        focus: function (id, target, noSelect) {
          var that = this;
          setTimeout(function () {
            if (that.input) {
              !noSelect && that.input.select();
              noSelect && that.input.focus();
            }
          }, 100);
        },

        unload: function (container, id) {
          clearInterval(this.changeTimer);
        },

        initEventListeners: function (controller) {
          var self = this;
          var container = controller.config.container;
          var pressedMouse = false;

          self.timerstart = new Date();

          gantt.event(container.childNodes[0], "mousedown", function (e) {
            e.stopPropagation();
          });
          gantt.event(container.childNodes[0], "mousemove", function (e) {
            e.stopPropagation();
          });
          gantt.event(container.childNodes[0], "dblclick", function (e) {
            e.stopPropagation();
          });
          container.childNodes[0].onmousedown = function (e) {
            pressedMouse = true;
          };

          container.childNodes[0].onmousemove = function (e) {
            gantt._inline_edit._on_text_dnd = pressedMouse;
          };

          container.childNodes[0].onmouseup = function (e) {
            pressedMouse = false;
            gantt._inline_edit._on_text_dnd = false;
          };
        }
      },
      //Webix datepicker component
      datepicker: {
        render: function (container, id) {
          let componentHeight = parseInt(container.style.height, 10);
          const componentWidth = parseInt(container.style.width, 10);

          if(gantt.config.baseline === 1) {
            componentHeight = componentHeight / 2;
          }

          container.innerHTML = "<div id='" + id + "'></div>";

          $$(id).config.view === "datepicker" && $$(id).destructor();

          webix.ui({
            view: 'datepickeredit',
            id: id,
            container: id,
            timepicker: true,
            height: componentHeight,
            width: componentWidth,
            css: 'text_value',
            projectCalendar: null,
            getProjectCalendar: function () {
              let taskId;
              if (gantt._inline_edit._controllers && gantt._inline_edit._controllers[0] && gantt._inline_edit._controllers[0].config.taskId) {
                taskId = gantt._inline_edit._controllers[0].config.taskId;
              }

              return gantt.getCalendar(gantt.getTask(taskId).gantt_id);
            },
            on: {
              "onBeforeRender": function () {
                let calendar = $$(id).getPopup().getBody();

                calendar.define("blockDates", function (date) {
                  if (_.includes(id, 'end_date')) {
                    if (new Date(date).valueOf() < gantt.date.day_start(_.clone(gantt._inline_edit._currentTaskData.start_date)).valueOf()) {
                      return true;
                    }
                  }

                  if (gantt.isWorkDay(date, this.config.projectCalendar)) {
                    return false;
                  }

                  return true;
                });
                calendar.define("blockTime", function (date) {
                  if (_.includes(id, 'end_date')) {
                    if (gantt.isWorkEndTime(date, this.projectCalendar)) {
                      return false;
                    }
                  }

                  if (_.includes(id, 'start_date')) {
                    if (gantt.isWorkStartTime(date, this.projectCalendar)) {
                      return false;
                    }
                  }

                  return true;
                });

                if (gantt.isWorkDay(new Date(), this.config.getProjectCalendar())) {
                  calendar.define("icons", [{
                    template: function () {
                      if (_.includes(id, 'end_date') && gantt.date.day_start(new Date(_.clone(gantt._inline_edit._currentTaskData.start_date))).valueOf() > new Date().valueOf()) {
                        return "";
                      }
                      return "<span class='webix_cal_icon_today webix_cal_icon_today_" + (_.includes(id, 'end_date') ? "end_date" : "start_date") + " webix_cal_icon'>" + webix.i18n.calendar.today + "</span>";
                    },
                    on_click: {
                      "webix_cal_icon_today_start_date": function () {
                        this.setValue(gantt.getClosestWorkTime({
                          date: gantt.date.day_start(new Date()),
                          dir: "future"
                        }));

                        this.callEvent("onTodaySet", [this.getSelectedDate()]);
                      },
                      "webix_cal_icon_today_end_date": function () {
                        this.setValue(gantt.getClosestWorkTime({
                          date: gantt.date.day_start(new Date(new Date().getTime() + 24 * 60 * 60 * 1000)),
                          dir: "past"
                        }));

                        this.callEvent("onTodaySet", [this.getSelectedDate()]);
                      }
                    }
                  }]);
                } else {
                  calendar.define("icons", false);
                }

                calendar.define("minuteStep", 1);
                calendar.refresh();
              }
            }
          });

          const dateIcon = container.querySelector('.webix_input_icon');
          componentWidth < 170 ? dateIcon.style.opacity = 0 : dateIcon.style.opacity = 1;
        },

        setValue: function (container, value, id) {
          const datepicker = $$(id)
          const popup = $$(id).getPopup();
          const leftPos = container.getBoundingClientRect().x - 9;

          datepicker.getInputNode().classList.add('mousetrap')

          datepicker.blockEvent('onChange');
          datepicker.setValue(value);
          datepicker.unblockEvent('onChange');

          popup.attachEvent("onShow", function () {
            popup.$view.style.left = leftPos + "px";

            popup.getNode().classList.add('custom_datepicker');
            let docHeight = document.body.clientHeight;
            let popupHeight = popup.$height;

            let topPosContainer = container.getBoundingClientRectWrapper().y;
            let domSizesTop = docHeight - topPosContainer;
            if (domSizesTop > (popupHeight + 30)) {
              popup.$view.style.top = topPosContainer + container.offsetHeight + 1 + "px";
              popup.getNode().classList.remove('suggest_popup_top');
              popup.getNode().classList.add('suggest_popup_bottom');
            } else {
              popup.$view.style.top = topPosContainer - popupHeight - 2 + "px";
              popup.getNode().classList.remove('suggest_popup_bottom');
              popup.getNode().classList.add('suggest_popup_top');
            }
          });
        },

        getValue: function (container, id) {
          return $$(id) && $$(id).getValue();
        },

        focus: function (id) {
          const datepicker = $$(id);

          if (datepicker) {
            let taskId = null;
            let $$suggest = $$(id).getPopup().getBody();
            if (gantt._inline_edit._controllers && gantt._inline_edit._controllers[0] && gantt._inline_edit._controllers[0].config.taskId) {
              taskId = gantt._inline_edit._controllers[0].config.taskId;
            }

            $$suggest.config.projectCalendar = null;

            if (taskId) {
              $$suggest.config.projectCalendar = gantt.getCalendar(gantt.getTask(taskId).gantt_id);
            }

            datepicker.getInputNode().click();
            datepicker.focus();
          }
        },

        unload: function (container, id) {
          var view = $$(id);

          if (view) {
            view.getPopup().hide();
            view.destructor();
          }
        },

        initEventListeners: function (controller) {
          const el = $$(controller.config.id);
          const container = controller.config.container;

          el.attachEvent("onChange", function (newValue, oldValue) {
            controller.change(newValue, oldValue);
            _.delay(() => {
              gantt.saveTaskEditors(true);
            });
          });

          gantt.event(container.childNodes[0], "dblclick", function (e) {
            el.getInputNode().select();
            e.stopPropagation();
          });

          gantt.event(container.childNodes[0], "mousedown", function (e) {
            e.stopPropagation();
          });

          gantt.event(container.childNodes[0], "mousemove", function (e) {
            e.stopPropagation();
          });
        }
      },
      custom_datepicker: {
        render: function (container, id) {
          let componentHeight = parseInt(container.style.height, 10);
          const componentWidth = parseInt(container.style.width, 10);

          if(gantt.config.baseline === 1) {
            componentHeight = componentHeight / 2;
          }

          container.innerHTML = "<div id='" + id + "'></div>";

          $$(id).config.view === "datepicker" && $$(id).destructor();

          webix.ui({
            view: 'datepickeredit',
            id: id,
            container: id,
            height: componentHeight,
            width: componentWidth,
            emptyAllowed: true,
          });

          const dateIcon = container.querySelector('.webix_input_icon');
          componentWidth < 170 ? dateIcon.style.opacity = 0 : dateIcon.style.opacity = 1;
        },

        setValue: function (container, value, id) {
          const datepicker = $$(id);
          const popup = datepicker.getPopup();
          const leftPos = container.getBoundingClientRect().x - 9;

          datepicker.blockEvent('onChange');
          datepicker.setValue(value);
          datepicker.unblockEvent('onChange');

          popup.attachEvent("onShow", function () {
            popup.$view.style.left = leftPos + "px";

            popup.getNode().classList.add('custom_datepicker');

            if(popup.getValue()) {
              popup.getNode().classList.add('custom_datepicker_clear');
            }

            let docHeight = document.body.clientHeight;
            let popupHeight = popup.$height;

            let topPosContainer = container.getBoundingClientRectWrapper().y;
            let domSizesTop = docHeight - topPosContainer;

            if (domSizesTop > (popupHeight + 30)) {
              popup.$view.style.top = topPosContainer + container.offsetHeight + 1 + "px";
              popup.getNode().classList.remove('suggest_popup_top');
              popup.getNode().classList.add('suggest_popup_bottom');
            } else {
              popup.$view.style.top = topPosContainer - popupHeight - 2 + "px";
              popup.getNode().classList.remove('suggest_popup_bottom');
              popup.getNode().classList.add('suggest_popup_top');
            }
          });
        },

        getValue: function (container, id) {
          return $$(id) && $$(id).getValue();
        },

        focus: function (id) {
          const datepicker = $$(id);

          if (datepicker) {
            let taskId = null;
            let $$suggest = $$(id).getPopup().getBody();

            if (gantt._inline_edit._controllers && gantt._inline_edit._controllers[0] && gantt._inline_edit._controllers[0].config.taskId) {
              taskId = gantt._inline_edit._controllers[0].config.taskId;
            }

            $$suggest.config.projectCalendar = null;

            if (taskId) {
              $$suggest.config.projectCalendar = gantt.getCalendar(gantt.getTask(taskId).gantt_id);
            }

            datepicker.getInputNode().click();
            datepicker.focus();
          }
        },

        unload: function (container, id) {
          var view = $$(id);

          if (view) {
            view.getPopup().hide();
            view.destructor();
          }
        },

        initEventListeners: function (controller) {
          const el = $$(controller.config.id);
          const container = controller.config.container;

          el.attachEvent("onChange", function (newValue, oldValue, isInvalid) {
            const isEmpty = newValue === '';
            const isValid = newValue instanceof Date && !isNaN(newValue);
            isInvalid = (!isValid && !isEmpty) || isInvalid;

            _.delay(() => {
              gantt.saveTaskEditors(true, isInvalid);
            });

            if (isInvalid) {
              webix.message({ type: "warning", text: __("gantt_date_invalid") });
              return false;
            } else {
              controller.change(newValue, oldValue);
            }
          });

          gantt.event(container.childNodes[0], "dblclick", function (e) {
            el.getInputNode().select();
            e.stopPropagation();
          });

          gantt.event(container.childNodes[0], "mousedown", function (e) {
            e.stopPropagation();
          });

          gantt.event(container.childNodes[0], "mousemove", function (e) {
            e.stopPropagation();
          });
        }
      },
      //webix counter component
      counter: {
        render: function (container, id) {
          var counterBoxInput;
          var counterBoxButtonPrev;
          var counterBoxButtonNext;
          var componentHeight = parseInt(container.style.height, 10);
          var min = id.indexOf('progress') != -1 ? 0 : 1;
          var max = id.indexOf('progress') != -1 ? 100 : 3000;

          container.innerHTML = "<div id='" + id + "'></div>";

          webix.ui({
            view: 'counter',
            id: id,
            container: id,
            min: min,
            max: max,
            step: 1,
            height: componentHeight
          });

          counterBoxInput = container.querySelector('.webix_el_group');
          counterBoxButtonPrev = container.getElementsByTagName('button')[0];
          counterBoxButtonNext = container.getElementsByTagName('button')[1];

          if (gantt.config.baseline === 1) {
            counterBoxInput.style.height = (componentHeight - 2) / 2 + "px";
            counterBoxButtonPrev.classList.add('baseline_active');
            counterBoxButtonNext.classList.add('baseline_active');
          }
        },

        setValue: function (container, value, id) {
          this._settingValue = true;

          if (id.indexOf('progress') !== -1) {
            value *= 100;
            value = _.floor(Math.round(value * 100) / 100);
          } else if (!value) {
            value = 1;
          }

          $$(id) && $$(id).setValue(value);
        },

        getValue: function (container, id) {
          return $$(id) && $$(id).getValue();
        },

        focus: function (id) {
          var that = this;
          $$(id) && $$(id).focus();
          setTimeout(function () {
            $$(id) && $$(id).$view.childNodes[0].childNodes[0].childNodes[1].select();
          }, 60);
        },

        unload: function (container, id) {
          if ($$(id)) {
            $$(id).destructor();
          }
        },

        initEventListeners: function (controller) {
          var self = this;
          var el = $$(controller.config.id);
          var container = controller.config.container;
          var input = el.$view.childNodes[0].childNodes[0].childNodes[1];

          input.classList.add('mousetrap');

          el.attachEvent("onEnter", function (e) {
            e.preventDefault();
          });
          el.attachEvent("onChange", function (newValue, oldValue) {

            if (!self._settingValue) {
              newValue = Math.round(newValue * 100) / 100;
              el.config.value = newValue;
              input.value = newValue;
              //el.refresh();
              controller.change(newValue, oldValue);
            } else {
              self._settingValue = false;
            }
          });

          gantt.event(container.childNodes[0], "mousedown", function (e) {
            e.stopPropagation();
          });
          gantt.event(container.childNodes[0], "mousemove", function (e) {
            e.stopPropagation();
          });
          gantt.event(container.childNodes[0], "dblclick", function (e) {
            e.stopPropagation();
          });

          gantt.event(input, "keydown", function (e) {
            if (_.includes([37, 38, 39, 40], e.keyCode)) {
              e.stopPropagation();
              gantt.callEvent('counterKeyNavigation', [e]);
            }
          });
        }
      },
      estimation_counter: {
        render: function (container, id) {
          var counterBoxInput;
          var counterBoxButtonPrev;
          var counterBoxButtonNext;
          var componentHeight = parseInt(container.style.height, 10);
          container.innerHTML = "<div id='" + id + "'></div>";
          webix.ui({
            view: 'counter',
            id: id,
            container: id,
            min: 0,
            step: 1,
            height: componentHeight - 3
          });

          counterBoxInput = container.querySelector('.webix_el_group');
          counterBoxButtonPrev = container.getElementsByTagName('button')[0];
          counterBoxButtonNext = container.getElementsByTagName('button')[1];

          if (gantt.config.baseline === 1) {
            counterBoxInput.style.height = (componentHeight - 2) / 2 + "px";
            counterBoxButtonPrev.classList.add('baseline_active');
            counterBoxButtonNext.classList.add('baseline_active');
          }
        },

        setValue: function (container, value, id) {
          this._settingValue = true;
          if (!value && value !== null) {
            value = 0;
          }
          $$(id) && $$(id).setValue(value);
        },

        getValue: function (container, id) {
          return $$(id) && $$(id).getValue();
        },

        focus: function (id) {
          var that = this;
          $$(id) && $$(id).focus();
          setTimeout(function () {
            $$(id) && $$(id).$view.childNodes[0].childNodes[0].childNodes[1].select()
          }, 60);
        },

        unload: function (container, id) {
          var master = $$(id);
          if (master) {
            master.destructor();
          }
        },

        initEventListeners: function (controller) {
          var self = this;
          var el = $$(controller.config.id);
          var container = controller.config.container;
          var input = el.$view.childNodes[0].childNodes[0].childNodes[1];

          input.classList.add('mousetrap');

          el.attachEvent("onChange", function (newValue, oldValue) {
            if (!self._settingValue) {
              newValue = Math.round(newValue * 100) / 100;
              el.config.value = newValue;
              input.value = newValue;
              //el.refresh();
              controller.change(newValue, oldValue);
            } else {
              self._settingValue = false;
            }
          });

          gantt.event(container.childNodes[0], "mousedown", function (e) {
            e.stopPropagation();
          });
          gantt.event(container.childNodes[0], "mousemove", function (e) {
            e.stopPropagation();
          });
          gantt.event(container.childNodes[0], "dblclick", function (e) {
            e.stopPropagation();
          });

          gantt.event(input, "keydown", function (e) {
            if (_.includes([37, 38, 39, 40], e.keyCode)) {
              e.stopPropagation();
              gantt.callEvent('counterKeyNavigation', [e]);
            }
          });
        }
      },
      custom_counter: {
        render: function (container, id) {
          var counterBoxInput;
          var counterBoxButtonPrev;
          var counterBoxButtonNext;
          var componentHeight = parseInt(container.style.height, 10);
          container.innerHTML = "<div id='" + id + "'></div>";

          webix.ui({
            view: 'counter',
            id: id,
            container: id,
            step: 1,
            min: 0,
            height: componentHeight
          });

          counterBoxInput = container.querySelector('.webix_el_group');
          counterBoxButtonPrev = container.getElementsByTagName('button')[0];
          counterBoxButtonNext = container.getElementsByTagName('button')[1];

          if (gantt.config.baseline === 1) {
            counterBoxInput.style.height = (componentHeight - 2) / 2 + "px";
            counterBoxButtonPrev.classList.add('baseline_active');
            counterBoxButtonNext.classList.add('baseline_active');
          }
        },

        setValue: function (container, value, id) {
          this._settingValue = true;
          $$(id) && $$(id).setValue(value);
        },

        getValue: function (container, id) {
          return $$(id) && $$(id).getValue();
        },

        focus: function (id) {
          $$(id) && $$(id).focus();
          setTimeout(function () {
            $$(id) && $$(id).$view.childNodes[0].childNodes[0].childNodes[1].select();
          }, 60);
        },

        unload: function (container, id) {
          if ($$(id)) {
            $$(id).destructor();
          }
        },

        initEventListeners: function (controller) {
          var self = this;
          var el = $$(controller.config.id);
          var container = controller.config.container;
          var input = el.$view.childNodes[0].childNodes[0].childNodes[1];

          input.classList.add('mousetrap');

          el.attachEvent("onEnter", function (e) {
            e.preventDefault();
          });

          el.attachEvent("onChange", function (newValue, oldValue) {
            if (!self._settingValue) {
              newValue = Math.round(newValue * 100) / 100;
              el.config.value = newValue;
              input.value = newValue;
              controller.change(newValue, oldValue);
            } else {
              self._settingValue = false;
            }
          });

          gantt.event(container.childNodes[0], "mousedown", function (e) {
            e.stopPropagation();
          });
          gantt.event(container.childNodes[0], "mousemove", function (e) {
            e.stopPropagation();
          });
          gantt.event(container.childNodes[0], "dblclick", function (e) {
            e.stopPropagation();
          });

          gantt.event(input, "keydown", function (e) {
            if (_.includes([37, 38, 39, 40], e.keyCode)) {
              e.stopPropagation();
              gantt.callEvent('counterKeyNavigation', [e]);
            }
          });
        }
      },
      //webix select component
      searchselect: {
        render: function (container, id, colIndex) {
          let options = [];
          const column = gantt.config.columns[colIndex];
          let componentHeight = parseInt(container.style.height, 10);
          const ganttContainer = document.querySelector('.dhx_gantt_container');

          if (column.control_options) {
            options = column.control_options;
          }

          if(gantt.config.baseline === 1) {
            componentHeight = componentHeight / 2;
          }

          container.innerHTML = `<div id='${id}'></div>`;

          webix.ui({
            view: 'richselect',
            borderless: true,
            id: id,
            container: id,
            min: 1,
            height: componentHeight,
            inputHeight: componentHeight,
            suggest: {
              view: "suggestsearch",
              css: "filter-multicombo-suggest",
              resetButton: true,
              body: {
                ...options.body,
                data: options.data
              }
            },
            popupWidth: 186,
            css: "select_control"
          });

          if (gantt.config.baseline === 1 && ganttContainer.classList.contains('compact')) {
            container.classList.add('mode_compact');
          }

          if(gantt.config.baseline === 1) {
            $$(id).getNode().children[0].style.height = componentHeight/2 + 'px';
          }
        },

        setValue: function (container, value, id) {
          const combo = $$(id);
          const popup = combo.getPopup();

          if (gantt.config.baseline === 0) {
            combo.$view.childNodes[0].childNodes[0].style.lineHeight = gantt.config.row_height - gantt.config.inline_editor_free_height - 3 + 'px';
          } else {
            combo.$view.childNodes[0].childNodes[0].style.lineHeight = gantt.config.row_height / 2 - gantt.config.inline_editor_free_height - 4 + 'px';
          }
          if (!popup.$view.classList.contains('inline_select_popup')) {
            popup.$view.className += ' inline_select_popup';
            popup.$view.className += ' task_view';
          }

          combo.blockEvent('onChange');
          combo.setValue(value);
          combo.unblockEvent('onChange');
        },

        getValue: function (container, id) {
          return $$(id) && $$(id).getValue();
        },

        focus: function (id) {
          $$(id) && $$(id).getInputNode().click();
        },

        unload: function (container, id) {
          var master = $$(id);

          if (master) {
            master.getPopup().hide();
            master.destructor();
          }
        },

        initEventListeners: function (controller) {
          var el = $$(controller.config.id);
          var container = controller.config.container;

          el.attachEvent("onChange", function (newValue, oldValue, e) {
            _.delay(_.bind(function () {
              controller.change(newValue, oldValue);
              setTimeout(function () {
                gantt.saveTaskEditors(true);
              }, 0);
            }, this));
          });

          gantt.event(container.childNodes[0], "mousedown", function (e) {
            e.stopPropagation();
          });
          gantt.event(container.childNodes[0], "mousemove", function (e) {
            e.stopPropagation();
          });
          gantt.event(container.childNodes[0], "dblclick", function (e) {
            e.stopPropagation();
          });
        },

        _getNameById: function (element, valueId) {
          valueId = !valueId ? 0 : valueId;

          var list = element.getPopup().getList().config.data;
          var result = "";

          list.some(function (item, itemIndex) {
            if (item.value === valueId) {
              index = itemIndex;
              return true;
            }
          });

          return result;
        }
      },
      searchmultiselect: {
        render: function (container, id, colIndex) {
          let options = [];
          const column = gantt.config.columns[colIndex];
          let componentHeight = parseInt(container.style.height, 10);
          const ganttContainer = document.querySelector('.dhx_gantt_container');

          if (column.control_options) {
            options = column.control_options;
          }

          if(gantt.config.baseline === 1) {
            componentHeight = componentHeight / 2;
          }

          container.innerHTML = `<div id='${id}'></div>`;

          webix.ui({
            view: 'multiselect',
            borderless: true,
            id: id,
            container: id,
            min: 1,
            height: componentHeight,
            inputHeight: componentHeight,
            suggest: {
              view: "multisuggestsearch",
              css: "filter-multicombo-suggest",
              body: {
                ...options.body,
                data: options.data
              }
            },
            popupWidth: 224
          });

          if (gantt.config.baseline === 1 && ganttContainer.classList.contains('compact')) {
            container.classList.add('mode_compact');
          }

          gantt.callEvent("beforeShowCustomColumnSuggestList");
        },

        setValue: function (container, value, id) {
          const combo = $$(id);

          if (!combo) {
            return;
          }

          const popup = combo.getPopup();

          if (gantt.config.baseline === 0) {
            combo.$view.childNodes[0].childNodes[0].style.lineHeight = gantt.config.row_height - gantt.config.inline_editor_free_height - 3 + 'px';
          } else {
            combo.$view.childNodes[0].childNodes[0].style.lineHeight = gantt.config.row_height / 2 - gantt.config.inline_editor_free_height - 4 + 'px';
          }
          if (!popup.$view.classList.contains('inline_select_popup')) {
            popup.$view.className += ' inline_select_popup';
            popup.$view.className += ' task_view';
          }

          combo.blockEvent('onChange');
          combo.setValue(value);
          combo.unblockEvent('onChange');
        },

        getValue: function (container, id) {
          return $$(id) && $$(id).getValue();
        },

        focus: function (id) {
          $$(id) && $$(id).getInputNode().click();
        },

        unload: function (container, id) {
          var master = $$(id);

          if (master) {
            master.getPopup().hide();
            master.destructor();
          }
        },

        initEventListeners: function (controller) {
          const container = controller.config.container;

          gantt.event(container.childNodes[0], "mousedown", function (e) {
            e.stopPropagation();
          });
          gantt.event(container.childNodes[0], "mousemove", function (e) {
            e.stopPropagation();
          });
          gantt.event(container.childNodes[0], "dblclick", function (e) {
            e.stopPropagation();
          });
        },

        _getNameById: function (element, valueId) {
          valueId = !valueId ? 0 : valueId;

          var list = element.getPopup().getList().config.data;
          var result = "";

          list.some(function (item, itemIndex) {
            if (item.value === valueId) {
              index = itemIndex;
              return true;
            }
          });

          return result;
        }
      },
      select: {
        render: function (container, id, colIndex) {
          let options = [];
          const column = gantt.config.columns[colIndex];
          let componentHeight = parseInt(container.style.height, 10);
          const ganttContainer = document.querySelector('.dhx_gantt_container');

          if(gantt.config.baseline === 1) {
            componentHeight = componentHeight / 2;
          }

          if (column.control_options) {
            options = column.control_options;
          }

          container.innerHTML = "<div id='" + id + "'></div>";

          webix.ui({
            view: 'richselect',
            borderless: true,
            id: id,
            container: id,
            min: 1,
            height: componentHeight,
            inputHeight: componentHeight,
            options: options,
            popupWidth: 150,
            css: "select_control"
          });

          if (gantt.config.baseline === 1 && ganttContainer.classList.contains('compact')) {
            container.classList.add('mode_compact');
          }
          if (gantt.config.baseline === 1 && ganttContainer.classList.contains('touch')) {
            container.classList.add('mode_touch');
          }
          if (gantt.config.baseline === 1) {
            if (container.parentNode.getAttribute('data-name') === 'status' || 'priority') {
              container.classList.add('baseline_active')
            } else {
              if (container.classList.contains('baseline_active')) {
                container.classList.remove('baseline_active')
              }
            }
          }

          if (container.parentNode.getAttribute("data-type") !== "") {
            if (container.parentNode.getAttribute("data-type") === "custom_select") {
              container.style.left = parseInt(container.style.left, 10) - 1 + "px";
            }
          }

          if (container.parentNode.getAttribute('data-name') === 'status' || 'priority') {
            if (container.parentNode.offsetWidth <= 90) {
              container.classList.add('un_arrow')
            } else {
              container.classList.remove('un_arrow')
            }
          }
        },

        setValue: function (container, value, id) {
          var combo = $$(id);
          var popup = combo.getPopup();
          if (gantt.config.baseline === 0) {
            combo.$view.childNodes[0].childNodes[0].style.lineHeight = gantt.config.row_height - gantt.config.inline_editor_free_height - 3 + 'px';
          } else {
            combo.$view.childNodes[0].childNodes[0].style.lineHeight = gantt.config.row_height / 2 - gantt.config.inline_editor_free_height - 4 + 'px';
          }
          if (!popup.$view.classList.contains('inline_select_popup')) {
            popup.$view.className += ' inline_select_popup';
            popup.$view.className += ' task_view';
          }

          combo.blockEvent('onChange');
          combo.setValue(value);
          combo.unblockEvent('onChange');

          popup.attachEvent("onShow", function () {
            popup.config.width = 186;
            popup.config.padding = 0;
            popup.resize();

            popup.getNode().classList.add('custom_select');

            let docHeight = document.body.clientHeight;
            let popupHeight = popup.$height;

            let topPos = container.getBoundingClientRectWrapper().y;
            let leftPos = parseInt(popup.$view.style.left, 10) - 9 + "px";
            let domSizesTop = docHeight - topPos;

            if (domSizesTop > (popupHeight * 2)) {
              popup.$view.style.top = topPos + container.offsetHeight + 1 + "px";
              popup.getNode().classList.remove('suggest_popup_top');
              popup.getNode().classList.add('suggest_popup_bottom');
            } else {
              popup.$view.style.top = topPos - popupHeight - 4 + "px";
              popup.getNode().classList.remove('suggest_popup_bottom');
              popup.getNode().classList.add('suggest_popup_top');
            }

            if (container.parentNode.getAttribute('data-type') === 'custom_select') {
              const skin = gantt.skin;
              let pixelsNum = 13;
              if(skin !== "1") {
                pixelsNum = 11;
              }
              popup.$view.style.left = parseInt(popup.$view.style.left, 10) - pixelsNum + "px";
            } else {
              popup.$view.style.left = leftPos;
            }

            return popup.config;
          });
        },

        getValue: function (container, id) {
          return $$(id) && +$$(id).getValue();
        },

        focus: function (id) {
          $$(id) && $$(id).getInputNode().click();
        },

        unload: function (container, id) {
          var master = $$(id);

          if (master) {
            master.getPopup().hide();
            master.destructor();
          }
        },

        initEventListeners: function (controller) {
          var self = this;
          var el = $$(controller.config.id);

          var container = controller.config.container;

          el.attachEvent("onChange", function (newValue, oldValue, e) {
            _.delay(_.bind(function () {
              controller.change(newValue, oldValue);
              setTimeout(function () {
                gantt.saveTaskEditors(true);
              }, 0);
            }, this));
          });

          gantt.event(container.childNodes[0], "mousedown", function (e) {
            e.stopPropagation();
          });
          gantt.event(container.childNodes[0], "mousemove", function (e) {
            e.stopPropagation();
          });
          gantt.event(container.childNodes[0], "dblclick", function (e) {
            e.stopPropagation();
          });
        },

        _getNameById: function (element, valueId) {
          valueId = !valueId ? 0 : valueId;

          var list = element.getPopup().getList().config.data;
          var result = "";

          list.some(function (item, itemIndex) {
            if (item.value === valueId) {
              index = itemIndex;
              return true;
            }
          });

          return result;
        }
      },
      tags: {
        render: function (container, id, colIndex) {
          let options = [];
          const column = gantt.config.columns[colIndex];
          let componentHeight = parseInt(container.style.height, 10);
          const ganttContainer = document.querySelector('.dhx_gantt_container');

          if (column.control_options) {
            options = column.control_options;
          }

          if(gantt.config.baseline === 1) {
            componentHeight = componentHeight / 2;
          }

          container.innerHTML = `<div id='${id}' height='100%'></div>`;

          webix.ui({
            view: 'multicombogrid',
            borderless: true,
            id: id,
            container: id,
            min: 1,
            height: componentHeight,
            inputHeight: componentHeight,
            css: 'filter-multicombo-hide-input',
            suggest: {
              view: "multisuggestsearch",
              css: "filter-multicombo-suggest",
              body: {
                ...options.body,
                data: options.data
              }
            },
            tagMode: false,
            tagTemplate: function (selectedIds) {
              const selectedItemsIds =  selectedIds.toString().split(',');
              const allElems = $$(this.id).getList().serialize(true);
              const selectedElems = allElems.filter((elem) => {
                return selectedItemsIds.includes(`${elem.id}`)
              });

              let resultTag = '';
              const maxElemsToShow = 2;

              if (selectedElems.length && selectedElems.length <= maxElemsToShow) {
                resultTag = selectedElems.map(elem => {
                  const tag = `<span class="tag-text">${elem.value}</span>`;
                  return `<span class="tag-item" style="background: ${elem.color}">${tag}</span>`;
                }).join('');
              }

              if (selectedElems.length && selectedElems.length > maxElemsToShow) {
                const tags = selectedElems.slice(0, maxElemsToShow).map(elem => {
                  const tag = `<span class="tag-text">${elem.value[0]}</span>`;
                  return `<span class="tag-item" style="width: 18px; background: ${elem.color}">${tag}</span>`;
                }).join('');

                resultTag = `${tags}${selectedElems.length > maxElemsToShow ? `<span>+${selectedElems.length - maxElemsToShow}</span>` : ''}`;
              }

              return resultTag;
            },
            popupWidth: 224
          });

          if (gantt.config.baseline === 1 && ganttContainer.classList.contains('compact')) {
            container.classList.add('mode_compact');
          }

          gantt.callEvent("beforeShowCustomColumnSuggestList");
        },

        setValue: function (container, value, id) {
          const combo = $$(id);

          if (!combo) {
            return;
          }

          const popup = combo.getPopup();

          if (gantt.config.baseline === 0) {
            combo.$view.childNodes[0].childNodes[0].style.lineHeight = gantt.config.row_height - gantt.config.inline_editor_free_height - 3 + 'px';
          } else {
            combo.$view.childNodes[0].childNodes[0].style.lineHeight = gantt.config.row_height / 2 - gantt.config.inline_editor_free_height - 4 + 'px';
          }
          if (!popup.$view.classList.contains('inline_select_popup')) {
            popup.$view.className += ' inline_select_popup';
            popup.$view.className += ' task_view';
          }

          combo.blockEvent('onChange');
          combo.setValue(value);
          combo.unblockEvent('onChange');
        },

        getValue: function (container, id) {
          return $$(id) && $$(id).getValue();
        },

        focus: function (id) {
          $$(id) && $$(id).getInputNode().click();
        },

        unload: function (container, id) {
          var master = $$(id);

          if (master) {
            master.getPopup().hide();
            master.destructor();
          }
        },

        initEventListeners: function (controller) {
          const container = controller.config.container;

          gantt.event(container.childNodes[0], "mousedown", function (e) {
            e.stopPropagation();
          });
          gantt.event(container.childNodes[0], "mousemove", function (e) {
            e.stopPropagation();
          });
          gantt.event(container.childNodes[0], "dblclick", function (e) {
            e.stopPropagation();
          });
        },

        _getNameById: function (element, valueId) {
          valueId = !valueId ? 0 : valueId;

          var list = element.getPopup().getList().config.data;
          var result = "";

          list.some(function (item, itemIndex) {
            if (item.value === valueId) {
              index = itemIndex;
              return true;
            }
          });

          return result;
        }
      },
      resources: {
        render: function (container, id, colIndex) {
          let options = [];
          let items = [];
          const column = gantt.config.columns[colIndex];
          let componentHeight = parseInt(container.style.height, 10);
          const ganttContainer = document.querySelector('.dhx_gantt_container');

          if (column.control_options) {
            options = column.control_options;
            items = options.data;
          }

          if (gantt.config.multiview) {
            const taskCell = container.closest('.gantt_row_editing');
            const taskId = +taskCell.getAttribute('task_id');
            const task = gantt.getTask(taskId);

            items = options.multiviewData[task.gantt_id];
          }

          if(gantt.config.baseline === 1) {
            componentHeight = componentHeight / 2;
          }

          container.innerHTML = `<div id='${id}'></div>`;

          webix.ui({
            view: 'multicombogrid',
            borderless: true,
            id: id,
            container: id,
            min: 1,
            height: componentHeight,
            inputHeight: componentHeight,
            css: 'filter-multicombo-hide-input',
            suggest: {
              view: "separatormultisuggestsearch",
              css: "filter-multicombo-suggest",
              body: {
                ...options.body,
                data: items.filter(option => {
                  return option.hasOwnProperty('name');
                })
              }
            },
            tagMode: false,
            tagTemplate: function (selectedIds) {
              const selectedItemsIds =  selectedIds.toString().split(',');
              const allElems = $$(this.id).getList().serialize(true);
              const selectedElems = allElems.filter((elem) => {
                return selectedItemsIds.includes(`${elem.id}`)
              });

              let resultTag = '';
              const maxElemsToShow = 2;

              if (selectedElems.length && selectedElems.length < maxElemsToShow) {
                const picture = `<span class="resources-icon" style="background-image: url(${selectedElems[0].picture})"></span>`;
                const text = `<span class="resources-text">${selectedElems[0].value}</span>`;
                resultTag = `<span class="resources-item">${picture}${text}</span>`;
              }

              if (selectedElems.length && selectedElems.length >= maxElemsToShow) {
                const maxElemsToShow = 2;
                const iconTags = selectedElems.slice(0, maxElemsToShow).map(elem => {
                  const picture = elem.picture ? `<span class="resources-icon" style="background-image: url(${elem.picture})"></span>` : '';
                  return `<span class="resources-item">${picture}</span>`;
                }).join("");

                resultTag = `${iconTags}${selectedElems.length > maxElemsToShow ? `+${selectedElems.length - maxElemsToShow}` : ''}`;
              }

              return resultTag;
            },
            popupWidth: 224
          });

          if (gantt.config.baseline === 1 && ganttContainer.classList.contains('compact')) {
            container.classList.add('mode_compact');
          }

          gantt.callEvent("beforeShowCustomColumnSuggestList");
        },

        setValue: function (container, value, id) {
          const combo = $$(id);

          if (!combo) {
            return;
          }

          const popup = combo.getPopup();

          if (gantt.config.baseline === 0) {
            combo.$view.childNodes[0].childNodes[0].style.lineHeight = gantt.config.row_height - gantt.config.inline_editor_free_height - 3 + 'px';
          } else {
            combo.$view.childNodes[0].childNodes[0].style.lineHeight = gantt.config.row_height / 2 - gantt.config.inline_editor_free_height - 4 + 'px';
          }
          if (!popup.$view.classList.contains('inline_select_popup')) {
            popup.$view.className += ' inline_select_popup';
            popup.$view.className += ' task_view';
          }

          combo.blockEvent('onChange');
          combo.setValue(value);
          combo.unblockEvent('onChange');
        },

        getValue: function (container, id) {
          return $$(id) && $$(id).getValue();
        },

        focus: function (id) {
          $$(id) && $$(id).getInputNode().click();
        },

        unload: function (container, id) {
          var master = $$(id);

          if (master) {
            master.getPopup().hide();
            master.destructor();
          }
        },

        initEventListeners: function (controller) {
          const container = controller.config.container;

          gantt.event(container.childNodes[0], "mousedown", function (e) {
            e.stopPropagation();
          });
          gantt.event(container.childNodes[0], "mousemove", function (e) {
            e.stopPropagation();
          });
          gantt.event(container.childNodes[0], "dblclick", function (e) {
            e.stopPropagation();
          });
        },

        _getNameById: function (element, valueId) {
          valueId = !valueId ? 0 : valueId;

          var list = element.getPopup().getList().config.data;
          var result = "";

          list.some(function (item, itemIndex) {
            if (item.value === valueId) {
              index = itemIndex;
              return true;
            }
          });

          return result;
        }
      },
      multiselect: {
        render: function (container, id, colIndex) {

        },

        setValue: function (container, value, id) {

        },

        getValue: function (container, id) {

        },

        focus: function (id, container) {
          const pos = container.getBoundingClientRectWrapper();
          const resPos = {x: pos.x, y: pos.y - 9, bottom: pos.bottom + 1, height: pos.height};
          gantt.callEvent('showResourcesListForTask', [resPos, gantt._inline_edit._currentTask, null, false, 'gridView']);
        },

        unload: function (container, id) {
          gantt.callEvent('hideResourcesListForTask');
        },

        initEventListeners: function (controller) {
          var container = controller.config.container;

          gantt.event(container, "click", function (e) {
            const pos = container.getBoundingClientRectWrapper();
            e.stopPropagation();
            const resPos = {x: pos.x, y: pos.y - 9, bottom: pos.bottom + 1, height: pos.height};
            gantt.callEvent('showResourcesListForTask', [resPos, gantt._inline_edit._currentTask, null, false, 'gridView']);
          });

          gantt.event(container, "mousedown", function (e) {
            e.stopPropagation();
          });
          gantt.event(container, "mousemove", function (e) {
            e.stopPropagation();
          });
          gantt.event(container, "dblclick", function (e) {
            e.stopPropagation();
          });
        }
      },
      time_tracking: {
        render: function (container, id, colIndex) {
        },
        setValue: function (container, value, id) {
        },
        getValue: function (container, id) {
        },
        focus: function (id, container) {
          var pos = container.getBoundingClientRectWrapper();
          setTimeout(function () {
            const resPos = {x: pos.x, y: pos.y, bottom: pos.bottom};
            gantt.callEvent('showTimeTrackingPopup', ['task', resPos, gantt._inline_edit._currentTask]);
          }, 10);
        },
        unload: function (container, id) {
          //gantt.callEvent('hideTimeTrackingPopup');
        },
        initEventListeners: function (controller) {
          var container = controller.config.container;

          gantt.event(container, "click", function (e) {
            var pos = container.getBoundingClientRectWrapper();
            e.stopPropagation();
            const resPos = {x: pos.x, y: pos.y, bottom: pos.bottom};
            gantt.callEvent('showTimeTrackingPopup', ['task', resPos, gantt._inline_edit._currentTask]);
          });

          gantt.event(container, "mousedown", function (e) {
            e.stopPropagation();
          });
          gantt.event(container, "mousemove", function (e) {
            e.stopPropagation();
          });
          gantt.event(container, "dblclick", function (e) {
            e.stopPropagation();
          });
        }
      },
      select_color: {
        render: function (container, id, colIndex) {
          let options = [];
          const column = gantt.config.columns[colIndex];
          let componentHeight = parseInt(container.style.height, 10);

          if(gantt.config.baseline === 1) {
            componentHeight = componentHeight / 2;
          }

          if (column.control_options) {
            options = column.control_options;
          }

          container.innerHTML = "<div id='" + id + "'></div>";

          const delta = 30;
          let width = 120;
          let height = delta;

          _.each(options.data, (opt, i) => {

            if (i % 6 === 0 && i !== 0) {
              height += delta;
            }

            if (i > 3 && i < 6) {
              width += delta;
            }
          });

          webix.ui({
            view: 'richselect',
            borderless: true,
            id: id,
            container: id,
            min: 1,
            height: componentHeight,
            inputHeight: componentHeight,
            popupWidth: width,
            popupHeight: height,
            padding: 20,
            css: 'select_color',
            options: {
              view: "suggestcolor",
              resetButton: true,
              body: {
                ...options.body,
                data: options
              }
            },
          });


          if (container.parentNode.getAttribute('data-type') === 'custom_color') {
            if (container.parentNode.offsetWidth <= 90) {
              container.classList.add('un_arrow')
            } else {
              container.classList.remove('un_arrow')
            }
          }
        },

        setValue: function (container, value, id) {
          const combo = $$(id);
          const popup = combo.getPopup();
          const ganttContainer = document.querySelector('.dhx_gantt_container');

          if (gantt.config.baseline === 0) {
            combo.$view.childNodes[0].childNodes[0].style.lineHeight = gantt.config.row_height - gantt.config.inline_editor_free_height - 4 + 'px';
          } else {
            combo.$view.childNodes[0].childNodes[0].style.lineHeight = gantt.config.row_height / 2 - gantt.config.inline_editor_free_height - 4 + 'px';
          }
          if (ganttContainer.classList.contains('compact')) {
            combo.$view.childNodes[0].childNodes[0].style.lineHeight = gantt.config.row_height - gantt.config.inline_editor_free_height + 'px';
          }

          combo.blockEvent('onChange');
          combo.setValue(value);
          combo.unblockEvent('onChange');

          popup.attachEvent("onShow", function () {
            popup.getNode().classList.add('select_color');

            let docHeight = document.body.clientHeight;
            let popupHeight = popup.$height;

            let topPos = container.getBoundingClientRectWrapper().y;
            let leftPos = parseInt(popup.$view.style.left, 10) - 9 + "px";
            let domSizesTop = docHeight - topPos;

            if (domSizesTop > (popupHeight + 50)) {
              popup.$view.style.top = topPos + container.offsetHeight + 1 + "px";
              popup.getNode().classList.remove('suggest_popup_top');
              popup.getNode().classList.add('suggest_popup_bottom');
            } else {
              popup.$view.style.top = topPos - popupHeight - 4 + "px";
              popup.getNode().classList.remove('suggest_popup_bottom');
              popup.getNode().classList.add('suggest_popup_top');
            }

            popup.$view.style.left = leftPos;
          });
        },

        getValue: function (container, id) {
          return $$(id) && $$(id).getValue();
        },

        focus: function (id) {
          $$(id) && $$(id).getInputNode().click();
        },

        unload: function (container, id) {
          var master = $$(id);

          if (master) {
            master.getPopup().hide();
            master.destructor();
          }
        },

        initEventListeners: function (controller) {
          const el = $$(controller.config.id);
          const container = controller.config.container;

          el.attachEvent("onChange", function (newValue, oldValue, e) {
            _.delay(_.bind(function () {
              controller.change(newValue, oldValue);
              setTimeout(function () {
                gantt.saveTaskEditors(true);
              }, 0);
            }, this));
          });

          gantt.event(container.childNodes[0], "mousedown", function (e) {
            e.stopPropagation();
          });
          gantt.event(container.childNodes[0], "mousemove", function (e) {
            e.stopPropagation();
          });
          gantt.event(container.childNodes[0], "dblclick", function (e) {
            e.stopPropagation();
          });
        }
      },
      checkbox: {
        //
      },
      predecessor: {
        render: function (container, id) {
          let componentHeight = parseInt(container.style.height, 10);

          if(gantt.config.baseline === 1) {
            componentHeight = componentHeight / 2;
          }

          container.innerHTML = `<div id='${id}'></div>`;

          webix.ui({
            view: 'text',
            id: id,
            container: id,
            height: componentHeight,
            inputHeight: componentHeight,
            on: {
              onKeyPress: function (code) {
                if (code === 13) {
                  this.blur();
                  return;
                }
                if (code === 40 || code === 38) {
                  gantt.callEvent("suggestSearchPopupFocus")
                  return;
                }
                const prevValue = this.getValue();
                _.delay((prevValue) => {
                  const newValue = this.getValue();
                  const pos = this.getNode().parentNode.getBoundingClientRectWrapper();
                  const inputNode = this.getInputNode();
                  const selectionPos = inputNode.selectionStart;
                  const selectedTask = gantt._inline_edit._currentTaskData;

                  if (newValue !== prevValue) {
                    gantt.callEvent("showLinksSuggestOnSearch", [pos, selectedTask, newValue, selectionPos, this])
                  }
                }, 0, prevValue);
              }
            }
          });

          $$(id).getInputNode().style.lineHeight = `${componentHeight}px`;
        },

        setValue: function (container, value, id) {
          const input = $$(id);

          input.blockEvent('onChange');
          input.setValue(value);
          input.unblockEvent('onChange');
        },

        getValue: function (container, id) {
          return $$(id) && $$(id).getValue();
        },

        focus: function (id, target, noSelect) {
          $$(id).focus();
        },

        unload: function (container, id) {
          const view = $$(id);
          if (view) {
            view.destructor();
          }
          gantt.callEvent('hideLinksSuggestOnSearch');
        },

        initEventListeners: function (controller) {
          const container = controller.config.container;
          const input = $$(controller.config.id).getInputNode();
          input.classList.add('mousetrap');

          gantt.event(container.childNodes[0], "mousedown", function (e) {
            e.stopPropagation();
          });
          gantt.event(container.childNodes[0], "mousemove", function (e) {
            e.stopPropagation();
          });
          gantt.event(container.childNodes[0], "dblclick", function (e) {
            e.stopPropagation();
          });
        }
      }
    }
  };

  /**
   * Inline controller methods
   */
  gantt._inline_edit._InlineController.prototype.hasRights = function () {
    const fieldName = this.config.data_type ? this.config.data_type : this.config.colName;

    return gantt.hasAccessToTask(this.config.task) && gantt.hasRightToEditField(this.config.task?.gantt_id, fieldName);
  };
  gantt._inline_edit._InlineController.prototype.render = function () {
    var taskRow = gantt.getTaskRowNode(gantt._inline_edit._currentTask);
    var cell = {};
    var container = undefined;
    var size;

    let isTaskNameEdition;
    if (taskRow && taskRow.childNodes.length) {
      cell = taskRow.childNodes[this.config.colIndex];
      container = cell.querySelector(".inline_editor");
      if (cell.dataset.name === 'text') isTaskNameEdition = true;
    }

    if (!container && cell.innerHTML) {
      if (this.config.container && this.config.container.childNodes.length > 0) {
        this.config.control.unload(this.config.container, this.config.id);
      }

      container = this.config.container = document.createElement("DIV");
      container.className = "inline_editor";

      size = this._getControllerRect(cell);

      container.style.left = `${size.left}px`;
      container.style.height = isTaskNameEdition ? '20px' : `${size.height}px`;
      container.style.width = isTaskNameEdition ? `${size.width - 15}px` : `${size.width}px`;
      cell.style.position = "relative";

      cell.classList.add('gantt_focused_with_editor');
      cell.appendChild(container);

      this.config.id = this.config.colName + '' + this.config.colIndex;
      this.config.control.render(container, this.config.id, this.config.showColIndex);

      if (isTaskNameEdition) {
        container.classList.add('task_name_edit');
        if (cell.clientWidth <= 49) {
          container.classList.add('task_name_edit__without_margin');
        }
        const button = document.createElement("div");
        button.addEventListener('click', ()=> gantt.saveTaskEditors(true));
        button.innerHTML = chartIconsBG.submit;
        button.className = 'submit_button';
        container.appendChild(button);
      }

      this.config.control.initEventListeners(this);
    }
  };

  gantt._inline_edit._InlineController.prototype.change = function (newValue, oldValue) {
    if (this.config) {
      gantt.callEvent("onInlineEdit", [gantt._inline_edit._currentTask, this.config.colName, newValue, oldValue, this.config.isCustomColumn]);
    }
  };

  gantt._inline_edit._InlineController.prototype.setValue = function (value) {
    if (_.includes(['duration', 'estimation', 'trackedTime'], this.config.colName)) {

      const projectCalendar = gantt.getCalendar(this.config.task.gantt_id);
      let durationData = gantt.config.durationData;

      if (projectCalendar) {
        durationData = {
          showDay: Object.keys(projectCalendar.getConfig().dates).filter(key => +projectCalendar.getConfig().dates[key]),
          showTime: _.clone(projectCalendar.getConfig().hours),
        };
      }

      value = gantt.timeParser.output(value, {
        durationData,
        durationStep: gantt.config.duration_view,
        prop: this.config.colName
      });
    }

    this.config.control.setValue(this.config.container, value, this.config.id);
  };

  gantt._inline_edit._InlineController.prototype.saveValue = function () {
    var value = this.config.control.getValue(this.config.container, this.config.id);
    var task = gantt._inline_edit._currentTaskData;
    var currentTask = gantt.getTask(gantt._inline_edit._currentTask) || {};
    var validate = gantt.config.columns[_.findIndex(gantt.config.columns, {name: this.config.colName})].inline_validate;

    if (!task) {
      return false;
    }

    if (this.config.isUserCustomColumn) {
      const type = this.config.data_type.split('_')[1];
      let oldValue = gantt.getUserCustomValue(task, this.config.colName).value;

      if (!validate(value, this.config.columnType)) {
        return false;
      }

      if (_.isDate(oldValue) && _.isDate(value)) {
        if (value.getTime() !== oldValue.getTime()) {
          gantt.callEvent('onChangeUserCustomValue', [task, this.config.colName, value]);

          userExtAnalytics.log('task_grid_action', { action: 'custom_field', type });
        }

        return false;
      }

      if (!(!value && !oldValue) && (value !== oldValue)) {
        gantt.callEvent('onChangeUserCustomValue', [task, this.config.colName, value]);

        userExtAnalytics.log('task_grid_action', { action: 'custom_field', type });
      }

      return false;
    }

    if (this.config.isCustomColumn) {
      if (task[this.config.colName] !== value) {
        currentTask[this.config.colName] = value;
        task[this.config.colName] = value;
        gantt._inline_edit._changedCustomColumns.push(this.config.colName);

        if (this.config.colName === 'status') {
          if (!gantt.config.isJira) {
            task.progress = gantt.config.bindStatusAndProgress.calc(value, null).progress;
          }

          userExtAnalytics.log('task_grid_action', { action: 'status' });

          return true;
        }

        if (this.config.colName === 'priority') {
          userExtAnalytics.log('task_grid_action', { action: 'priority' });

          return true;
        }

      }

      return false;
    }

    switch (this.config.colName) {
      case "text": {

        if (!validate(value)) {
          return false;
        }

        let textNode = document.querySelector('[task_id="' + gantt._inline_edit._currentTask + '"] .inline_editor_task_name_container_text');

        if (textNode) {
          textNode.innerText = value;

          userExtAnalytics.log('task_grid_action', { action: 'name' });
        }

        break;
      }
      case "start_date": {
        const datePicker = $$(this.config.id);
        const calendar = gantt.getCalendar(currentTask.gantt_id);
        const isEmpty = value === '';
        const isValid = value instanceof Date && !isNaN(value);

        if (isEmpty) {
          webix.message({ type: "warning", text: __("gantt_date_invalid") });
          return false;
        }

        if (!isValid) {
          webix.message({ type: "warning", text: __("gantt_date_invalid") });
          return false;
        }

        value = datePicker.config.dateLimitFormater(value, false);

        if (!gantt.isWorkDay(value, calendar) || !gantt.isWorkTime(value, 'minute', calendar)) {
          value = gantt.getClosestWorkTime({date: value, dir: "future", unit: gantt.config.duration_unit, task: currentTask});
        }

        if (gantt.config.auto_scheduling) { //спрашиваем у автошедулинга, можем ли мы сдвинуть начало таски
          var predecessors = gantt._getPredecessors(task);

          _.each(predecessors, function (pred) {
            if (pred.preferredEnd && new Date(value).valueOf() < pred.preferredEnd.valueOf()) {
              value = gantt.getClosestWorkTime({date: pred.preferredEnd, dir: "future"});
            }
          });
        }

        if (new Date(task[this.config.colName]).valueOf() !== new Date(value).valueOf()) {
          currentTask[this.config.colName] = gantt.date.minute_start(value);
          task.start_date = gantt.date.minute_start(value);
          task.end_date = gantt.calculateEndDate({
            start_date: task.start_date,
            duration: task.duration,
            task,
            unit: gantt.config.duration_unit
          });

          userExtAnalytics.log('task_grid_action', { action: 'start_date' });

          return true;
        }

        return false;
      }
      case "end_date": {
        const datePicker = $$(this.config.id);
        const isEmpty = value === "";
        const isValid = value instanceof Date && !isNaN(value);

        if (isEmpty) {
          webix.message({ type: "warning", text: __("gantt_date_invalid") });
          return false;
        }

        if (!isValid) {
          webix.message({ type: "warning", text: __("gantt_date_invalid") });
          return false;
        }

        value = datePicker.config.dateLimitFormater(value, false);

        if (new Date(task[this.config.colName]).valueOf() !== new Date(value).valueOf()) {
          if (value.valueOf() <= task.start_date.valueOf()) {
            return false;
          } else if (!value.getHours()) {
            value.setHours(task.end_date.getHours());
          }

          currentTask[this.config.colName] = gantt.date.minute_start(value);
          task.end_date = gantt.date.minute_start(value);
          // task.duration = gantt.calculateDuration(task.start_date, task.end_date);
          task.duration = gantt.calculateDuration({
            start_date: task.start_date,
            end_date: task.end_date,
            unit: gantt.config.duration_unit,
            task: task
          });

          userExtAnalytics.log('task_grid_action', { action: 'end_date' });

          return true;
        }

        return false;
      }
      case "duration": {
        let parsedValue = validate(value);

        if (!parsedValue) {
          return false;
        }

        value = parsedValue.value;

        value = +value || 1;

        let duration = gantt.calculateDuration({
          start_date: task.start_date,
          end_date: task.end_date,
          unit: gantt.config.duration_unit,
          task: task
        });

        if (duration !== value) {
          task.end_date = gantt.calculateEndDate({start_date: task.start_date, duration: value, task, unit: gantt.config.duration_unit});
          task.duration = value;

          userExtAnalytics.log('task_grid_action', { action: 'duration' });

          return true;
        }

        return false;
      }
      case "estimation": {
        let parsedValue = validate(value);

        if (!parsedValue) {
          return false;
        }

        value = parsedValue.value;

        if (task.estimation === null || task.estimation === undefined) {
          currentTask[this.config.colName] = null;
          task[this.config.colName] = null;
        }
        if (task.estimation !== value && !isNaN(value)) {
          currentTask[this.config.colName] = value;
          task.estimation = value;

          userExtAnalytics.log('task_grid_action', { action: 'estimation' });

          return true;
        }
        return false;
      }
      case "progress": {
        if (!validate(+value)) {
          return false;
        }

        value /= 100;
        value = Math.round(value * 100) / 100;

        if (task[this.config.colName] !== value) {
          task[this.config.colName] = value;

          userExtAnalytics.log('task_grid_action', { action: 'progress' });

          return true;
        }

        return false;
      }
      case "predecessors": {
        return true;
      }
      case "resource_id":
      case "time_tracking":
        return false;
    }

    const hasDiff = task[this.config.colName] !== value;

    if (hasDiff) {
      if (validate && !validate(value)) {
        return false;
      }

      value = ((typeof value === "string") ? value.trim() : value);

      currentTask[this.config.colName] = value;
      task[this.config.colName] = value;

      return true;
    }

    return false;
  };

  gantt._inline_edit._InlineController.prototype.focus = function () {
    this.config.control.focus(this.config.id, this.config.container, this.config.noSelect);
  };

  gantt._inline_edit._InlineController.prototype.unload = function () {
    if (this.config.container) {
      this.config.control.unload(this.config.container, this.config.id);

      if (this.config.container.parentNode) {
        this.config.container.parentNode.removeChild(this.config.container);
      }
    }
  };

  gantt._inline_edit._InlineController.prototype._getControllerRect = function (node) {
    const children = node.childNodes;
    const nodeStyle = window.getComputedStyle(node, null);
    const paddingRight = parseInt(nodeStyle.paddingRight, 10);
    const paddingLeft = parseInt(nodeStyle.paddingLeft, 10);
    let usedWidth = 0;

    _.each(children, function (item) {
      if (item.classList && !item.classList.contains(gantt._inline_edit.cellContentClass)) {
        usedWidth += item.offsetWidth;
      }
    });

    return {
      width: usedWidth ? node.scrollWidth - usedWidth - paddingRight - 2 : node.scrollWidth - paddingRight - paddingLeft ,
      height: node.offsetHeight,
      left: usedWidth ? usedWidth + paddingLeft : 0,
    };
  };

  gantt._inline_edit.checkByLinkDependency = function (taskData, columnName) {
    if (columnName === 'create_date' || columnName === 'creator' || taskData.type === gantt.config.types.button) {
      return true;
    }

    if (!(columnName === 'start_date' || columnName === 'end_date') || !gantt.config.auto_scheduling) {
      return false;
    }

    if (columnName === 'start_date') {
      const children = gantt.getChildren(taskData.parent).filter(childID => gantt.getTask(childID).type !== gantt.config.types.button);
      var predecessors = gantt._getPredecessors(taskData, children.length > 1);

      if (predecessors.length === 1) {
        return true;
      }
    }

    var mastersData = gantt.getMasterLinksByTaskId(taskData.id);
    var shouldDisable = false;
    var alreadyBlockedByStart = false;

    mastersData.forEach(function (masterData) {
      switch (masterData.linkType) {
        case gantt.config.links.finish_to_start:
        case gantt.config.links.start_to_start:
          if (columnName === 'start_date') {
            shouldDisable = true;
          }
          if (columnName === 'end_date') {
            alreadyBlockedByStart = true;
            shouldDisable = false;
          }
          break;
        case gantt.config.links.finish_to_finish:
        case gantt.config.links.start_to_finish:
          if (columnName === 'end_date' && !alreadyBlockedByStart) {
            shouldDisable = true;
          }
          break;
      }
    });

    return shouldDisable;
  };

  gantt._inline_edit.hasEditor = function (columnIndex, task) {
    var isHasEditor;
    var column;
    const isTotalEstimate = gantt.config.multiview ? task.$level === 1 : task.$level === 0;

    if (columnIndex === undefined) {
      return false;
    } else if (_.isNumber(columnIndex)) {
      column = gantt.config.columns[columnIndex];
    } else {
      column = columnIndex;
    }

    if (!column) {
      return false;
    }

    if (isTotalEstimate && column.name !== "start_date") {
      return false;
    }

    var type = column.control_type;
    var edits = gantt._inline_edit.editors;

    isHasEditor = type && edits[type] && (!column.task_types || !task.type || column.task_types.indexOf(task.type) !== -1);

    if (gantt._inline_edit.checkByLinkDependency(task, column.name)) {
      isHasEditor = false;
    }

    if (task.$level === 0 && column.name !== 'start_date') {
      isHasEditor = false;
    }

    if (['total_price', 'actual_cost'].includes(column.name) && !gantt.canEditBudget(task.gantt_id, column.name)) {
      isHasEditor = false;
    }

    return isHasEditor;
  };

  gantt.toggleRowEditingClass = function (id, isEnable) {
    var nodes = document.querySelectorAll('.gantt_row[task_id="' + id + '"]');
    if (nodes && nodes[0] && nodes[0].classList) {
      nodes[0].classList[isEnable ? 'add' : 'remove']('gantt_row_editing');
    }
  };

  gantt.canChangeStartDate = function (value, task) {
    var colName = 'start_date';

    if (gantt.config.auto_scheduling) { //спрашиваем у автошедулинга, можем ли мы сдвинуть начало таски
      var predecessors = gantt._getPredecessors(task);

      _.each(predecessors, function (pred) {
        if (pred.preferredEnd && new Date(value).valueOf() < pred.preferredEnd.valueOf()) {
          value = gantt.getClosestWorkTime({date: pred.preferredEnd, dir: "future"});
        }
      });
    }

    if (new Date(task[colName]).valueOf() !== new Date(value).valueOf()) {
      return true;
    }

    return false;
  };

  gantt.selectAndShowTask = function (id, scrollTop, rowCountOffset) {
    var task = gantt.getTask(id);
    var yScroll;
    var xScroll;

    gantt.selectTask(task.id);

    yScroll = gantt.getScrollState().y;

    gantt.showTask(task.id);

    xScroll = gantt.getScrollState().x;

    gantt._blockScrollEvent = true;
    gantt.scrollTo(xScroll, yScroll);
  };

  gantt.showTaskEditors = function (task, clickIndex, newValue) {
    task = (typeof task === "object") ? task : gantt.getTask(task);

    if (!task) {
      return;
    }

    if (task.$virtual) {
      return true;
    }

    var visibleColumns = gantt.getGridColumns();
    var column = visibleColumns[clickIndex];

    gantt.saveTaskEditors(true);
    gantt._inline_edit._currentTask = task.id;
    gantt._inline_edit._oldTask = gantt.copy(task);

    gantt._inline_edit._currentTaskData = gantt.copy(task); //TODO rewrite

    var allColumns = gantt.config.columns;
    var numberVisibleGrid = 0;
    var controller = {};
    var isForceOpenInited = false;
    var isWbsColumnShown = _.some(visibleColumns, function (column) {
      return column.name === 'wbs';
    });

    if (!clickIndex) {
      clickIndex = isWbsColumnShown ? 2 : 1; // default field
    }

    if (clickIndex >= visibleColumns.length) {
      return;
    }

    if (gantt.config.readonly) {
      return;
    }

    const node = document.querySelector(`.gantt_row[task_id="${task.id}"]`);
    const cell = node && node.querySelector(`.gantt_cell[${column.isUserCustomColumn ? 'data-type="' + column.data_type + '"' : 'data-name="' + column.name + '"'}]`);
    if (cell && cell.classList.contains('cell_readonly')) {
      return;
    }

    if (column.isUserCustomColumn && !gantt.callEvent('canEditColumn', [task, column.name])) {
      return;
    }

    if (column.isUserCustomColumn && column.data_type === 'custom_checkbox') {
      gantt.callEvent('onChangeUserCustomValue', [task, column.name, null]);

      userExtAnalytics.log('task_grid_action', { action: 'custom_field', type: 'checkbox' });

      return;
    }

    var isForceOpening = false;
    var noSelect = false;
    var type = column.control_type;
    var indexInAllColumns = 0;

    _.each(allColumns, function (col, i) {
      if (col.name === column.name) {
        indexInAllColumns = i;
      }
    });

    if (gantt._inline_edit.hasEditor(indexInAllColumns, task)) {
      gantt.selectTask(task.id);
      gantt.toggleRowEditingClass(task.id, true);
      gantt.selectAndShowTask(task.id, true);
      isForceOpening = true;
      isForceOpenInited = true;

      var value = task[column.name];

      if (column.isUserCustomColumn) {
        value = gantt.getUserCustomValue(task, column.name).value;
      }

      if (column.name === "predecessors") {
        const links = task.$target || [];
        const linkFormater = gantt.ext.formatters.linkFormatter();
        value = links.map(link => {
          const linkObj = {
            ...gantt.getLink(link),
            type: '0',
            lag: 0
          };
          return linkFormater.format(linkObj);
        }).join(', ')
      }

      if (newValue) {
        value = newValue;
        noSelect = true;
      }

      controller = gantt._inline_edit._createControl(
        task.id,
        indexInAllColumns,
        clickIndex,
        type,
        isForceOpening,
        noSelect,
        column
      );

      if (controller.config.id) {

        controller.setValue(value);
      }
    }


    if (gantt._inline_edit._controllers[0] && gantt._inline_edit._controllers[0].config.id) {
      if (!isForceOpenInited) gantt._inline_edit._controllers[0].focus();
    } else {
      gantt._inline_edit._currentTask = null;
      gantt._inline_edit._controllers = [];
    }

    gantt._inline_edit._changeEstimation = false;
    gantt._inline_edit._changeResource = false;
    gantt._inline_edit._changeOwner = false;
    gantt._inline_edit._changedCustomColumns = [];
  };

  gantt.isEditingState = function () {
    return (gantt.isInlineEditorOpened() || gantt.isActiveInputAddTask());
  };

  gantt.isActiveInputAddTask = function () {
    return !!document.querySelector(".gantt_btn_add.active");
  };

  const _popupsState = {};

  gantt.isBlockingCollaborationByPopups = function () {
    return Object.keys(_popupsState).reduce((result, key) => (_popupsState[key] || result), false);
  };

  gantt.setBlockingCollaborationByPopup = function (popupId, state) {
    _popupsState[popupId] = state;

    if (!state && !gantt.isBlockingCollaborationByPopups()) {
      gantt.callEvent('onHidePopupBlockingCollaboration');
    }
  };

  gantt.updateTaskEditorsCurrentTask = function (taskId) {
    var task = gantt.isTaskExists(taskId) ? gantt.getTask(taskId) : undefined;

    if (gantt._inline_edit._currentTask === taskId) {
      gantt._inline_edit._currentTaskData = gantt.copy(task);
    }
  };

  gantt.saveTaskEditors = function (unloadEditors, isEsc) {
    if (!gantt._inline_edit._isEditorsOpened()) {
      return true;
    }

    var controllers = gantt._inline_edit._controllers;
    var currentTask = gantt.isTaskExists(gantt._inline_edit._currentTask) ? gantt.getTask(gantt._inline_edit._currentTask) : undefined;
    var changes = false;
    let isProgressChanged = false;
    let isStartDateChanged = false;

    gantt.toggleRowEditingClass(currentTask && currentTask.id, false);

    if (isEsc) {
      gantt.unselectTask();
      gantt.unloadTaskEditors();
      return;
    }

    if (gantt._inline_edit._currentTask && controllers.length) {
      _.each(controllers, function (item) {
        if (item.saveValue()) {
          if (item.config.colName === 'progress') {
            isProgressChanged = true;
          }
          if (item.config.colName === 'start_date') {
            isStartDateChanged = true;
          }

          changes = true;

          if (item.config.colName === 'predecessors') {
            const value = item.config.control.getValue(item.config.container, item.config.id);
            gantt.callEvent("saveLinkColumn", [value, currentTask]);

            changes = false;
          }
        }
      });
    }

    gantt.unselectTask();

    if (!changes) {
      gantt.callEvent('unloadTaskEditors');
    }

    if (unloadEditors) {
      gantt.unloadTaskEditors();
      var updatedTask = gantt.copy(gantt._inline_edit._currentTaskData);
      //var oldTask = gantt.copy(gantt._inline_edit._oldTask);

      if (currentTask && changes) {
        if (currentTask.type === gantt.config.types.project) {
          gantt.callEvent("onBeforeProjectMove", [currentTask.id]);
        }

        // strange old code that leads to bug with constraint_date
        // if (gantt._inline_edit._oldTask) {
        //   currentTask.end_date = gantt.calculateEndDate({
        //     start_date: currentTask.start_date,
        //     duration: currentTask.duration,
        //     task: currentTask
        //   });
        //   gantt.callEvent("onBeforeTaskChanged", [currentTask.id, "resize", oldTask]);
        // }

        updatedTask.end_date = gantt.calculateEndDate({
          start_date: updatedTask.start_date,
          duration: updatedTask.duration,
          task: updatedTask
        });

        updatedTask.constraint_type = gantt.config.constraint_types.ASAP;
        updatedTask.constraint_date = null;

        if (isProgressChanged) {
          gantt.callEvent('changeProgress', [updatedTask, true]);
          currentTask.progress = updatedTask.progress;
          currentTask.status = updatedTask.status;
          gantt.refreshTask(currentTask.id, updatedTask);
        } else {
          if (updatedTask.type === gantt.config.types.project && isStartDateChanged) {
            gantt.moveProjectHandler(updatedTask.id, updatedTask, updatedTask.start_date)
              .then(() => {
                updatedTask.isHidden = 1;
                gantt.updateTask(currentTask.id, updatedTask);
                delete updatedTask.isHidden;
                gantt._inline_edit._oldTask = null;
              });
          } else {
            const estimationDiff = currentTask.estimation !== updatedTask.estimation;

            if (isStartDateChanged && estimationDiff) {
              updatedTask.estimation = currentTask.estimation;
            }

            gantt.updateTask(currentTask.id, updatedTask);
            gantt._inline_edit._oldTask = null;
          }
        }

        gantt.callEvent('taskEditor:task:changed', [currentTask])

        return true;
      }
    }
  };

  gantt.isInlineEditorOpened = function () {
    return gantt._inline_edit._isEditorsOpened();
  };

  gantt.hasCurrentTaskChanges = function () {
    var changes = false;
    var controllers = gantt._inline_edit._controllers;

    if (gantt._inline_edit._currentTask && controllers.length) {
      _.each(controllers, function (item) {
        if (item.saveValue()) {
          changes = true;
        }
      });
    }

    return changes;
  };

  gantt.isTaskSettingsPopupOpened = function () {
    return $$('taskSettingsPopup') && $$('taskSettingsPopup').isVisible();
  };

  gantt.unloadTaskEditors = function () {
    var controllers = gantt._inline_edit._controllers;

    _.each(controllers, function (item) {
      item.unload();
    });

    gantt._inline_edit._currentTask = null;
    gantt._inline_edit._controllers = [];

    gantt.callEvent('closedInlineEditor');
    gantt.scrollTo(gantt.getScrollState().x,gantt.getScrollState().y);//fix fucking bug in safari
  };

  gantt._inline_edit.oldRefreshTask = gantt.refreshTask;

  gantt.refreshTask = function (taskId, refresh_links) {
    var task = gantt.getTask(taskId);
    var isTaskInserted = task && task.$dataprocessor_class === 'gantt_inserted ';

    if (gantt.getState("linksDnD").link_source_id == taskId && gantt._inline_edit._isEditorsOpened()) {
      gantt.saveTaskEditors(true);
      gantt._inline_edit.oldRefreshTask.apply(gantt, arguments);
      isTaskInserted && gantt.callEvent('onAfterTaskInsert', [task]);

      return;
    }

    gantt._inline_edit.oldRefreshTask.apply(gantt, arguments);
    isTaskInserted && gantt.callEvent('onAfterTaskInsert', [task]);

    if (taskId != gantt._inline_edit._currentTask) {
      return;
    }

    var task = this.getTask(taskId);

    if (task && this.isTaskVisible(taskId)) {
      gantt._inline_edit._onDataRender();
    }
  };

  var createGridCell = function (columns, task, element, index) {
    var last = +index === columns.length - 1;
    var cssCell = "gantt_cell" + (last ? " gantt_last_cell" : "");
    var value;
    var baselineValue = null;
    var columnsWithoutBaselineDiff = ['time_tracking', 'actual_cost'];
    var hasEditor = gantt._inline_edit.hasEditor(element, task);

    const isOverdue = element.name === 'overdue';
    const isOverload = element.name === 'overload';
    const isComments = element.name === 'comments';
    const isAttachments = element.name === 'attachments';
    const isWBS = element.name === "wbs";
    const isText = element.name === 'text';
    const isButtons = element.name === "buttons";
    const isStartDate = element.name === 'start_date';
    const isEndDate = element.name === 'end_date';
    const isResource = element.name === 'resource_id';
    const isMassChange = element.name === 'masschange';
    const isCustomColor = element.data_type === 'custom_color';
    const isCustomTags = element.data_type === 'custom_tags';
    const isCustomResourses = element.data_type === 'custom_resources';
    const isCheckbox = element.data_type === 'custom_checkbox';
    const isTimeLog = element.name === 'time_tracking';
    const isStatus = element.name === 'status';
    const isPriority = element.name === 'priority';

    const isCustomMultiselect = element.data_type === 'custom_multiselect';


    const isButton = task.type === gantt.config.types.button;
    const isProject = task.type === gantt.config.types.project;
    const isTask = task.type === gantt.config.types.task;

    const isTotalEstimate = gantt.config.multiview ? task.$level === 1 : task.$level === 0;

    const isJiraRootProjetc = gantt.config.isJira
      && (gantt.config.gantt_id <= GT.lastProjectIdBeforeRootTask)
      && (gantt.config.multiview ? task.$level === 2 : task.$level === 1);

    if (element.name === "add") {
      value = "<div class='gantt_add'></div>";
    } else {
      value = element.template ? element.template(task) : task[element.name];

      if (task.baselineData && !_.includes(columnsWithoutBaselineDiff, element.name)) {
        baselineValue = element.template ? element.template(task.baselineData, true) : task.baselineData[element.name];
      }

      if (!baselineValue || _.isEqual(baselineValue, value)) {
        if (element.isUserCustomColumn && gantt.getBaselineUserCustomValue && gantt.getUserCustomValue) {
          baselineValue = null;

          const baselineCustomValue = gantt.getBaselineUserCustomValue(task, element);
          const userCustomValue = gantt.getUserCustomValue(task, element.name);

          if (_.toString(baselineCustomValue.text)) {
            baselineValue = _.toString(baselineCustomValue.text);
          }

          if (element.data_type === 'custom_number' && userCustomValue) {
            baselineCustomValue.value = +baselineCustomValue.value;
            userCustomValue.value = +userCustomValue.value;
          }

          if (_.isEqual(baselineCustomValue.value, userCustomValue.value)) {
            baselineValue = null;
          } else {
            if (element.data_type === 'custom_color' || element.data_type === 'custom_checkbox') {
              baselineValue = gantt.config.changed_value_locale;
            }
            if (element.data_type === 'custom_text' && !baselineCustomValue.value) {
              baselineValue = gantt.config.changed_value_locale;
            }

          }

          if (element.data_type === 'custom_date') {
            baselineValue = _.isEqual(baselineCustomValue.text, userCustomValue.text) ? null : baselineCustomValue.text.trim();
          }

        } else {
          baselineValue = null;
        }
      }

      if (value instanceof Date) {
        value = gantt.templates.date_grid(value);
      }

      let cssTextClass = 'text_value';


      if (isOverdue) {
        cssTextClass = 'overdue';
      }
      if (+task.status === 4 && task.type !== 'project') {
        if(gantt.config.cross_out_tasks) {
          if(!isComments && !isAttachments && !isOverload && !isCustomColor && !isCustomTags && !isCheckbox && !isMassChange && !isOverdue && !isButton && !isButtons) {
            let cssClass = " crossOutGrid";
            if (isWBS || isCustomMultiselect) {
              cssClass += "-span-custom";
            }
            if(isTimeLog) {
              cssClass += "-time-tracling";
            }
            if(isResource || isCustomResourses) {
              cssClass += " crossOutGrid-resource";
            }
            if(isStatus || isPriority) {
              cssClass += " crossOutGrid-icons";
            }
            cssTextClass += cssClass;
          }
        }
        if(gantt.config.discoloration_tasks && !isMassChange) {
          cssTextClass += " discolorationGrid";
        }
      }

      if (isOverload) {
        cssTextClass = 'overload';
      }

      if (isComments && !gantt.config.masschange && !isTotalEstimate) {
        const hasComments = task.hasComments > 0;
        const newComments = task.newComments;

        cssCell += " notification_cell";
        value = `
          <div class='notification_cell_comments ${hasComments ? "has" : ""} ${newComments ? "new" : ""}' >
            ${!GT.appMode.isHistory ? chartIconsBG.comments : ''}
          </div>`;
        if(($$('taskViewId') && $$('taskViewId').isVisible()) || GT.appMode.isLink){
          cssCell += " no_hover";
        }
      }

      if (isAttachments && !gantt.config.masschange && !isTotalEstimate) {
        cssCell += " notification_cell";
        value = `<div class='notification_cell_attachments ${task.hasAttachments ? "has" : ""}' >
                    ${!GT.appMode.isHistory ? chartIconsBG.attach : ''}
                 </div>`;
        if(($$('taskViewId') && $$('taskViewId').isVisible()) || GT.appMode.isLink){
          cssCell += " no_hover";
        }
      }

      if (isText && !isButton && !element.isUserCustomColumn) {
        let link = '';
        let data_id = '';

        if (!GT.appMode.isHistory && !GT.appMode.isLink && !GT.appMode.isExport) {
          link = `<div class='tooltip_gantt_show_more'><a>${__('gantt_tooltip_show_more')}</a></div>`;
          data_id = `data-id='${task.id}'`;
        }
        const isBaseline = (gantt.config.baseline && baselineValue);
        const baselineRow = isBaseline ? `${"<div class='text_value baseline_content'>" + "<div class='text_value_content'>"}${baselineValue}</div>` + '</div>' : '';

        value = `${`<div class='gantt_tree_content inline_editor_task_name_container ${isBaseline ? 'baseline_view' : ''}'>`
          + "<div class='inline_editor_task_name_container_text'>" + "<div class='text_value'>"
          + "<div class='text_value_content'>"}${value}</div>` + `</div>${baselineRow}</div>`
          + '<div class=\'tooltip-gantt-html\'>'
          + `<div class='tooltip-gantt-html-title'>${value}</div>${
            task.note ? `<div class="tooltip-delimiter"></div><div class='tooltip-gantt-html-description ql-snow ql-editor'>${wrapWithPTag(task.note)}</div>` : ''
          }${link
          }</div>`
          + '</div>';

        if (!isTotalEstimate) {
          value += `<div class='gantt_grid_fast_button_edit'>${chartIconsBG.editor}</div>` +
            (isJiraRootProjetc || !gantt.hasRight(task.gantt_id, 'delete_task') ? '' : `<div class='gantt_grid_fast_button_delete'>${chartIconsBG.delete}</div>`);
        }

        value += "</div>";
      } else {
        let baselineRow = (gantt.config.baseline && baselineValue) ? "<div class='gantt_tree_content baseline_content'>" + "<div class='text_value'>" + baselineValue + "</div>" + "</div>" : '';
        let textCell = "<div class='" + cssTextClass + "'>" + value + "</div>";
        let key;

        if (isStartDate) {
          if (gantt._inline_edit.checkByLinkDependency(task, element.name)) {
            if (task.type === gantt.config.types.project) {
              key = 'why_can_not_set_it_case2_start';
            } else {
              key = 'why_can_not_set_it_case4';
            }
          }
        }

        if (isEndDate) {
          if (isProject) {
            if(gantt.hasRight(task.gantt_id, 'static_fields')) {
              key = 'why_can_not_set_it_case1';
            }
          }
          if (gantt._inline_edit.checkByLinkDependency(task, element.name)) {
            if (isTask) {
              key = 'why_can_not_set_it_case5';
            }
          }
        }

        if (key) {
          textCell = "<div class='tooltip-gantt no_pointer " + cssTextClass + "' data-y='-4' data-key='" + key + "' data-position='top'>" + value + "</div>";
        }

        value = "<div class='gantt_tree_content'>" + textCell + "</div>" + baselineRow;
      }
    }

    var tree = "";

    if (element.tree) {
      var j = 0;

      if (gantt.config.multiview && isButton && task.$level === 2) {
        j = 2;
      }

      for (; j < task.$level; j++) {
        tree += gantt.templates.grid_indent(task);
      }

      if (gantt.hasChild(task.id)) {
        tree += gantt.templates.grid_open(task);
        //tree += gantt.templates.grid_folder(task);
      } else {
        tree += gantt.templates.grid_blank(task);

        // tree += gantt.templates.grid_file(task);
      }
    }

    var style = "width:" + (element.width - (last ? 1 : 0)) + "px;";
    let customColumnAttr = "";
    const columnNameAttr = `data-name='${_.escape(element.name)}'`;

    if (element.isUserCustomColumn) {
      customColumnAttr = `data-type='${element.data_type}'`;
    }

    if (isResource) {
      cssCell += ' assign';
    }

    // if (gantt.defined(element.align)) {
    //   style += "text-align:" + element.align + ";";
    // }

    if (isButton && !isText && !isWBS && !isComments && !isAttachments && !isOverdue && !isOverload) {
      return '';
    }

    if (isButtons) {
      cssCell += " actions_panel";
    }

    if (isText) {
      cssCell += " text_cell";
      if(_checkIsOverdue(task) && gantt.config.highlight_overdue) {
        cssCell += " overdue";
      }
      if (+task.status === 4 && task.type !== 'project') {
        if(gantt.config.cross_out_tasks) {
          cssCell += " crossOutGrid-custom";
        }
        if(gantt.config.discoloration_tasks) {
          cssCell += " discolorationGrid";
        }
      }
    }

    if(isComments || isAttachments || isOverload || isCustomColor || isCustomTags || isCheckbox) {
      if ((gantt.config.cross_out_tasks || gantt.config.discoloration_tasks) &&  +task.status === 4 && task.type !== 'project') {
        cssCell += " discolorationGrid";
      }
    }

    if (isEndDate) {
      if(_checkIsOverdue(task) && gantt.config.highlight_overdue) {
        cssCell += " overdue";
      }
    }

    if (element.name === "status") {
      cssCell += " status";
    }

    if (element.name === "priority") {
      cssCell += " priority";
    }

    if (element.data_type) {
      cssCell += ` ${element.data_type}`;
    }

    if (hasEditor) {
      cssCell += " has_cell_inline_editor";
    }

    if (!gantt.hasAccessToTask(task) || !gantt.hasRightToEditField(task.gantt_id, element.data_type ? element.data_type : element.name)) {
      cssCell += " cell_readonly";
    }

    const cellEl = document.createElement('div');

    cellEl.className = cssCell;
    cellEl.style.cssText = style;
    cellEl.setAttribute('data-type', element.data_type);
    cellEl.setAttribute('data-name', element.name);

    if ((element.name === 'total_price' && !gantt.hasRight(task.gantt_id, 'cost_field')) || (element.name === 'actual_cost' && !gantt.hasRight(task.gantt_id, 'actual_cost_field'))) {
      cellEl.innerHTML = '';
    } else {
      cellEl.innerHTML = `${tree} ${value}`;
    }

    return cellEl;
  };

  var getCssForTaskRow = function (task) {
    const taskID = +task.id;
    const parent = task.type === 'button' ? gantt.getTask(task.parent) : null;

    let cssRow = '';

    if (task.$index % 2 !== 0) {
      cssRow += " odd ";
    }

    if (task.$transparent) {
      cssRow += " gantt_transparent ";
    }

    if (gantt.templates.grid_row_class) {
      cssRow += (gantt.templates.grid_row_class.call(gantt, task.start_date, task.end_date, task) || "");
    }

    if (+gantt.getState().selected_task === taskID) {
      cssRow += " gantt_selected";
    }

    if (gantt._inline_edit._isEditorsOpened() && gantt._inline_edit._currentTask === task.id) {
      cssRow += " gantt_row_editing";
    }
    if (+gantt.config._opened_task_id === taskID) {
      cssRow += " selected";
    }

    if (task.$level === 0) {
      cssRow += " estimate_total";
    } else if (task.$level === 1 || (task.$level === 2 && task.type === 'button' && gantt.config.multiview)) {
      cssRow += task.type === 'project' ? " top_project" : " top_task";
    } else if (task.type !== 'task' && task.type !== 'milestone') {
      cssRow += " task_group";
    }
    if (_checkIsOverdue(task) && gantt.config.highlight_overdue) {
      cssRow += " highlightOverdueGrid";
    }


    return cssRow;
  };

  var createDOMElement = function (task, cssRow) {
    var taskID = +task.id;
    var el = document.createElement("div");
    const isAttachmentsColumn = gantt.config.columns.find(col => col.name === "attachments");
    const isCommentsColumn = gantt.config.columns.find(col => col.name === "comments");
    const isOverloadColumn = gantt.config.columns.find(col => col.name === "overload");
    const masschangePadding = isAttachmentsColumn.hide && isCommentsColumn.hide && isOverloadColumn.hide
      ? 'masschange_disabled_left_padding_20'
      :  !isOverloadColumn.hide && isAttachmentsColumn.hide && isAttachmentsColumn.hide ? 'masschange_disabled_left_padding_10'
      : ''

    el.className = "gantt_row " + cssRow + (gantt.config.readonly ? ' unuseble ' : '');
    el.className += gantt.config.masschange ? ` masschange_disabled ${masschangePadding}` : '';
    el.style.height = gantt.config.row_height + "px";
    el.style.lineHeight = gantt.config.row_height + "px";

    el.style.position = "absolute";
    el.style.left = "0px";
    el.style.top = gantt.getTaskTop(task.id) + "px";

    if (gantt.config.baseline === 1) {
      el.style.height = gantt.config.row_height + "px";
      el.style.lineHeight = gantt.config.row_height + "px";
    }

    el.setAttribute("task_id", taskID);

    el.onmouseenter = _.debounce(function () {
      var isResize = !!document.querySelector(".gantt_resizing");
      if (!gantt.config.dndMove && !isResize) {
        if (!taskID) {
          gantt.setHoverTaskRow();
        } else {
          const task = gantt.isTaskExists(taskID) && gantt.getTask(taskID);
          if (!task || task.type === gantt.config.types.button) {
            gantt.setHoverTaskRow();
          } else {
            gantt.setHoverTaskRow(taskID);
          }
        }
      }

    }, 50);

    el.onmouseover = _.debounce(function (e) {
      var isResize = !!document.querySelector(".gantt_resizing");
      const customColumnsClasses = ['.custom_multiselect', '.custom_resources', '.custom_tags'];
      const pointedColumnClass = customColumnsClasses.find(cellClass => {
        return e.target.closest(cellClass);
      });

      if (!gantt.config.dndMove && !isResize && e && (e.target.closest('.assign') && e.target.closest('.assign').querySelector('.resource-in-grid'))) {
        if (gantt.isInlineEditorOpened() && gantt._inline_edit._currentTask === taskID) {
          return;
        }

        const pos = e.target.getBoundingClientRectWrapper();

        if (pos.x || pos.y) {
          gantt.callEvent('showResourcesPreviewListForTask', [pos, taskID]);
        }

        return;
      }

      if (!gantt.config.dndMove && !isResize && e && pointedColumnClass) {
        const pointedCustomCell = e.target.closest(pointedColumnClass);

        if (pointedCustomCell.classList.contains('gantt_focused')) {
          return;
        }

        if (pointedCustomCell.querySelector('.text_value').children.length) {
          const pos = pointedCustomCell.getBoundingClientRectWrapper();

          if (pos.x || pos.y) {
            gantt.callEvent('showCustomColumnPreviewList', [pos, taskID, pointedCustomCell.dataset.name]);
          }
        }
      }
    }, 50);
    el.onmouseout = _.debounce(function (e) {
      gantt.callEvent('hideResourcesPreviewListForTask');
      gantt.callEvent('hideCustomColumnPreviewList');
    }, 50);
    el.onmouseleave = _.debounce(function (e) {
      !gantt.config.dndMove && gantt.removeHoverTaskRow(taskID, e);
    }, 50);

    return el;
  };

  var getDefaultGridLine = function (task) {
    const columns = gantt.getGridColumns();

    let clearCache = false;
    if (!gantt._isLinksCacheEnabled()) {
      gantt._startLinksCache();
      clearCache = true;
    }

    if (clearCache)
      gantt._endLinksCache();

    let cssRow = getCssForTaskRow(task);

    if (typeof gantt.isMassChangeSelected === 'function' && gantt.isMassChangeSelected(task.id)) {
      cssRow += ' gantt-task-mc-selected';
    }

    if (task.needMultiviewBackground) {
      cssRow += ' multiviewBackground';
    }

    if (gantt.config.dnd_task_temp && gantt.config.dnd_task_temp[task.id]) {
      cssRow += ' dnd-highlight-paste';
    }

    const el = createDOMElement(task, cssRow);

    columns.forEach((column, index) => {
      const cell = createGridCell(columns, task, column, index);

      cell && el.appendChild(cell);
      if (cell && cell.dataset.name === 'text') {
          setImmediate(() => {
            const taskNameContainer = cell.querySelector('.inline_editor_task_name_container');

            if (taskNameContainer) {
              const textValueContent = taskNameContainer.childNodes
                && taskNameContainer.childNodes[0]
                && taskNameContainer.childNodes[0].childNodes
                && taskNameContainer.childNodes[0].childNodes[0];

                const tooltip = document.createElement('div');

                if ($$('taskViewId') && $$('taskViewId').isVisible()) {
                cell.classList.remove('tooltip-gantt');
                return;
                };
                tooltip.className = 'tooltip-gantt-html';
                tooltip.innerHTML = '<div class=\'tooltip-gantt-html-title\'></div>';
                tooltip.childNodes[0].innerText = textValueContent.innerText;
                cell.dataset.html = 'true';
                cell.dataset.y = '-6';
                cell.dataset.id = task.id;
                cell.classList.add('tooltip-gantt');
                cell.appendChild(tooltip);
            }
          })
      }
    });

    // const html = cells.join("");
    //
    // el.innerHTML = html;

    if(el.classList.contains('gantt_btn_add') && gantt.config.multiview  && el.classList.contains('top_task') ) {
      if (el.querySelector('.text_cell')) {
        const buttonRow = el.querySelector('.text_cell'); // need two indents
        const newEl = document.createElement('div');
        const newEl2 = document.createElement('div');
        newEl2.className = 'gantt_tree_indent'
        newEl.className = 'gantt_tree_indent'
        buttonRow.insertBefore(newEl, buttonRow.firstChild)
        buttonRow.insertBefore(newEl2, buttonRow.firstChild)
      }
    }

    return el;
  };

  var getWorkloadGridLine = function (item) {
    var el = document.createElement("div");

    el.className = "gantt_row workload_row" + (item.user_id ? " workload_row_real_resource" : " workload_row_material_resource") + (item.selected ? " selected" : "");
    if (item.separator) {
      el.className += ' separator';
    }
    if (item.firstChild) {
      el.className += ' first';
    }
    if (item.lastChild) {
      el.className += ' last';
    }
    if (item.unassigned) {
      el.className += ' unassigned';
    }
    if (item.opened) {
      el.className += ' open';
    }
    el.style.height = `${gantt._getWorkloadItemHeight(item.id)}px`;
    el.style.lineHeight = `${gantt._getWorkloadItemHeight(item.id)}px`;
    el.style.position = "absolute";
    el.style.top = `${gantt._getWorkloadItemTop(item.id)}px`;

    el.innerHTML = `${item.tasksLayout ? '' : `<div class='arrow'></div>`}
                    <div class='workload_row_photo' style="background-image:url(${item.photo || 'https://cdn.ganttpro.com/app/imgs/default/default-ava.svg'})"></div>
                    <div class='workload_row_name'>${_.escape(item.name)}</div>`;

    el.onclick = function (e) {
      if (e && e.target && e.target.closest('.workload_row_calendar')) {
        gantt.callEvent("showPersonalCalendar", [item.resource_id]);
      } else if (!item.tasksLayout) {
        gantt.callEvent('showResourceWorkloadTasks', [item.id]);
      }
    };

    if (item.tasksLayout) {
      el.innerHTML = `<div class="empty"></div>`;
      el.className += " tasks";
    } else {
      el.setAttribute('data-resource-grid-id', item.id);
      if (gantt._getOverloadedResources({resourceId: item.resource_id})) {
        el.innerHTML += `<div class="workload_row_overload tooltip-gantt" data-key="overload_tooltip_workload">${gantt._getSvgIcon('resource', 'regular', 24)}</div>`;
      }
      if (!GT.appMode.isExport && item.accessToPersonalCalendar && !item.unassigned) {
        // el.innerHTML += '<div class="workload_row_calendar tooltip-gantt" data-key="workload_calendar_tooltip"></div>';
        el.innerHTML += '<div class="workload_row_calendar tooltip-gantt gp_autotest_webix_workload_resource_item_btn_calendar" data-key="workload_calendar_tooltip"  data-position="top" data-align="right">' + '<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="#757575" d="M8 12h8v1H8zM8 15h4v1H8zM7 4h1v2.13333H7zM16 4h1v2.13333h-1zM5 9h14v1.06667H5z"/><rect x=".5" y=".5" width="13" height="13" rx="1.5" transform="translate(5 6)" stroke="#757575"/></svg>' + '</div>';
      }
    }

    return el;
  };

  gantt.getVisibleTasksRange = function(gantt, view, config, viewport){
    const dataCount = gantt.getVisibleTaskCount();
    const buffer = 1;
    const rowHeight = gantt.config.row_height;
    const start = Math.floor(viewport.y / rowHeight) || 0;
    const end = Math.floor(viewport.y_end / rowHeight) || dataCount;
    const indexStart = Math.max(0, start - buffer);
    const indexEnd =  Math.min(dataCount, end + buffer);
    let result = {
      start: indexStart,
      end: indexEnd
    };

    if (view.$id === 'workloadGrid') {
      result = {
        start: 0,
        end: config.fullOrder.length
      }
    }

    return result;
  }
  gantt._getSvgIcon = function (name, type, size) {
    const hrefForSvg = `${type}/${size}/${name}`;

    return `<svg width="${size}" height="${size}" viewBox="0 0 ${size} ${size}" fill="none" xmlns="http://www.w3.org/2000/svg">
    <use xmlns:xlink="http://www.w3.org/2000/svg"
            xlink:href="#icons/${hrefForSvg}"
            href="#icons/${hrefForSvg}"
        ></use>
  </svg>`;
  };
  function wrapSmartRender(renderOne) {
    return function () {
      return {
        render: renderOne,
        update: null,
        getRectangle: function (item, view) {
          if (item.render_type === 'workload') {
            return {
              top: GT.appMode.isExport ? 0 : gantt._getWorkloadItemTop(item.id),
              height: GT.appMode.isExport ? Infinity : gantt._getWorkloadItemHeight(item.id),
              left: 0,
              right: Infinity
            };
          }
          return {
            top: view.getItemTop(item.id),
            height: view.getItemHeight(item.id),
            left: 0,
            right: Infinity
          };
        },
        getVisibleRange: gantt.getVisibleTasksRange
      };
    };
  }

  const renderOneGridLine = function (item) {

    if (item.render_type !== 'workload') {
      if (gantt.is_workload_mode) {
        return '';
      }

      return getDefaultGridLine(item);
    }

    return getWorkloadGridLine(item);
  };

  gantt.$ui.layers.gridLine = wrapSmartRender(renderOneGridLine);

  gantt.htmlToElement = function (html) {
    var template = document.createElement('template');

    template.innerHTML = html;

    return template.content.firstChild;
  };

  /**
   * Attach Event Handlers
   */
  gantt.attachEvent("onTaskClick", gantt._inline_edit._onTaskClick);
  gantt.attachEvent("onTaskDblClick", gantt._inline_edit._onTaskDblClick);
  gantt.attachEvent("onTaskClosed", gantt._inline_edit._onTaskClosed);
  gantt.attachEvent("onEmptyClick", gantt._inline_edit._onEmptyClick);
  gantt.attachEvent("onBeforeLightbox", gantt._inline_edit._onBeforeLightBox);
  gantt.attachEvent("onBeforeTaskDrag", gantt._inline_edit._onBeforeTaskDrag);
  gantt.attachEvent("onRowDragStart", gantt._inline_edit._onRowDragStart);
  gantt.attachEvent("onBeforeTaskDelete", gantt._inline_edit._onBeforeTaskDelete);
  gantt.attachEvent("newTaskCommentNotification", gantt._inline_edit._onAddedNewComment);
  gantt.attachEvent("addTaskAttachmentNotification", gantt._inline_edit._onAddedNewAttachment);
  gantt.attachEvent("removeTaskAttachmentNotification", gantt._inline_edit._onRemovedAttachment);
  gantt.attachEvent("removeTaskCommentNotification", gantt._inline_edit._onReadNewComment);
  gantt.attachEvent("onTaskIdChange", function (taskId, newTaskId) {
    if (gantt._inline_edit._currentTask === taskId) {
      gantt._inline_edit._currentTask = newTaskId;
      gantt._inline_edit._currentTaskData = gantt.getTask(newTaskId);
    }
  });

  gantt.attachEvent("onAfterLinkDelete", function (id, item) {
    gantt.refreshTask(item.target);
  });

  gantt.attachEvent("refreshTaskEditors", function () {
    gantt._inline_edit._onDataRender();
  });

  gantt.attachEvent("onAfterLinkAdd", function (id, item) {
    gantt.refreshTask(item.target);
  });

  gantt.attachEvent("onColumnResizeStart", function (index, column) {
    gantt._inline_edit._onTaskClosed();
    return true;
  });

  window.addEventListener("resize", function () {
    gantt._inline_edit._onTaskClosed();
  });

  gantt.attachEvent("onGridResizeStart", function (index, column) {
    gantt._inline_edit._onTaskClosed();
    return true;
  });

  gantt.attachEvent("onAfterTaskAddAndOtherActions", function (taskId, taskData) {
    if (!gantt.config.isJira) {
      //gantt.showTaskEditors(taskData);
    }
  });

  window.document.addEventListener('click', function (e) {
    if (gantt._inline_edit._on_text_dnd) {
      setTimeout(() => {
        gantt._inline_edit._on_text_dnd = false;
      }, 50);
      return;
    }

    if (gantt.isInlineEditorOpened() && !e.target.closest('.gantt_row') && !(e.target.closest('.webix_window') || !e.target.closest('.webixapp')) && !e.target.closest('.inline_select_popup')) {
      gantt.saveTaskEditors(true);
    }
  }, false);

  var chartIconsBG = {
    'editor': `${gantt._getSvgIcon('open', 'regular', 24)}`,
    'delete': `${gantt._getSvgIcon('delete', 'regular', 24)}`,
    'attach': `${gantt._getSvgIcon('attach', 'regular', 24)}`,
    'comments': `${gantt._getSvgIcon('comments', 'regular', 24)}`,
    'submit':`<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="16" height="16" rx="2" fill="#1565C0"/>
    <path d="M10.4672 5.57393L6.8968 9.53467L5.54126 8.28529C5.33514 8.09532 5.01198 8.11655 4.8325 8.33186C4.66276 8.53547 4.68563 8.83713 4.88413 9.01282L6.5673 10.5027C6.75799 10.6714 7.04854 10.6572 7.2218 10.4706L11.1704 6.21719C11.3393 6.03522 11.3402 5.75401 11.1724 5.57098C10.9825 5.3638 10.6554 5.36517 10.4672 5.57393Z" fill="white" stroke="white" stroke-width="0.2"/>
</svg>`
  };

  (function () {
    /** Overrides event functionality.
     *  Includes all default methods from dhtmlx.common but adds _silentStart, _silendEnd
     *   @desc:
     *   @type: private
     */
    gantt._eventable = function (obj) {
      obj._silent_mode = false;
      obj._silentStart = function () {
        this._silent_mode = true;
      };
      obj._silentEnd = function () {
        this._silent_mode = false;
      };

      obj.attachEvent = function (name, catcher, callObj) {
        name = 'ev_' + name.toLowerCase();
        if (!this[name])
          this[name] = new this._eventCatcher(callObj || this);

        return (name + ':' + this[name].addEvent(catcher)); //return ID (event name & event ID)
      };
      obj.callEvent = function (name, arg0) {
        if (this._silent_mode) return true;
        name = 'ev_' + name.toLowerCase();
        if (this[name])
          return this[name].apply(this, arg0);
        return true;
      };
      obj.checkEvent = function (name) {
        return (!!this['ev_' + name.toLowerCase()]);
      };
      obj._eventCatcher = function (obj) {
        var dhx_catch = [];
        var z = function () {
          var res = true;
          for (var i = 0; i < dhx_catch.length; i++) {
            if (dhx_catch[i]) {
              var zr = dhx_catch[i].apply(obj, arguments);
              res = res && zr;
            }
          }
          return res;
        };
        z.addEvent = function (ev) {
          if (typeof (ev) == "function")
            return dhx_catch.push(ev) - 1;
          return false;
        };
        z.removeEvent = function (id) {
          dhx_catch[id] = null;
        };
        return z;
      };
      obj.detachEvent = function (id) {
        if (id) {
          var list = id.split(':');           //get EventName and ID
          this[list[0]].removeEvent(list[1]); //remove event
        }
      };
      obj.detachAllEvents = function () {
        for (var name in this) {
          if (name.indexOf("ev_") === 0)
            delete this[name];
        }
      };
      obj = null;
    };
    var editors = gantt._inline_edit.editors;

    for (var i in editors) {
      gantt._eventable(editors[i]);
    }
  })();
});
